import styled from "@emotion/styled";
import {
  Box,
  CircularProgress,
  Divider,
  Icon,
  Stack,
  Typography,
} from "@mui/material";
import ForegroundPaper from "./ForegroundPaper";

export default function PageContainer({
  title,
  icon,
  color = "transparent",
  actions,
  children,
  loading,
}) {
  return (
    <ForegroundPaper
      sx={{
        m: 0.5,
        mb: 0,
        borderRadius: 4,
      }}
    >
      {!loading && (
        <Stack
          direction={"column"}
          justifyContent={"center"}
          alignItems={"stretch"}
          p={2}
        >
          <PageHeader spacing={2}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              width={"100%"}
              spacing={2}
            >
              <Stack direction={"row"} alignItems={"center"} spacing={2}>
                {icon && (
                  <Box
                    sx={{
                      backgroundColor: color,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "50%",
                      padding: 1,
                      width: 32,
                      height: 32,
                      ".MuiSvgIcon-root": {
                        color: (theme) =>
                          color !== "transparent"
                            ? theme.palette.primary.main + "!important"
                            : theme.palette.primary.contrastText + "!important",
                      },
                    }}
                  >
                    {icon}
                  </Box>
                )}
                <Typography variant="h6" gutterBottom>
                  {title}
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems={"center"} spacing={2}>
                {actions}
              </Stack>
            </Stack>
          </PageHeader>
          <Divider
            sx={{
              my: 2,
            }}
          />
          {children}
        </Stack>
      )}
      {loading && (
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          width={"100%"}
          height={140}
        >
          <CircularProgress color="secondary" />
        </Stack>
      )}
    </ForegroundPaper>
  );
}

const PageHeader = styled(Stack)(({ theme }) => ({
  flexDirection: "column",
  position: "sticky",
  top: 0,
  zIndex: 40,
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));
