import api from "../config/api";
import { useState } from "react";
import { useNotification } from "./useNotification";
import { useSnackbar } from "notistack";

const useCrud = () => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  let crud = {};

  crud.get = async (url) => {
    setLoading(true);
    try {
      const response = await api.get(url);
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);

      enqueueSnackbar(error.message, {
        variant: "error",
      });
    }
  };

  crud.post = async (url, data, message = "Created") => {
    setLoading(true);

    try {
      const response = await api.post(url, data);
      setLoading(false);

      enqueueSnackbar(message, {
        variant: "success",
      });
      return response.data;
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.messages) {
        let message = `${error?.response?.data?.messages}${
          error?.response?.data?.keyValue
            ? `: ${Object.values(error?.response?.data?.keyValue)[0]}`
            : ""
        } `;
        return enqueueSnackbar(message, {
          variant: "error",
        });
      }
      if (error.messages)
        return enqueueSnackbar({
          variant: "error",
          message: error.messages,
        });
    }
  };
  crud.put = async (url, data, message = "Updated") => {
    setLoading(true);
    try {
      const response = await api.put(url, data);
      setLoading(false);
      enqueueSnackbar(message);
      return response.data;
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.message)
        return enqueueSnackbar(error?.response?.data?.message, {
          variant: "error",
        });
      if (error.message)
        return enqueueSnackbar(error.message, {
          variant: "error",
        });
    }
  };

  crud.delete = async (url) => {
    setLoading(true);
    try {
      const response = await api.delete(url);
      setLoading(false);
      if (response?.data?.result === "false") {
        return enqueueSnackbar(response?.data?.messages, {
          variant: "error",
        });
      }
      return response.data;
    } catch (error) {
      setLoading(false);
      return enqueueSnackbar(error.message, {
        variant: "error",
      });
    }
  };

  return {
    loading,
    crud,
  };
};

export default useCrud;
