import * as React from "react";
import { Autocomplete, Box, Checkbox, TextField } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const MultipleSelect = ({ label, setSelectedId, AllCustomersList }) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <>
      <Autocomplete
        onChange={(event, newValue) => {
          setSelectedId(
            newValue.map((i) => i.id),
            null,
            ""
          );
        }}
        multiple
        id="checkboxes-tags-demo"
        options={AllCustomersList}
        disableCloseOnSelect
        getOptionLabel={(option) => option?.customer_number}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            <Box px={3}>{option.customer_number}</Box>
            {option.title}
          </li>
        )}
        renderInput={(params) => (
          <TextField {...params} label={label} placeholder={label} />
        )}
      />
    </>
  );
};
export default MultipleSelect;
