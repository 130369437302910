import { Typography } from "@mui/material";
import React from "react";

export default function Label(props) {
  return (
    <Typography variant="subtitle2" mb={3} px={0.5} fontWeight={700} {...props}>
      {props.children}
    </Typography>
  );
}
