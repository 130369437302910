import { Autocomplete, Box, Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";

import { Add, AddLocationAlt, Save } from "@mui/icons-material";
import ForegroundPaper from "../../../components/@common/ForegroundPaper";
import CustomInput from "../../../components/@common/CustomInput";
import CustomButton from "../../../components/@common/CustomButton";
import { useModal } from "../../../hooks/useModal";
import PageContainer from "../../../components/@common/PageContainer";
import CustomSelect from "../../../components/@common/CustomSelect";
import useForm from "../../../hooks/useForm";
import useFetch from "../../../hooks/useFetch";
import { blue } from "@mui/material/colors";
import useCrud from "../../../hooks/useCrud";
import DraggableMap from "../../../components/@common/Map/DraggableMap";

export default function AddNewCustomer() {
  const { crud, loading } = useCrud();
  const { setValues, values, handleInput, handleSelect } = useForm({
    customer_number: "",
    first_name: "",
    last_name: "",
    company_name: "",
    email: "",
    phone: "",
    mobile: "",
    address: "",
    city: "",
    zipcode: "",
    latitude: null,
    longitude: null,
    establishment: "",
    contract_status: "",
    contracted_by: null,
    start_place: "",
    time_note: "",
    collection_preference: "",
    working_time: "",
    prefered_language: "",
    note: "",
  });

  return (
    <PageContainer
      loading={loading}
      title={"Add new customer"}
      icon={<Add />}
      color={blue[500]}
      actions={
        <>
          <CustomButton
            label={"Save"}
            endIcon={<Save />}
            onClick={() => crud.post("/api/customers/admin/add", values)}
          />
        </>
      }
    >
      <AddNewCustomerForm
        setValues={setValues}
        values={values}
        handleInput={handleInput}
        handleSelect={handleSelect}
      />
    </PageContainer>
  );
}

function AddNewCustomerForm({ setValues, values, handleInput, handleSelect }) {
  const { setModal } = useModal();

  const { data: agents, loading: loadingAgents } = useFetch(
    "/api/managers/agents"
  );

  const serializedDrivers = agents?.drivers?.map((item) => {
    return {
      id: item.id,
      title: item.first_name + " " + item.last_name,
    };
  });

  return (
    <ForegroundPaper>
      <Stack
        direction={"row"}
        alignItems={"flex-start"}
        spacing={2}
        sx={{ width: "100%", py: 2 }}
      >
        <Stack
          direction={"column"}
          alignItems={"stretch"}
          justifyContent={"flex-start"}
          spacing={2}
          sx={{
            flex: 1,
          }}
        >
          <Stack
            sx={{ width: "100%" }}
            direction={"row"}
            justifyContent={"space-between"}
            spacing={2}
          >
            <Stack
              sx={{ width: "100%" }}
              direction={"column"}
              justifyContent={"flex-start"}
              spacing={2}
            >
              <Stack sx={{ width: "100%" }} direction={"row"} spacing={2}>
                <CustomInput
                  value={values.customer_number}
                  fullWidth
                  label={"Customer number"}
                  type="text"
                  onChange={handleInput("customer_number")}
                />
                <CustomInput
                  fullWidth
                  label={"First name"}
                  value={values.first_name}
                  type="text"
                  onChange={handleInput("first_name")}
                />
                <CustomInput
                  fullWidth
                  value={values.last_name}
                  label={"Last name"}
                  type="text"
                  onChange={handleInput("last_name")}
                />
                <CustomInput
                  value={values.company_name}
                  fullWidth
                  label={"Company name"}
                  type="text"
                  onChange={handleInput("company_name")}
                />
                <CustomInput
                  value={values.email}
                  fullWidth
                  label={"Email"}
                  type="email"
                  onChange={handleInput("email")}
                />
              </Stack>
            </Stack>
          </Stack>

          <Stack sx={{ width: "100%" }} direction={"row"} spacing={2}>
            <CustomInput
              value={values.phone}
              fullWidth
              label={"Phone"}
              type="tel"
              onChange={handleInput("phone")}
            />
            <CustomInput
              value={values.mobile}
              fullWidth
              label={"Mobile"}
              type="tel"
              onChange={handleInput("mobile")}
            />
            <SingleEntity
              label={"City"}
              value={values.city}
              onChange={handleSelect("city")}
              entityType={"city"}
            />
            <SingleEntity
              label={"Zip code"}
              value={values.zipcode}
              onChange={handleSelect("zipcode")}
              entityType={"zipcode"}
            />
            <CustomInput
              value={values.address}
              label={"address"}
              type="text"
              onChange={handleInput("address")}
            />
            <Box
              sx={{
                position: "relative",
                alignSelf: "center",
              }}
            >
              <CustomButton
                color="info"
                variant={
                  values.latitude && values.longitude ? "contained" : "outlined"
                }
                sx={{
                  height: 56,
                }}
                onClick={() =>
                  setModal({
                    title: "Pin the address",
                    child: (
                      <Stack
                        sx={{
                          position: "relative",
                          width: "50vw",
                          height: "50vh",
                        }}
                      >
                        <DraggableMap
                          setModal={setModal}
                          values={values}
                          setValues={setValues}
                        
                        />
                      </Stack>
                    ),
                  })
                }
                label={<AddLocationAlt />}
              />
            </Box>
          </Stack>
          <Stack sx={{ width: "100%" }} direction={"row"} spacing={2}>
            <SingleEntity
              value={values.prefered_language}
              label={"Preferred language"}
              entityType={"language"}
              onChange={handleSelect("prefered_language")}
            />
            <SingleEntity
              value={values.working_time}
              label={"Working time"}
              entityType={"working_time"}
              onChange={handleSelect("working_time")}
            />
            <CustomInput
              value={values.time_note}
              label={"Time note"}
              type="text"
              onChange={handleInput("time_note")}
            />
          </Stack>
          <Stack sx={{ width: "100%" }} direction={"row"} spacing={2}>
            <SingleEntity
              value={values.establishment}
              label={"Type of establishment"}
              entityType={"establishment"}
              onChange={handleSelect("establishment")}
            />
            <CustomSelect
              value={values.contract_status}
              label={"Contract status"}
              options={[
                { id: 1, title: "available" },
                { id: 2, title: "in progress" },
              ]}
              onChange={handleSelect("contract_status")}
            />
            <CustomSelect
              label={"Contracted by"}
              options={serializedDrivers}
              onChange={(e, opt) =>
                setValues({ ...values, contracted_by: opt.id })
              }
            />
            <CustomSelect
              value={values.collection_preference}
              label={"Collection preference"}
              options={[
                { id: 1, title: "every 20 days" },
                { id: 2, title: "every 30 days" },
                { id: 3, title: "every 40 days" },
              ]}
              onChange={handleSelect("collection_preference")}
            />
          </Stack>

          <CustomInput
            value={values.note}
            label={"Additional note"}
            type="text"
            multiline
            rows={2}
            onChange={handleInput("note")}
          />
        </Stack>
      </Stack>

      <Box></Box>
    </ForegroundPaper>
  );
}

function SelectGroup({ handleSingleValue, label, groups, value }) {
  return (
    <Autocomplete
      fullWidth
      disablePortal
      options={groups}
      autoHighlight
      value={value ? value : null}
      defaultValue={value}
      getOptionLabel={(option) => option.name}
      onChange={(event, newValue) => {
        if (newValue) {
          handleSingleValue("groupId", newValue._id);
        } else {
          handleSingleValue("groupId", null);
        }
      }}
      renderOption={(props, option) => (
        <Box {...props} key={option._id} component={"li"}>
          {option.name}
        </Box>
      )}
      renderInput={(params) => (
        <div ref={params.InputProps.ref}>
          <CustomInput
            label={label}
            isDropdown
            inputProps={{
              ...params.inputProps,
            }}
          />
        </div>
      )}
    />
  );
}
function SelectProvinceAndCity({
  handleSingleValue,
  initialProvince,
  initialCity,
}) {
  const [province, setProvince] = useState(null);

  useEffect(() => {
    setProvince(initialProvince);
  }, []);

  return (
    <>
      <Autocomplete
        onOpen={() => {
          handleSingleValue("province", null);
          handleSingleValue("city", null);
          setProvince(null);
        }}
        fullWidth
        disablePortal
        options={provinces}
        autoHighlight
        value={initialProvince ? initialProvince : null}
        getOptionLabel={(option) => option.name}
        onChange={(event, newValue) => {
          if (newValue) {
            handleSingleValue("province", newValue);
            setProvince(newValue);
          } else {
            setProvince(null);
            handleSingleValue("province", null);
          }
        }}
        renderOption={(props, option) => (
          <Box component="li" {...props} key={option.slug}>
            {option.name}
          </Box>
        )}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <CustomInput
              label={"استان"}
              isDropdown
              inputProps={{
                ...params.inputProps,
              }}
            />
          </div>
        )}
      />

      {!province && <CustomInput fullWidth disabled label={"شهر"} />}
      {province && (
        <Autocomplete
          fullWidth
          disablePortal
          options={cities?.filter((city) => city.province_id === province?.id)}
          autoHighlight
          value={initialCity ? initialCity : null}
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option.slug}>
              {option.name}
            </Box>
          )}
          onChange={(event, newValue) => {
            if (newValue) {
              handleSingleValue("city", newValue);
            } else {
              handleSingleValue("city", null);
            }
          }}
          renderInput={(params) => {
            return (
              <div ref={params.InputProps.ref}>
                <CustomInput
                  isDropdown
                  label={"شهر"}
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              </div>
            );
          }}
        />
      )}
    </>
  );
}

function SingleEntity({ value, label, entityType, onChange }) {
  const { data, loading } = useFetch(
    `/api/managers/form_fields/entity_type?entity_type=${entityType}`
  );

  return (
    <CustomSelect
      loading={loading}
      value={value}
      label={label}
      onChange={onChange}
      options={data?.data}
    />
  );
}
