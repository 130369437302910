import { MapContainer, Marker, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { FullscreenControl } from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/styles.css";

const LiveMap = ({ lat, lng, h = "70vh" }) => {
  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  });

  return (
    <>
      <MapContainer
        center={[lat, lng]}
        zoom={10}
        scrollWheelZoom={true}
        style={{
          width: "100%",
          height: h,
        }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <FullscreenControl
          position="topright"
          title="Full Screen"
          titleCancel="Standard Size"
        />
        <Marker position={[lat, lng]} />
      </MapContainer>
    </>
  );
};

export default LiveMap;
