import { Box, Chip, CircularProgress, Stack, Typography } from "@mui/material";
import LiveMap from "../components/@common/Map/liveMap";
import { useEffect, useState } from "react";
import useFetch from "../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import Clock from "react-live-clock";
import jMoment from "moment-jalaali";
import moment from "moment/moment";
import { getContrastedColor } from "../utils/getContrastedColor";
import { FaLongArrowAltLeft } from "react-icons/fa";
const AgentsLiveMap = () => {
  const { data: agents, loading } = useFetch(
    "/api/managers/livelocation?driver_id=1"
  );

  const navigate = useNavigate();
  const [time, setTime] = useState(1);

  const [livMapData, setLiveMapData] = useState([null, null]);

  useEffect(() => {
    if (agents && agents.data) {
      setLiveMapData([agents.data.latitude, agents.data.longitude]);
    }
  }, [agents]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (agents && agents.data) {
        setLiveMapData([agents.data.latitude, agents.data.longitude]);
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [agents]);

  return (
    <Box display={"flex"} flexDirection={"column"} height={"100vh"}>
      {loading && (
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <CircularProgress color="secondary" />
        </Box>
      )}
      <Box
        p={4}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"start"}
          spacing={2}
        >
          <Box sx={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
            <FaLongArrowAltLeft fontSize={"32px"} />
          </Box>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"start"}
            spacing={2}
          >
            <img src="/logo.png" width={32} />
            <Stack alignItems={"start"}>
              <Typography fontSize={16} fontWeight={700}>
                <Box component={"span"} sx={{ color: "#F7991C" }}>
                  Recycle
                </Box>
                <Box
                  component={"span"}
                  sx={{
                    color: "#21B24F",
                  }}
                >
                  Solution
                </Box>
              </Typography>
              <Typography
                fontSize={12}
                fontWeight={400}
                mt={-0.5}
                sx={{ opacity: 0.7 }}
              >
                Beyond collection
              </Typography>
            </Stack>
          </Stack>
          <Box
            component={"span"}
            sx={{
              width: "1px",
              height: 35,
              bgcolor: (theme) =>
                getContrastedColor(theme.palette.foreground.main),
              opacity: 0.2,
            }}
          ></Box>
          <Stack alignItems={"start"} sx={{ opacity: 0.7 }}>
            <Typography fontSize={18} fontWeight={700}>
              AI
            </Typography>
            <Typography
              fontSize={8}
              fontWeight={400}
              mt={-0.5}
              variant="button"
            >
              POWERED
            </Typography>
          </Stack>
        </Stack>

        <Chip
          label={
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              {time === 1 ? (
                <Typography fontSize={12}>CET</Typography>
              ) : (
                <Typography fontSize={12}>IRST</Typography>
              )}
              <Clock
                format={"HH:mm:ss"}
                ticking={true}
                timezone={time === 1 ? "CET" : "IRST"}
              />
              <span>|</span>
              <span>
                {time === 1
                  ? moment().utcOffset("+0100").format("MM/DD/YYYY")
                  : jMoment().format("jYYYY/jMM/jDD")}
              </span>
            </Stack>
          }
          clickable
          onClick={() => {
            if (time === 1) {
              setTime((prevTime) => prevTime + 1);
            } else {
              setTime(1);
            }
          }}
        />
      </Box>
      {!loading && livMapData[0] !== null && livMapData[1] !== null && (
        <LiveMap lat={livMapData[0]} lng={livMapData[1]} h="100vh" />
      )}
    </Box>
  );
};

export default AgentsLiveMap;
