import { Button, CircularProgress, Stack } from "@mui/material";
import React from "react";

export default function CustomButton(props) {
  return (
    <>
      {!props.loading && (
        <Button
          color={"secondary"}
          variant={"contained"}
          disabled={props.loading}
          {...props}
          sx={{
            borderRadius: (theme) => theme.settings.borderRadius - 2,
            ...props.sx,
          }}
        >
          {props.label}
        </Button>
      )}
      {props.loading && (
        <Stack
          minWidth={100}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            py: 1,
          }}
        >
          <CircularProgress
            size={25}
            sx={{
              color: (theme) => theme.palette.secondary.main,
            }}
          />
        </Stack>
      )}
    </>
  );
}
