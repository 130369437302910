import { Avatar, IconButton, Stack, Typography } from "@mui/material";

import { Add, Delete, Edit, Person2 } from "@mui/icons-material";

import CustomButton from "../../../components/@common/CustomButton";
import { AppPageHeader } from "../../../components/@app/AppHeader";
import PageContainer from "../../../components/@common/PageContainer";
import { useNavigate } from "react-router-dom";
import CustomTabs from "../../../components/@common/CustomTabs";
import { useNotification } from "../../../hooks/useNotification";
import { useState } from "react";
import { useEffect } from "react";
import api from "../../../config/api";
import Cookies from "js-cookie";
import CustomTable from "../../../components/@common/CustomTable";
import moment from "moment";
import useFetch from "../../../hooks/useFetch";
import { mutate } from "swr";
import { useModal } from "../../../hooks/useModal";
import { orange } from "@mui/material/colors";

export default function AgentManagement() {
  const navigate = useNavigate();
  const { setModal } = useModal();

  const { data, loading, error } = useFetch("/api/managers/agents");
  console.log(process.env.REACT_APP_PUBLIC_URL);
  const serializedDrivers = data?.drivers?.map((item) => {
    return {
      // /root/germanServerHome/home/drivers/recycle.drivers/public/drivers/profile/1/profile.jpg
      id: item.id,
      "": (
        <Avatar
          sx={{
            width: 24,
            height: 24,
          }}
          src={
            process.env.REACT_APP_SERVER_PUBLIC_URL +
            item.profile_picture?.slice(
              item.profile_picture.indexOf("public/drivers/profile/"),
              item.profile_picture.length
            )
          }
        />
      ),
      name: item.first_name + " " + item.last_name,
      mobile: item.mobile,
      email: item.email,
      created: moment(item.created_at).calendar(),
      " ": (
        <Stack direction={"row"} spacing={1}>
          <IconButton onClick={() => navigate(`edit/driver/${item.id}`)}>
            <Edit />
          </IconButton>
          <IconButton
            onClick={() =>
              setModal({
                title: "confirm delete",
                child: (
                  <ConfirmDelete
                    id={item.id}
                    agent_type={"driver"}
                    onClose={() => setModal(null)}
                  />
                ),
              })
            }
          >
            <Delete />
          </IconButton>
        </Stack>
      ),
    };
  });

  const serializedMarketers = data?.marketers?.map((item) => {
    return {
      id: item.id,
      name: item.first_name + " " + item.last_name,
      mobile: item.mobile,
      email: item.email,
      created: moment(item.created_at).calendar(),
      " ": (
        <Stack direction={"row"} spacing={1}>
          <IconButton onClick={() => navigate(`edit/driver/${item.id}`)}>
            <Edit />
          </IconButton>
          <IconButton
            onClick={() =>
              setModal({
                title: "confirm delete",
                child: (
                  <ConfirmDelete
                    id={item.id}
                    agent_type={"driver"}
                    onClose={() => setModal(null)}
                  />
                ),
              })
            }
          >
            <Delete />
          </IconButton>
        </Stack>
      ),
    };
  });

  const tabs = [
    {
      tabId: "drivers",
      label: "Drivers",
      content: (
        <CustomTable
          options={{
            rows: serializedDrivers,
            loading: loading,
            showId: false,
          }}
        />
      ),
    },
    {
      tabId: "marketers",
      label: "Marketers",
      content: (
        <CustomTable
          options={{
            rows: serializedMarketers,
            loading: false,
            showId: false,
          }}
        />
      ),
    },
  ];

  return (
    <PageContainer
      loading={loading}
      title={"Agent Management"}
      icon={<Person2 />}
      color={orange[500]}
      actions={
        <>
          <CustomButton
            label={"Add new"}
            endIcon={<Add />}
            onClick={() => navigate("/app/agent-management/add")}
          />
        </>
      }
    >
      {!error && <CustomTabs tabs={tabs} />}
    </PageContainer>
  );
}

function ConfirmDelete({ id, agent_type, onClose }) {
  const { setNotification } = useNotification();

  const deleteItem = async () => {
    try {
      const response = await api.post(
        "/api/managers/agents/remove",
        { id, agent_type },
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("gwmuser"),
          },
        }
      );

      mutate(`/api/managers/agents`, () => onClose());
      setNotification({
        message: "removed",
      });
      return response.data;
    } catch (error) {
      if (error.response.data.message) {
        setNotification({
          variant: "error",
          message: "error",
        });
      }
      return;
    }
  };

  return (
    <Stack
      sx={{
        p: 2,
      }}
      spacing={1}
    >
      <Typography>Are you sure?</Typography>
      <Stack direction={"row"} spacing={2} justifyContent={"end"}>
        <CustomButton
          label="cancel"
          variant="text"
          color="inherit"
          onClick={onClose}
        />
        <CustomButton
          label="confirm"
          variant="text"
          color="error"
          onClick={async () => await deleteItem()}
        />
      </Stack>
    </Stack>
  );
}
