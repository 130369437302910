import { Marker, Popup } from "react-leaflet";
import { useRef, useEffect, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Box } from "@mui/material";
import CustomInput from "../CustomInput";
import CustomButton from "../CustomButton";
import { FullscreenControl } from "react-leaflet-fullscreen";

const DraggableMap = ({ setModal, setValues, values }) => {
  delete L.Icon.Default.prototype._getIconUrl;
  L.Icon.Default.mergeOptions({
    iconRetinaUrl:
      "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png",
    iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
    shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
  });

  const [lat, setLat] = useState(values.latitude || 51.1642292);
  const [lng, setLng] = useState(values.longitude || 10.4541194);
  const [markerPos, setMarkerPos] = useState([
    values.latitude || 51.1642292,
    values.longitude || 10.4541194,
  ]);

  const mapRef = useRef();

  useEffect(() => {
    setValues({
      ...values,
      latitude: lat,
      longitude: lng,
    });
  }, [lat, lng, setValues, values]);

  const handleLatChange = (e) => {
    const newLat = e.target.value;
    setLat(newLat);
    if (!isNaN(parseFloat(newLat))) {
      setMarkerPos([parseFloat(newLat), lng]);
    }
  };

  const handleLngChange = (e) => {
    const newLng = e.target.value;
    setLng(newLng);
    if (!isNaN(parseFloat(newLng))) {
      setMarkerPos([lat, parseFloat(newLng)]);
    }
  };

  const updatePosition = () => {
    const newLat = parseFloat(lat);
    const newLng = parseFloat(lng);
    if (!isNaN(newLat) && !isNaN(newLng)) {
      setMarkerPos([newLat, newLng]);
      if (mapRef.current) {
        mapRef.current.setView([newLat, newLng], mapRef.current.getZoom());
        setModal(null);
      }
    } else {
      alert("Please enter valid coordinates.");
    }
  };

  const handleMarkerMove = (event) => {
    const newPos = event.target.getLatLng();
    setMarkerPos([newPos.lat, newPos.lng]);
    setLat(newPos.lat);
    setLng(newPos.lng);
  };

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setView(markerPos, mapRef.current.getZoom());
    }
  }, [markerPos]);

  return (
    <Box>
      <Box display={"flex"} gap={1} p={2}>
        <CustomInput
          label="Latitude"
          type="text"
          value={lat}
          onChange={handleLatChange}
          placeholder="Enter latitude"
        />
        <CustomInput
          label="Longitude"
          type="text"
          value={lng}
          onChange={handleLngChange}
          placeholder="Enter longitude"
        />
        <CustomButton label={"Update"} onClick={updatePosition} />
      </Box>
      <MapContainer
        center={markerPos}
        zoom={13}
        style={{ height: "400px", width: "100%" }}
        ref={mapRef}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <FullscreenControl
          position="topright"
          title="Full Screen"
          titleCancel="Standard Size"
        />
        <Marker
          draggable
          autoPan
          eventHandlers={{
            moveend: handleMarkerMove,
          }}
          position={markerPos}
        >
          <Popup>
            A marker at ({lat}, {lng})
          </Popup>
        </Marker>
      </MapContainer>
    </Box>
  );
};

export default DraggableMap;
