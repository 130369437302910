import { Close } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Stack,
  Typography,
  alpha,
  capitalize,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import { AnimatePresence, motion } from "framer-motion";
import { createContext, forwardRef, useContext, useState } from "react";
import ForegroundPaper from "../components/@common/ForegroundPaper";
import { getContrastedColor } from "../utils/getContrastedColor";

const ModalContext = createContext({});

export const ProvideModal = ({ children }) => {
  const [content, setModal] = useState(null);

  return (
    <motion.div layout>
      <ModalContext.Provider
        value={{
          setModal,
        }}
      >
        <Modal
          open={content ? true : false}
          onClose={() => setModal(null)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AnimatePresence>
            <ForegroundPaper
              component={motion.div}
              layout
              initial={{
                opacity: 0,
                translateY: 200,
                scale: 0.8,
              }}
              animate={{
                opacity: 1,
                translateY: 0,
                scale: 1,
                transition: {
                  delay: 0.1,
                },
              }}
              exit={{
                opacity: 0,
                scale: 1.2,
              }}
              sx={{
                minWidth: 400,
              }}
            >
              <Stack
                sx={{
                  position: "relative",
                  maxHeight: "80vh",
                }}
              >
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  px={1}
                  py={1}
                  sx={{
                    position: "sticky",
                    top: 0,
                    zIndex: 10,
                    bgcolor: (theme) => theme.palette.foreground.main,
                    borderBottom: "1px solid",
                    borderColor: (theme) =>
                      alpha(
                        getContrastedColor(theme.palette.foreground.main),
                        0.1
                      ),
                  }}
                >
                  <Typography px={1}>
                    {content?.title && capitalize(content?.title)}
                  </Typography>
                  <IconButton onClick={() => setModal(null)}>
                    <Close fontSize="small" />
                  </IconButton>
                </Stack>
                <ChildElement>{content?.child}</ChildElement>
              </Stack>
            </ForegroundPaper>
          </AnimatePresence>
        </Modal>
        {children}
      </ModalContext.Provider>
    </motion.div>
  );
};

export const useModal = () => useContext(ModalContext);

const ChildElement = forwardRef((props, ref) => (
  <motion.div
    layout
    ref={ref}
    style={{
      overflowY: "auto",
    }}
  >
    {props?.children}
  </motion.div>
));
