import { Autocomplete, Box, Stack, capitalize } from "@mui/material";
import CustomInput from "./CustomInput";
import { useEffect, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { motion } from "framer-motion";

export default function CustomSelect(props) {
  const { label, options } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Autocomplete
      disabled={props.disabled}
      fullWidth
      loading={props.loading}
      disablePortal
      options={options || []}
      autoHighlight
      onChange={props.onChange}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      isOptionEqualToValue={(option, value) => {
        return option !== value;
      }}
      getOptionLabel={(option) => {
        return option.title
          ? capitalize(String(option.title).replace("_", " "))
          : "";
      }}
      renderOption={(props, option) => (
        <Box {...props} key={option.id} component={"li"}>
          {option.title
            ? capitalize(String(option.title).replace("_", " "))
            : ""}
        </Box>
      )}
      renderInput={(params) => {
        return (
          <div ref={params.InputProps.ref}>
            <CustomInput
              disabled={props.disabled}
              label={label}
              dropdown={"true"}
              inputProps={{
                ...params.inputProps,
                value: props.value && capitalize(String(props.value)),
                endAdornment: (
                  <Box
                    component={motion.div}
                    transition={{
                      duration: 0.03,
                    }}
                    sx={{
                      position: "absolute",
                      right: 18,
                      top: 18,
                      pointerEvents: "none",
                      opacity: 0.7,
                    }}
                  >
                    {isOpen ? (
                      <motion.div>
                        <FaChevronUp />
                      </motion.div>
                    ) : (
                      <motion.div>
                        <FaChevronDown />
                      </motion.div>
                    )}
                  </Box>
                ),
              }}
            />
          </div>
        );
      }}
      sx={{
        typography: {
          color: "white",
        },
      }}
    />
  );
}
