import {
  CircularProgress,
  Stack,
  Typography,
  lighten,
  darken,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Checkbox,
  TablePagination,
  styled,
} from "@mui/material";
import { useEffect, useState } from "react";
import ForegroundPaper from "../../../../components/@common/ForegroundPaper";
import { FaBoxOpen } from "react-icons/fa6";
import CustomTableToolbar from "./CustomTableToolbar";
import api from "../../../../config/api";
import { getRandomNumber } from "../../../../utils/getRandomNumber";
import moment from "moment";
import { getContrastedColor } from "../../../../utils/getContrastedColor";
import { FilterAlt } from "@mui/icons-material";
import CustomInput from "../../../../components/@common/CustomInput";

const CustomerList = ({
  selectedItem,
  setSelectedItem,
  AllCustomers,
  setAllCustomers,
  outlined,
  title,
  action,
  emptyText = "Empty",
  defaultRowsPerPageOptions = [10, 25, 50],
  defaultRowsPerPage = 10,
  setSortedList,
  filterdCustomers,
  setFilteredCustomers,
  handleCheckboxChange,
  onClick,
}) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("createdAt");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const [rows, setRows] = useState(AllCustomers);
  const [loading, setLoading] = useState(false);
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    p: 0,
    zIndex: 0,
    color: getContrastedColor(theme.palette.foreground.main),
    ".MuiSvgIcon-root": {
      color: getContrastedColor(theme.palette.foreground.main),
    },

    ".Mui-active": {
      color: getContrastedColor(theme.palette.foreground.main),
    },
    ".Mui-active .MuiSvgIcon-root": {
      color: getContrastedColor(theme.palette.foreground.main),
    },
  }));

  const cellSx = [
    {
      color: (theme) =>
        theme.palette.mode === "light"
          ? darken(theme.palette.foreground.main, 0.7)
          : lighten(theme.palette.foreground.main, 0.7),
    },
  ];

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAll = () => {
    const allIds = AllCustomers.map((row) => row.id);
    if (selectedItem.length === allIds.length) {
      setSelectedItem([]);
    } else {
      setSelectedItem(allIds);
    }
  };

  let columns = [
    { title: "CN" },
    { title: "CoName" },
    { title: "WT" },
    { title: "City" },
    { title: "ZCode" },
    { title: "c-rank" },
    { title: "Last Visit" },
  ];

  const getCustomers = async () => {
    setLoading(true);
    try {
      const response = await api.get("/api/managers/customers");
      setAllCustomers(response?.data?.data);

      setLoading(false);

      return response.data;
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCustomers();
  }, []);

  const AllCustomersList = [...AllCustomers].map((customer) => {
    return {
      id: customer.id,
      CN: customer.customer_number,
      CoName: customer.company_name,
      WT: customer.working_time,
      city: customer.city,
      ZCode: customer.zipcode,
      "c-rank": getRandomNumber(1, 10, 2),
      "last visit":
        customer?.customer_visits_history &&
        moment(customer?.customer_visits_history[0]?.visit_date).format(
          "YYYY/MM/DD"
        ),
    };
  });

  const checkedList = selectedItem?.map((id) => {
    return AllCustomers?.map((i) => i).find((row) => row.id === id);
  });

  useEffect(() => {
    setSortedList(checkedList);
  }, [selectedItem]);

  useEffect(() => {
    setFilteredCustomers(AllCustomersList);
  }, [loading]);

  return (
    <>
      <CustomInput
        label={"Filter customers"}
        endAdornment={<FilterAlt />}
        onChange={(e) => {
          const term = e.target.value.toLowerCase();
          setFilteredCustomers(() => {
            return AllCustomersList.filter((c) => {
              if (
                c?.CoName?.toLowerCase()?.startsWith(term) ||
                c?.CN?.startsWith(term)
              ) {
                return c;
              }
            });
          });
        }}
      />
      {loading && (
        <ForegroundPaper
          sx={{
            width: "100%",
            border: outlined ? 1 : 0,
            borderColor: (theme) =>
              theme.palette.mode === "light"
                ? darken(theme.palette.foreground.main, 0.25)
                : lighten(theme.palette.foreground.main, 0.25),
          }}
        >
          <Stack
            direction={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            width={"100%"}
            height={120}
          >
            <CircularProgress color="inherit" />
          </Stack>
        </ForegroundPaper>
      )}

      {!loading && AllCustomers?.length === 0 && (
        <ForegroundPaper
          sx={{
            width: "100%",
            border: outlined ? 1 : 0,
            borderColor: (theme) =>
              theme.palette.mode === "light"
                ? darken(theme.palette.foreground.main, 0.25)
                : lighten(theme.palette.foreground.main, 0.25),
          }}
        >
          {title && (
            <CustomTableToolbar
              title={title}
              numSelected={selectedItem.length}
              action={action}
            />
          )}
          <Stack
            direction={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            width={"100%"}
            height={120}
            spacing={1}
            sx={{ opacity: 0.5 }}
          >
            <FaBoxOpen size={22} />
            <Typography>{emptyText}</Typography>
          </Stack>
        </ForegroundPaper>
      )}

      {AllCustomers?.length >= 1 && !loading && (
        <ForegroundPaper
          sx={{
            width: "100%",
            border: outlined ? 1 : 0,
            borderColor: (theme) =>
              theme.palette.mode === "light"
                ? darken(theme.palette.foreground.main, 0.25)
                : lighten(theme.palette.foreground.main, 0.25),
          }}
        >
          <TableContainer
            sx={{
              maxHeight: "100vh",
            }}
          >
            <Table fixedFooter={true} selectable={true}>
              <TableHead>
                <TableRow>
                  <TableCell sx={cellSx}>
                    <Checkbox
                      indeterminate={
                        selectedItem.length > 0 &&
                        selectedItem.length < AllCustomers.length
                      }
                      checked={selectedItem.length === AllCustomers.length}
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  {columns?.map((item) => (
                    <TableCell sx={cellSx} key={item?.id}>
                      {item?.title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filterdCustomers
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row) => (
                    <TableRow
                      key={row?.id}
                      onClick={(e) => {
                        if (e.target.tagName !== "INPUT") {
                          onClick(row);
                        }
                      }}
                      sx={{
                        cursor: "pointer",
                        ":hover": {
                          bgcolor: (theme) =>
                            theme.palette.mode === "light"
                              ? darken(theme.palette.foreground.main, 0.05)
                              : lighten(theme.palette.foreground.main, 0.05),
                        },
                      }}
                    >
                      <TableCell sx={cellSx}>
                        <StyledTableCell
                          role="checkbox"
                          padding={"checkbox"}
                          sx={{
                            cursor: "pointer",
                            borderBottom: "none",
                          }}
                        >
                          <Checkbox
                            checked={selectedItem.includes(row.id)}
                            onChange={() => handleCheckboxChange(row)}
                          />
                        </StyledTableCell>
                      </TableCell>

                      <TableCell sx={cellSx}>{row?.CN}</TableCell>
                      <TableCell sx={cellSx}>{row?.CoName}</TableCell>
                      <TableCell sx={cellSx}>{row?.WT}</TableCell>
                      <TableCell sx={cellSx}>{row?.city}</TableCell>
                      <TableCell sx={cellSx}>{row?.ZCode}</TableCell>
                      <TableCell sx={cellSx}>{row?.["c-rank"]}</TableCell>
                      <TableCell sx={cellSx}>{row?.["last visit"]}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            sx={{
              position: "sticky",
              bottom: -2,

              bgcolor: (theme) => theme.palette.foreground.main,
              color: (theme) =>
                getContrastedColor(theme.palette.foreground.main),
              ".MuiButtonBase-root": {
                transform: "scaleX(-1)",
              },
              ".Mui-disabled .MuiSvgIcon-root": {
                color: (theme) =>
                  darken(
                    getContrastedColor(theme.palette.foreground.main),
                    0.2
                  ),
              },

              ".MuiSvgIcon-root": {
                color: (theme) =>
                  getContrastedColor(theme.palette.foreground.main),
              },
              ".MuiMenu-paper": {
                backgroundColor: (theme) =>
                  getContrastedColor(theme.palette.foreground.main),
              },
            }}
            rowsPerPageOptions={defaultRowsPerPageOptions}
            component="div"
            count={AllCustomers?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </ForegroundPaper>
      )}
    </>
  );
};

export default CustomerList;
