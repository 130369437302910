import { blue } from "@mui/material/colors";
import useFetch from "../../../hooks/useFetch";
import PageContainer from "../../../components/@common/PageContainer";
import { FaFileContract } from "react-icons/fa6";
import {
  Box,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useModal } from "../../../hooks/useModal";
import CustomTextarea from "../../../components/@common/CustomTextArea";
import CustomButton from "../../../components/@common/CustomButton";
import useCrud from "../../../hooks/useCrud";
import { useEffect } from "react";
import { useNotification } from "../../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
import ForegroundPaper from "../../../components/@common/ForegroundPaper";

const CustomerContract = () => {
  const { data: contract_data, loading: contractLoading } = useFetch(
    "/api/managers/contracts"
  );
  const navigate = useNavigate();
  const { setModal } = useModal();
  const { crud } = useCrud();

  const customerContract = contract_data?.data?.map((item) => item?.content);

  const { setNotification } = useNotification();
  const confirm = () => {
    crud
      .put(
        `/api/managers/contracts/editContract`,
        { content: value, id: 1 },
        "Updated"
      )
      .then((response) => {
        if (response?.data) {
          setNotification({
            variant: "success",
            message: response.data.message,
          });
        }
        setModal(null);
      });

    return;
  };

  let value = "";

  useEffect(() => {
    value = customerContract;
  }, [contract_data]);

  return (
    <PageContainer
      title={"Customer Contract"}
      icon={<FaFileContract color={`#FFFFFF`} />}
      color={blue[500]}
      actions={
        <CustomButton
          label={"add contract relation"}
          onClick={() => {
            navigate("/app/contract_relation");
          }}
        />
      }
    >
      <Box display={"flex"} flexDirection={"column"} gap={"1rem"} px={"2rem"}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h3" gutterBottom>
            Contract :
          </Typography>
          <CustomButton
            label="edit contract"
            onClick={() => {
              setModal({
                title: "Contract Edit",
                child: (
                  <Box p={2} width={"40rem"}>
                    <CustomTextarea
                      value={value}
                      onChange={(e) => {
                        value = e.target.value;
                      }}
                    />
                    <Divider />
                    <Box
                      display={"flex"}
                      gap={"1rem"}
                      justifyContent={"right"}
                      mt={2}
                    >
                      <CustomButton
                        variant={"outlined"}
                        label="Cancel"
                        onClick={() => setModal(null)}
                      />
                      <CustomButton label="Confirm" onClick={confirm} />
                    </Box>
                  </Box>
                ),
              });
            }}
          />
        </Box>
        {contractLoading && (
          <ForegroundPaper
            sx={{
              width: "100%",
            }}
          >
            <Stack
              direction={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              width={"100%"}
              height={120}
            >
              <CircularProgress color="inherit" />
            </Stack>
          </ForegroundPaper>
        )}
        {!contractLoading && (
          <Box>
            <Typography variant="h6">{customerContract}</Typography>
          </Box>
        )}
      </Box>
    </PageContainer>
  );
};

export default CustomerContract;
