import { Stack } from "@mui/material";

import { useState } from "react";
import { Palette as PaletteIcon } from "@mui/icons-material";
import { useAppTheme } from "../hooks/useAppTheme";
import { colors } from "../utils/colors";
import ColorPalette from "../components/@theme/ColorPalette";
import CustomButton from "../components/@common/CustomButton";
import { AppPageHeader } from "../components/@app/AppHeader";

export default function Settings() {
  const { appTheme, changeThemeSettings, changePalette, changeComponent } =
    useAppTheme();
  const [palette, setPallete] = useState(appTheme.palette);

  let paletteProps = {
    appTheme,
    changeThemeSettings,
    changePalette,
    changeComponent,
    palette,
    setPallete,
  };
  return (
    <Stack
      direction={"column"}
      justifyContent={"center"}
      alignItems={"stretch"}
      p={2}
    >
      <AppPageHeader
        title={"تنظیمات نمایه"}
        icon={<PaletteIcon />}
        actions={
          <Stack direction={"row"} spacing={2}>
            <CustomButton
              label={"شب"}
              endIcon={"🌙"}
              onClick={() => {
                setPallete({ ...palette, primary: { main: "#212121" } });

                return changePalette({
                  primary: {
                    main: "#292929",
                  },
                  secondary: {
                    main: "#00e676",
                  },
                  tertiary: {
                    main: "#00e676",
                  },
                  background: {
                    main: "#212121",
                  },
                  foreground: {
                    main: "#292929",
                  },
                });
              }}
              sx={{
                color: "#f1f1f1",
              }}
              color="dark"
            />
            <CustomButton
              label={"روز"}
              endIcon={"☀️"}
              sx={{
                color: "#212121",
              }}
              color="light"
              onClick={() => {
                return changePalette({
                  primary: {
                    main: "#eeeeee",
                  },
                  secondary: {
                    main: "#1976d2",
                  },
                  tertiary: {
                    main: "#1976d2",
                  },
                  background: {
                    main: "#e0e0e0",
                  },
                  foreground: {
                    main: "#eeeeee",
                  },
                });
              }}
            />
            <CustomButton
              label={"🔳"}
              color="secondary"
              variant="text"
              onClick={() => {
                setPallete({ ...palette, primary: { main: "#f1f1f1" } });

                return changePalette({
                  primary: {
                    main: "#222222",
                  },
                  secondary: {
                    main: "#222222",
                  },
                  tertiary: {
                    main: "#222222",
                  },
                  background: {
                    main: "#eeeeee",
                  },
                  foreground: {
                    main: "#eeeeee",
                  },
                });
              }}
            />
            <CustomButton
              label={"🔀"}
              variant="text"
              color="secondary"
              onClick={() => {
                if (colors) {
                  function randomIntFromInterval(min, max) {
                    return Math.floor(Math.random() * (max - min + 1) + min);
                  }
                  let randomColorPrimary = "";
                  let randomColorSecondary = "";
                  let randomColorBg = "";
                  let randomColorTertiary = "";

                  function getRandColor() {
                    if (
                      colors[randomIntFromInterval(0, colors.length)] !==
                      undefined
                    ) {
                      return colors[randomIntFromInterval(0, colors.length)];
                    } else {
                      return colors[0];
                    }
                  }

                  randomColorPrimary = getRandColor();
                  randomColorSecondary = getRandColor();
                  randomColorBg = getRandColor();
                  randomColorTertiary = getRandColor();

                  setPallete({
                    ...palette,
                    primary: { main: randomColorPrimary },
                  });

                  return changePalette({
                    primary: {
                      main: randomColorPrimary,
                    },
                    secondary: {
                      main: randomColorSecondary,
                    },
                    tertiary: {
                      main: randomColorTertiary,
                    },
                    background: {
                      main: randomColorBg,
                    },
                    foreground: {
                      main: randomColorBg,
                    },
                  });
                }
              }}
            />
          </Stack>
        }
      />

      <ColorPalette {...paletteProps} />
    </Stack>
  );
}
