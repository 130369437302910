import { Add, FilterAlt, Group } from "@mui/icons-material";

import CustomButton from "../../../components/@common/CustomButton";
import PageContainer from "../../../components/@common/PageContainer";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import CustomTable from "../../../components/@common/CustomTable";
import api from "../../../config/api";
import Cookies from "js-cookie";
import { useNotification } from "../../../hooks/useNotification";
import { mutate } from "swr";

import useFetch from "../../../hooks/useFetch";
import { useModal } from "../../../hooks/useModal";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { blue } from "@mui/material/colors";
import ImportCSV from "../WarehouseManagement/ImportCSV";
import Oil from "../Oil";
import CustomInput from "../../../components/@common/CustomInput";
const CustomerManagement = () => {
  const { setModal } = useModal();
  const { data, loading, error } = useFetch("/api/customers/admin");
  const navigate = useNavigate();
  const [filterdCustomers, setFilteredCustomers] = useState([]);

  const serializedData = data?.data?.map((item) => {
    return {
      id: item.id,
      CN: item?.customer_number,
      name: item.first_name + " " + item.last_name,
      CoName: item?.company_name,
      phone: item.phone,
      email: item.email,
      "Contract price": item.last_price,
      "last visit":
        item?.customer_visits_history &&
        item?.customer_visits_history[item.customer_visits_history.length - 1]
          ?.visit_date,
    };
  });

  useEffect(() => {
    setFilteredCustomers(serializedData);
  }, [loading]);
  const [exportPdfLoading, setExportPdfLoading] = useState(false);

  const { setNotification } = useNotification();

  const handlePdfDownload = async (id) => {
    setExportPdfLoading(true);
    try {
      const response = await api
        .get(`api/drivers/contract/pdf?entity_id=${id}`, {
          responseType: "blob",
        })
        .then((response) => {
          setExportPdfLoading(false);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `contract.pdf`);
          document.body.appendChild(link);
          link.click();
          setNotification({
            message: "Pdf Downloaded",
            variant: "success",
          });
        });
    } catch (error) {
      console.error("Error downloading PDF:", error);
      setNotification({
        message: "PDF is not available",
        variant: "error",
      });
      setExportPdfLoading(false);
    }
  };

  return (
    <PageContainer
      loading={loading}
      title={"Customer Management"}
      icon={<Group />}
      color={blue[500]}
      actions={
        <>
          <CustomButton
            label={"Import CSV"}
            variant={"text"}
            onClick={() => {
              setModal({
                title: "Import CSV",
                child: <ImportCSV />,
              });
            }}
          />
          <CustomButton
            label={"Add new"}
            endIcon={<Add color="inherit" />}
            onClick={() => navigate("/app/customer-management/add")}
          />
        </>
      }
    >
      <CustomInput
        label={"Filter customers"}
        endAdornment={<FilterAlt />}
        onChange={(e) => {
          const term = e.target.value.toLowerCase();
          setFilteredCustomers(() => {
            return serializedData.filter((c) => {
              if (
                c?.name?.toLowerCase()?.startsWith(term) ||
                c.CN?.startsWith(term) ||
                c?.CoName?.toLowerCase()?.startsWith(term)
              ) {
                return c;
              }
            });
          });
        }}
      />
      {!error && (
        <CustomTable
          onEdit={(id) => navigate(`edit/${id}`)}
          onDelete={(id) =>
            setModal({
              title: "confirm delete",
              child: <ConfirmDelete id={id} onClose={() => setModal(null)} />,
            })
          }
          exportPdf={handlePdfDownload}
          exportPdfLoading={exportPdfLoading}
          options={{
            rows: filterdCustomers,
            loading: loading,
            showId: false,
          }}
        />
      )}
    </PageContainer>
  );
};

function ConfirmDelete({ id, onClose }) {
  const { setNotification } = useNotification();
  const [loading, setLoading] = useState(false);
  const deleteItem = async () => {
    setLoading(true);
    try {
      const response = await api.post(
        "/api/managers/customers/remove",
        { id },
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("gwmuser"),
          },
        }
      );

      mutate(`/api/customers/admin`, () => onClose());
      setNotification({
        message: "removed",
      });
      setLoading(false);
      return response.data;
    } catch (error) {
      if (error.response.data.message) {
        setNotification({
          variant: "error",
          message: "error",
        });
      }
      setLoading(false);
      return;
    }
  };

  return (
    <Stack
      sx={{
        p: 2,
      }}
      spacing={1}
    >
      <Typography>Are you sure?</Typography>
      <Stack direction={"row"} spacing={2} justifyContent={"end"}>
        {!loading && (
          <>
            <CustomButton
              label="cancel"
              variant="text"
              color="inherit"
              onClick={onClose}
            />
            <CustomButton
              label="confirm"
              variant="text"
              color="error"
              onClick={async () => await deleteItem()}
            />
          </>
        )}
        {loading && <CircularProgress color="secondary" />}
      </Stack>
    </Stack>
  );
}
export default CustomerManagement;
