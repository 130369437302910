import { MdTv } from "react-icons/md";
import {
  Avatar,
  Box,
  MenuItem,
  Menu,
  Stack,
  Typography,
  capitalize,
  ListItemText,
  ListItemAvatar,
  Chip,
  IconButton,
} from "@mui/material";
import Clock from "react-live-clock";

import AppDrawer from "./AppDrawer";
import {
  DarkMode,
  HelpOutline,
  LanguageOutlined,
  LightMode,
  Logout,
  Menu as MenuIcon,
} from "@mui/icons-material";

import BackgroundPaper from "../@common/BackgroundPaper";

import { getContrastedColor } from "../../utils/getContrastedColor";
import { useAppTheme } from "../../hooks/useAppTheme";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { blue } from "@mui/material/colors";
import moment from "moment/moment";
import jMoment from "moment-jalaali";
import { useEffect, useState } from "react";
export default function AppLayout({
  isAccounting,
  handleLogout,
}) {
  const { appTheme, changeThemeSettings, changePalette } = useAppTheme();
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [time, setTime] = useState(1);
  const [accountElement, setAccountElement] = useState(null);
  const [languageElement, setLanguageElement] = useState(null);
  const openAccountMenu = Boolean(accountElement);
  const openLanguageMenu = Boolean(languageElement);

  const handleOpenAccountMenu = (event) => {
    setAccountElement(event.currentTarget);
  };
  const handleOpenLanguageMenu = (event) => {
    setLanguageElement(event.currentTarget);
  };

  const handleDarkMode = () => {
    setAccountElement(null);
    changePalette(appTheme.palette.mode === "light" ? darkMode : lightMode);
  };

  const location = useLocation();

  const handleDrawer = () => {
    changeThemeSettings({
      drawer: {
        isOpen: !appTheme.settings.drawer.isOpen,
      },
    });
  };

  useEffect(() => {
    let userInfo = localStorage.getItem("userInfo");

    if (userInfo) {
      setUser(JSON.parse(userInfo));
    }
  }, []);

  useEffect(() => {
    if (location.pathname.endsWith("dashboard")) {
      changeThemeSettings({
        drawer: {
          isOpen: false,
        },
      });
    } else {
      changeThemeSettings({
        drawer: {
          isOpen: true,
        },
      });
    }
  }, [location]);


  return (
    <motion.div layout>
      <Stack
        direction={"row"}
        height={"100vh"}
        width={"100%"}
        sx={{
          overflow: "hidden",
          bgcolor: (theme) => theme.palette.primary.main,
        }}
      >
        <AnimatePresence>
          {appTheme.settings.drawer.isOpen && (
            <motion.div
              initial={{
                width: 0,
              }}
              transition={{
                ease: "easeInOut",
              }}
              animate={{
                width: 320,
              }}
              exit={{
                width: 0,
                transition: {
                  delay: 0.2,
                },
              }}
            >
              <AppDrawer
                isAccounting={isAccounting}
                drawerWidth={320}
                open={appTheme.settings.drawer.isOpen}
                onClick={handleDrawer}
              />
            </motion.div>
          )}
        </AnimatePresence>
        <Stack direction={"column"} width={"100%"}>
          <Stack
            sx={{
              boxShadow: 0,
              bgcolor: (theme) => `${theme.palette.primary.main} !important`,
            }}
          >
            <Stack
              direction={"row"}
              spacing={4}
              sx={{
                height: 64,
                px: 1.5,
                justifyContent: "space-between",
                alignItems: "center",
                ":root": { minWidth: "100%" },
              }}
            >
              {!appTheme.settings.drawer.isOpen && (
                <AnimatePresence>
                  <motion.div
                    initial={{
                      translateY: -60,
                      opacity: 0,
                    }}
                    transition={{
                      ease: "easeInOut",
                    }}
                    animate={{
                      translateY: 0,
                      opacity: 1,
                      transition: {
                        delay: 1,
                      },
                    }}
                    exit={{
                      translateY: -40,
                      opacity: 0,
                      scale: 0,
                    }}
                  >
                    <Stack direction={"row"} alignItems={"center"}>
                      <IconButton
                        onClick={handleDrawer}
                        sx={{
                          color: (theme) =>
                            getContrastedColor(theme.palette.primary.main),
                        }}
                      >
                        <MenuIcon />
                      </IconButton>
                    </Stack>
                  </motion.div>
                </AnimatePresence>
              )}

              <AnimatePresence>
                <motion.div
                  style={{
                    flex: 1,
                  }}
                  initial={{
                    translateY: -60,
                    opacity: 0,
                  }}
                  transition={{
                    ease: "easeInOut",
                  }}
                  animate={{
                    translateY: 0,
                    opacity: 1,
                    transition: {
                      delay: 1,
                    },
                  }}
                  exit={{
                    translateX: -40,
                    opacity: 0,
                    transition: {
                      delay: 0.4,
                    },
                  }}
                >
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    width={"100%"}
                    sx={{
                      transition: "all 0.3s",
                    }}
                  >
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"start"}
                      spacing={2}
                    >
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"start"}
                        spacing={2}
                      >
                        <img src="/logo.png" width={32} />
                        <Stack alignItems={"start"}>
                          <Typography fontSize={16} fontWeight={700}>
                            <Box component={"span"} sx={{ color: "#F7991C" }}>
                              Recycle
                            </Box>
                            <Box
                              component={"span"}
                              sx={{
                                color: "#21B24F",
                              }}
                            >
                              Solution
                            </Box>
                          </Typography>
                          <Typography
                            fontSize={12}
                            fontWeight={400}
                            mt={-0.5}
                            sx={{ opacity: 0.7 }}
                          >
                            Beyond collection
                          </Typography>
                        </Stack>
                      </Stack>
                      <Box
                        component={"span"}
                        sx={{
                          width: "1px",
                          height: 35,
                          bgcolor: (theme) =>
                            getContrastedColor(theme.palette.foreground.main),
                          opacity: 0.2,
                        }}
                      ></Box>
                      <Stack alignItems={"start"} sx={{ opacity: 0.7 }}>
                        <Typography fontSize={18} fontWeight={700}>
                          AI
                        </Typography>
                        <Typography
                          fontSize={8}
                          fontWeight={400}
                          mt={-0.5}
                          variant="button"
                        >
                          POWERED
                        </Typography>
                      </Stack>
                    </Stack>

                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                      <Box
                        sx={{ cursor: "pointer" ,marginTop:2}}
                        onClick={()=> navigate('/livemap')}
                      >
                        <MdTv fontSize={'32px'}  />
                      </Box>

                      {user && (
                        <Box>
                          <Chip
                            variant="outlined"
                            label={` Welcome, ${capitalize(
                              user?.first_name
                            )} ${capitalize(user?.last_name)}`}
                            icon={<Avatar sx={{ width: 20, height: 20 }} />}
                            clickable
                            onClick={handleOpenAccountMenu}
                          />
                          <Menu
                            id="account-menu"
                            anchorEl={accountElement}
                            open={openAccountMenu}
                            onClose={() => setAccountElement(null)}
                            slotProps={{
                              paper: {
                                elevation: 0,
                                sx: {
                                  overflow: "visible",
                                  filter:
                                    "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                  mt: 1.5,
                                  overflow: "hidden",
                                  borderRadius: (theme) =>
                                    theme.settings.borderRadius,
                                },
                              },
                            }}
                            transformOrigin={{
                              horizontal: "left",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "left",
                              vertical: "bottom",
                            }}
                          >
                            <MenuItem>
                              <ListItemAvatar>
                                <Avatar sx={{ width: 32, height: 32 }} />
                              </ListItemAvatar>
                              <ListItemText
                                primary={`${capitalize(user.first_name)} ${
                                  user.last_name
                                }`}
                                secondary={user.email}
                              />
                            </MenuItem>
                            <MenuItem onClick={handleDarkMode}>
                              <ListItemAvatar>
                                <Avatar
                                  sx={{
                                    width: 32,
                                    height: 32,
                                    bgcolor: "transparent",
                                  }}
                                >
                                  {appTheme.palette.mode === "light" ? (
                                    <DarkMode fontSize="small" />
                                  ) : (
                                    <LightMode fontSize="small" />
                                  )}
                                </Avatar>
                              </ListItemAvatar>

                              <ListItemText
                                primary={
                                  appTheme.palette.mode === "light"
                                    ? "Dark mode"
                                    : "Light mode"
                                }
                              />
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleLogout();
                                navigate("/");
                                setAccountElement(null);
                              }}
                            >
                              <ListItemAvatar>
                                <Avatar
                                  sx={{
                                    width: 32,
                                    height: 32,
                                    bgcolor: "transparent",
                                  }}
                                >
                                  <Logout fontSize="small" />
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText primary={"Logout"} />
                            </MenuItem>
                          </Menu>
                        </Box>
                      )}
                      <Box>
                        <Chip
                          label={"Language"}
                          icon={<LanguageOutlined sx={{ opacity: 0.5 }} />}
                          clickable
                          onClick={handleOpenLanguageMenu}
                        />
                        <Menu
                          id="language-menu"
                          anchorEl={languageElement}
                          open={openLanguageMenu}
                          onClose={() => setLanguageElement(null)}
                          slotProps={{
                            paper: {
                              elevation: 0,
                              sx: {
                                overflow: "visible",
                                filter:
                                  "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                mt: 1.5,
                                overflow: "hidden",
                                borderRadius: (theme) =>
                                  theme.settings.borderRadius,
                              },
                            },
                          }}
                          transformOrigin={{
                            horizontal: "left",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "left",
                            vertical: "bottom",
                          }}
                        >
                          <MenuItem onClick={() => setLanguageElement(null)}>
                            <Box
                              component={"span"}
                              sx={{
                                fontFamily: "NotoColorEmojiLimited",
                                mr: 1,
                              }}
                            >
                              🇩🇪
                            </Box>
                            German
                          </MenuItem>
                          <MenuItem onClick={() => setLanguageElement(null)}>
                            <Box
                              component={"span"}
                              sx={{
                                fontFamily: "NotoColorEmojiLimited",
                                mr: 1,
                              }}
                            >
                              🇬🇧
                            </Box>{" "}
                            English
                          </MenuItem>
                          <MenuItem onClick={() => setLanguageElement(null)}>
                            <Box
                              component={"span"}
                              sx={{
                                fontFamily: "NotoColorEmojiLimited",
                                mr: 1,
                              }}
                            >
                              🇮🇷
                            </Box>{" "}
                            Persian
                          </MenuItem>
                        </Menu>
                      </Box>

                      <Chip
                        label={"Help"}
                        icon={<HelpOutline sx={{ opacity: 0.5 }} />}
                        clickable
                      />
                      <Chip
                        label={
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            spacing={1}
                          >
                            {time === 1 ? (
                              <Typography fontSize={12}>CET</Typography>
                            ) : (
                              <Typography fontSize={12}>IRST</Typography>
                            )}
                            <Clock
                              format={"HH:mm:ss"}
                              ticking={true}
                              timezone={time === 1 ? "CET" : "IRST"}
                            />
                            <span>|</span>
                            <span>
                              {time === 1
                                ? moment()
                                    .utcOffset("+0100")
                                    .format("MM/DD/YYYY")
                                : jMoment().format("jYYYY/jMM/jDD")}
                            </span>
                          </Stack>
                        }
                        clickable
                        onClick={() => {
                          if (time === 1) {
                            setTime((prevTime) => prevTime + 1);
                          } else {
                            setTime(1);
                          }
                        }}
                      />
                    </Stack>
                  </Stack>
                </motion.div>
              </AnimatePresence>
            </Stack>
          </Stack>
          <Stack direction={"row"} height={"100%"}>
            <AnimatePresence>
              {!appTheme.settings.drawer.isOpen && (
                <motion.div
                  initial={{
                    width: 0,
                  }}
                  animate={{
                    width: 66,
                    transition: {
                      ease: "easeInOut",
                    },
                  }}
                  exit={{
                    width: 0,
                  }}
                >
                  <AppDrawer
                    isAccounting={isAccounting}
                    drawerWidth={320}
                    open={false}
                    onClick={handleDrawer}
                  />
                </motion.div>
              )}
            </AnimatePresence>
            <BackgroundPaper
              sx={{
                flex: 1,
                overflowY: "auto",
                pb: 15,
                width: 0,
                borderBottomLeftRadius: 0,
              }}
            >
              <Outlet />
            </BackgroundPaper>
          </Stack>
        </Stack>
      </Stack>
    </motion.div>
  );
}

const darkMode = {
  mode: "dark",
  primary: {
    main: "#212121",
  },
  secondary: {
    main: blue.A100,
  },
  tertiary: {
    main: blue.A100,
  },
  background: {
    main: "#262626",
  },
  foreground: {
    main: "#212121",
  },
};

const lightMode = {
  mode: "light",
  primary: {
    main: "#fff",
  },
  secondary: {
    main: "#0091ea",
  },
  tertiary: {
    main: "#0091ea",
  },
  background: {
    main: "#e1e1e1",
  },
  foreground: {
    main: "#F0F0F0",
  },
};
