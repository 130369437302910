import { Stack, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import ForegroundPaper from "../@common/ForegroundPaper";

export function AppPageHeader({ icon, title, actions }) {
  return (
    <ForegroundPaper
      sx={{
        mb: 0.1,
      }}
    >
      <PageHeader spacing={2}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"100%"}
          spacing={2}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            {icon}
            <Typography>{title}</Typography>
          </Stack>
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            {actions}
          </Stack>
        </Stack>
      </PageHeader>
    </ForegroundPaper>
  );
}

const PageHeader = styled(Stack)(({ theme }) => ({
  flexDirection: "column",
  position: "sticky",
  top: 0,
  zIndex: 40,

  padding: theme.spacing(2, 1),
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: theme.spacing(2),
  },
}));
