import { Add, Delete, LocalDrink } from "@mui/icons-material";
import PageContainer from "../../../components/@common/PageContainer";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import CustomTable from "../../../components/@common/CustomTable";
import useFetch from "../../../hooks/useFetch";
import moment from "moment";
import { useModal } from "../../../hooks/useModal";
import CustomButton from "../../../components/@common/CustomButton";
import CustomInput from "../../../components/@common/CustomInput";
import { useAppTheme } from "../../../hooks/useAppTheme";
import CustomColor from "../../../components/@common/CustomColor";
import { useState } from "react";
import Barcode from "react-barcode";
import { getContrastedColor } from "../../../utils/getContrastedColor";
import useCrud from "../../../hooks/useCrud";
import useForm from "../../../hooks/useForm";
import { mutate } from "swr";
import { blue } from "@mui/material/colors";

export default function Buckets() {
  const { setModal } = useModal();
  const { crud } = useCrud();
  const { appTheme } = useAppTheme();
  const { data, loading } = useFetch("/api/managers/warehouse/buckets");
  const [hoveredItem, setHoveredItem] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null > null);

  const handlePopoverOpen = (event, item) => {
    event.preventDefault();
    setHoveredItem(item);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setHoveredItem(null);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const serializedData = data?.data?.map((bucket) => {
    return {
      id: bucket?.id,
      "bucket id": bucket?.id,
      title: bucket?.title,
      "warehouse No": (
        <Barcode
          renderer={"img"}
          width={1}
          height={20}
          background={'white'}
          lineColor={getContrastedColor(appTheme.palette.foreground.main)}
          value={bucket.warehouse_number}
        />
      ),
      color: bucket.color,
      capacity: bucket.capacity,
      updated: moment(bucket.updated_at).format("YYYY/MM/DD"),
      "": (
        <>
          <IconButton
            onClick={() =>
              setModal({
                title: "confirm delete",
                child: (
                  <ConfirmDelete
                    id={bucket.id}
                    onClose={() => setModal(null)}
                  />
                ),
              })
            }
          >
            <Delete />
          </IconButton>
        </>
      ),
    };
  });

  return (
    <PageContainer
      title={"Buckets"}
      icon={<LocalDrink />}
      color={blue[500]}
      actions={
        <>
          <CustomButton
            label={"Add new"}
            endIcon={<Add color="inherit" />}
            onClick={(item) =>
              setModal({
                title: "Add new bucket",
                child: <NewBucket />,
              })
            }
          />
        </>
      }
    >
      <CustomTable
      
        options={{
          rows: serializedData || [],
          loading,
        }}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />

      {hoveredItem && (
        <Popover
          sx={{
            pointerEvents: "none", 
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <List sx={{ minWidth: 400, maxWidth: 560 }}>
            <SingleBucket bucket={hoveredItem} />
          </List>
        </Popover>
      )}
    </PageContainer>
  );
}

function SingleBucket({ bucket }) {
  return (
    <>
      <ListItem sx={{ p: 2 }} alignItems="flex-start">
        <ListItemAvatar>
          <Box
            sx={{
              position: "relative",
              bgcolor: bucket.color,
              display: "inline-block",
              width: 32,
              height: 32,
              borderRadius: "50%",
            }}
          ></Box>
        </ListItemAvatar>
        <ListItemText
          primary={bucket.title}
          secondary={
            <>
              <Typography
                component="span"
                variant="body2"
                sx={{
                  display: "inline",
                  color: (theme) =>
                    getContrastedColor(theme.palette.foreground.main),
                }}
              >
                {bucket.capacity}
              </Typography>
            </>
          }
        />
      </ListItem>

      <Stack
        sx={{ p: 2 }}
        direction={"row"}
        justifyContent={"space-between"}
        spacing={1}
      >
        <Typography>Enter date:</Typography>
        <Typography
          sx={{
            fontFamily: "Carlito",
          }}
        >
          {bucket.updated}
        </Typography>
      </Stack>
    </>
  );
}

function NewBucket() {
  const { setModal } = useModal();
  const { values, setValues, handleInput } = useForm({
    title: "",
    capacity: "",
    color: "#d68fb7",
  });

  const { crud } = useCrud();

  const create = () =>
    crud
      .post("/api/managers/warehouse/buckets/add", values)
      .then(() => setModal(null))
      .then(() => mutate("/api/managers/warehouse/buckets"));

  function standardize_color(str) {
    var ctx = document.createElement("canvas").getContext("2d");
    ctx.fillStyle = str;
    return ctx.fillStyle;
  }

  return (
    <>
      <Stack sx={{ p: 2, maxWidth: 400 }} spacing={2}>
        <CustomInput
          label={"Title"}
          value={values.title}
          onChange={handleInput("title")}
        />
        <CustomInput
          type={"number"}
          label={"Capacity"}
          value={values.capacity}
          onChange={handleInput("capacity")}
        />
        <CustomColor
          label={"Color"}
          newColor={standardize_color(values.color)}
          color={standardize_color(values.color)}
          onChange={(color) => {
            setValues((prev) => ({ ...prev, color: color }));
          }}
        />
        <CustomButton label={"Create"} onClick={create} />{" "}
      </Stack>
    </>
  );
}

function ConfirmDelete({ id, onClose }) {
  const { crud, loading } = useCrud();
  const deleteItem = () =>
    crud
      .delete("/api/managers/warehouse/buckets/remove?id=" + id)
      .then(() => onClose())
      .then(() => mutate("/api/managers/warehouse/buckets"));

  return (
    <Stack
      sx={{
        p: 2,
      }}
      spacing={1}
    >
      <Typography>Are you sure?</Typography>
      <Stack direction={"row"} spacing={2} justifyContent={"end"}>
        <CustomButton
          label="cancel"
          variant="text"
          color="inherit"
          onClick={onClose}
          disabled={loading}
        />
        <CustomButton
          loading={loading}
          label="confirm"
          variant="text"
          color="error"
          onClick={deleteItem}
        />
      </Stack>
    </Stack>
  );
}
