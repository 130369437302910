import { ChevronLeft, ChevronRight, Menu } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography, styled } from "@mui/material";
import AppDrawerItems from "./AppDrawerItems";
import { motion } from "framer-motion";
import { config } from "../../config";
import { openedMixin, closedMixin } from "../../utils/mixins";
import { getContrastedColor } from "../../utils/getContrastedColor";
import AccountingDrawerItems from "./AccountingDrawerItems";
import moment from "moment";

export default function AppDrawer({
  isAccounting,
  drawerWidth,
  open,
  onClick,
}) {
  return (
    <Drawer variant="permanent" open={open} drawerwidth={drawerWidth}>
      <Stack height={"100%"} justifyContent={"space-between"}>
        <Stack>
          <Box
            sx={{
              height: 63,
              display: open ? "flex" : "none",
              alignItems: "center",
            }}
          >
            {open && (
              <Box
                component={motion.div}
                initial={{ translateX: 80, opacity: 0 }}
                animate={{
                  translateX: 0,
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                    delay: 0.6,
                  },
                }}
                exit={{
                  translateX: -40,
                  opacity: 0,
                  transition: {
                    duration: 0.3,
                  },
                }}
              >
                <IconButton
                  onClick={onClick}
                  sx={{
                    mx: 3,
                    color: (theme) =>
                      getContrastedColor(theme.palette.primary.main),
                  }}
                >
                  <ChevronLeft />
                </IconButton>
              </Box>
            )}
          </Box>
          {isAccounting ? (
            <AccountingDrawerItems open={open} />
          ) : (
            <AppDrawerItems open={open} />
          )}
        </Stack>
        <Stack
          alignItems={"center"}
          justifySelf={"end"}
          p={open ? 2 : 0}
          sx={{
            opacity: 0.5,
            mb: !open && 10,
          }}
        >
          {open ? (
            <Typography sx={{ fontSize: 14 }} fontWeight={900}>
              Recycle Solution By GWM GmbH
            </Typography>
          ) : (
            <Typography sx={{ fontSize: 14 }} fontWeight={900}>
              GWM
            </Typography>
          )}
          <Typography sx={{ fontSize: 10 }} fontWeight={700}>
            V1.01
          </Typography>
          <Typography fontSize={10} fontWeight={700} mt={-0.5}>
            &copy;{moment().year()}
          </Typography>
        </Stack>
      </Stack>
    </Drawer>
  );
}

const Drawer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, drawerwidth }) => ({
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  height: "100%",
  ...(open && {
    ...openedMixin(drawerwidth, theme),
    "& .MuiPaper-root": {
      ...openedMixin(drawerwidth, theme),
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
  }),
}));
