import {Stack} from "@mui/material";
import {MuiFileInput} from "mui-file-input";
import {useState} from "react";
import {FaTimes} from "react-icons/fa";
import CustomButton from "../../../components/@common/CustomButton";
import api from "../../../config/api";

export default function ImportCSV() {
    const [file, setFile] = useState(null)

    const handleOnChange = (newFile) => {
        setFile(newFile)
    }

    const handleUpload = () => {
        api.post('/api/managers/customers/csvListImport', {
            'csv': file
        }, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <>
            <Stack sx={{
                padding: '10px'
            }}>
                Select File
                <MuiFileInput
                    value={file}
                    onChange={handleOnChange}
                    clearIconButtonProps={{
                        title: "Remove",
                        children: <FaTimes fontSize="small" />
                    }}
                />
                <CustomButton
                    sx={{
                        marginTop: '8px',
                    }}
                    label='Import'
                    onClick={handleUpload}
                />
            </Stack>
        </>
    )
}