import { IconButton, Stack, Typography } from "@mui/material";
import {
  ArrowDownwardOutlined,
  ArrowUpwardOutlined,
  Delete,
  FilterAlt,
  Map,
} from "@mui/icons-material";
import { useModal } from "../../../hooks/useModal";
import api from "../../../config/api";
import { useState, useEffect } from "react";
import FinalizeTable from "./FinalizeTable";
import CustomButton from "../../../components/@common/CustomButton";
import CustomMap from "../../../components/@common/CustomMap";
import SingleCustomerModal from "./SingleCustomerModal";
import Cookies from "js-cookie";
import { getRandomNumber } from "../../../utils/getRandomNumber";
import moment from "moment";
import CustomInput from "../../../components/@common/CustomInput";
import OpenMap from "../../../components/@common/Map/OpenMap";
import _ from "lodash";

const FinalVisitsTable = ({
  customer_ids,
  sortedCustomers,
  setSortedCustomers,
  setFilteredCustomers,
  onRemove,
  loading,
  setLoading,
}) => {
  const { setModal } = useModal();
  const [allCustomersList, setAllCustomersList] = useState([]);

  const reorderArray = (event, originalArray) => {
    let movedItem = originalArray.find(
      (item, index) => index === event.oldIndex
    );

    movedItem.sort = event.newIndex;

    const remainingItems = originalArray
      .filter((item, index) => index !== event.oldIndex)
      .map((item, index) => {
        return { ...item, sort: index - event.newIndex };
      });

    const reorderedItems = [
      ...remainingItems.slice(0, event.newIndex),
      movedItem,
      ...remainingItems.slice(event.newIndex),
    ];

    return reorderedItems;
  };

  const changeOrder = (index, direction) => {
    setSortedCustomers(
      reorderArray(
        { oldIndex: index, newIndex: index + (direction === "UP" ? -1 : 1) },
        sortedCustomers
      )
    );
  };

  const getSortedCustomers = async () => {
    setLoading(true);
    try {
      const response = await api.post(
        `/api/managers/customers/many`,
        { customer_ids },
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("gwmuser"),
          },
        }
      );

      setSortedCustomers(
        _.sortBy(response?.data?.data, "id").map((item) => {
          return { ...item, isSelected: true };
        })
      );

      setLoading(false);
      return response.data;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getAllCustomers = async () => {
    setLoading(true);
    try {
      const response = await api.get("/api/managers/customers");
      setAllCustomersList(response?.data?.data);

      setLoading(false);

      return response.data;
    } catch (error) {
      setLoading(false);
    }
  };

  const serializedCustomer = sortedCustomers
    ?.map((customer, i) => {
      return {
        id: customer?.id,
        ON: i + 1,
        "": (
          <Stack direction={"row"}>
            <IconButton size="small" onClick={() => changeOrder(i, "UP")}>
              <ArrowUpwardOutlined
                sx={{
                  fontSize: 20,
                }}
              />
            </IconButton>
            <IconButton size="small" onClick={() => changeOrder(i, "DOWN")}>
              <ArrowDownwardOutlined
                sx={{
                  fontSize: 20,
                }}
              />
            </IconButton>
          </Stack>
        ),
        CN: customer?.customer_number,
        CoName: customer?.company_name,
        WT: customer?.working_time,
        city: customer?.city,
        ZCode: customer?.zipcode,
        "c-rank": getRandomNumber(1, 10, 2),
        "last visit":
          customer?.customer_visits_history &&
          moment(customer?.customer_visits_history[0]?.visit_date).format(
            "YYYY/MM/DD"
          ),
        " ": (
          <IconButton
            onClick={() => {
              setModal({
                title: "Confirm Delete",
                child: (
                  <Stack
                    sx={{
                      p: 2,
                    }}
                    spacing={1}
                  >
                    <Typography>Are you sure?</Typography>
                    <Stack direction={"row"} spacing={2} justifyContent={"end"}>
                      <CustomButton
                        label="cancel"
                        variant="text"
                        color="inherit"
                        onClick={() => setModal(null)}
                      />
                      <CustomButton
                        label="confirm"
                        variant="text"
                        color="error"
                        onClick={() => onRemove(customer)}
                      />
                    </Stack>
                  </Stack>
                ),
              });
            }}
          >
            <Delete />
          </IconButton>
        ),
        isSelected: true,
      };
    })
    .map((customer) => {
      return { ...customer, isSelected: true };
    });

  useEffect(() => {
    setFilteredCustomers(serializedCustomer);
  }, [sortedCustomers]);

  useEffect(() => {
    getSortedCustomers();
    getAllCustomers();
    return () => {
      setSortedCustomers([]);
    };
  }, [customer_ids]);

  const validCustomers = allCustomersList.filter((customer) => {
    const lat = parseFloat(customer?.latitude);
    const lng = parseFloat(customer?.longitude);
    return !isNaN(lat) && !isNaN(lng);
  });

  const sortedMarker = sortedCustomers?.map((customer) => {
    const lat = parseFloat(customer?.latitude);
    const lng = parseFloat(customer?.longitude);
    return {
      lat: isNaN(lat) ? 0 : lat,
      lng: isNaN(lng) ? 0 : lng,
      name: customer?.company_name || "No Name",
      isSelected: customer?.isSelected,
    };
  });

  const customMarkers = validCustomers.map((customer) => {
    return {
      lat: parseFloat(customer?.latitude),
      lng: parseFloat(customer?.longitude),
      name: customer?.company_name || "No Name",
    };
  });

  return (
    <>
      <CustomInput
        label={"Filter customers"}
        endAdornment={<FilterAlt />}
        onChange={(e) => {
          const term = e.target.value.toLowerCase();
          setFilteredCustomers(() => {
            return serializedCustomer.filter((c) => {
              if (
                c?.name?.toLowerCase()?.startsWith(term) ||
                c?.CN?.startsWith(term) ||
                c?.CoName?.toLowerCase()?.startsWith(term)
              ) {
                return c;
              }
            });
          });
        }}
      />
      <FinalizeTable
        title={`Finalize (${serializedCustomer?.length})`}
        agentsLength={serializedCustomer?.length}
        outlined
        hideselect
        options={{
          rows: serializedCustomer?.map((item) => {
            delete item.isSelected;
            return { ...item };
          }),
          loading,
        }}
        onClick={(v) => {
          setModal({
            title: `${v.name}`,
            child: (
              <SingleCustomerModal
                customer={sortedCustomers.find((c) => c.id === v.id)}
              />
            ),
          });
        }}
        action={
          <>
            <CustomButton
              label={"show on map"}
              variant={"text"}
              endIcon={<Map />}
              onClick={() =>
                setModal({
                  title: "Map",
                  child: (
                    <Stack
                      sx={{
                        position: "relative",
                        width: "50vw",
                        height: "50vh",
                      }}
                    >
                      <OpenMap
                        sortedMarker={sortedMarker}
                        customMarkers={customMarkers}
                      />
                    </Stack>
                  ),
                })
              }
            />
          </>
        }
      />
    </>
  );
};

export default FinalVisitsTable;
