import { Add, Delete, Edit, Group, Warehouse } from "@mui/icons-material";
import PageContainer from "../../../components/@common/PageContainer";
import {
  Avatar,
  Box,
  Card,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Stack,
  Typography,
  capitalize,
} from "@mui/material";
import CustomTable from "../../../components/@common/CustomTable";
import useFetch from "../../../hooks/useFetch";
import moment from "moment";
import { useModal } from "../../../hooks/useModal";
import CustomButton from "../../../components/@common/CustomButton";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../../components/@common/CustomInput";
import CustomColor from "../../../components/@common/CustomColor";
import { useEffect, useState } from "react";
import { getContrastedColor } from "../../../utils/getContrastedColor";
import useForm from "../../../hooks/useForm";
import useCrud from "../../../hooks/useCrud";
import CustomSelect from "../../../components/@common/CustomSelect";
import CustomDatePicker from "../../../components/@common/CustomDatePicker";
import { mutate } from "swr";
import { blue } from "@mui/material/colors";

export default function WarehouseManagement() {
  const { setModal } = useModal();
  const [hoveredItem, setHoveredItem] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null > null);
  const { crud } = useCrud();

  const handlePopoverOpen = (event, item) => {
    setHoveredItem(item);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setHoveredItem(null);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const { data, loading } = useFetch("/api/managers/warehouse/products/wh");

  const serializedData = data?.data?.map((product) => {
    return {
      id: product.product.id,
      name: product.product.name,
      amount: product.amount,
      sold: product.sold_amount,
      "sold at": product.sold_date,
      status: product.sold_status,
      "buying price": product.unit_buying_price,
      "selling price": product.unit_selling_price,
      "": (
        <>
          <IconButton
            onClick={() =>
              setModal({
                title: "confirm delete",
                child: (
                  <ConfirmDelete
                    id={product.product.id}
                    onClose={() => setModal(null)}
                  />
                ),
              })
            }
          >
            <Delete />
          </IconButton>
        </>
      ),
    };
  });

  return (
    <PageContainer
      title={"Warehouse Management"}
      icon={<Warehouse />}
      color={blue[500]}
      actions={
        <>
          <CustomButton
            label={"Add new"}
            endIcon={<Add color="inherit" />}
            onClick={() =>
              setModal({
                title: "Add new product",
                child: <WarehouseFreshProductForm />,
              })
            }
          />
        </>
      }
    >
      <CustomTable
        options={{
          rows: serializedData || [],
          loading,
        }}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      {hoveredItem && (
        <Popover
          sx={{
            pointerEvents: "none",
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <List sx={{ width: "100%", maxWidth: 360 }}>
            <SingleProduct product={hoveredItem} />
          </List>
        </Popover>
      )}
    </PageContainer>
  );
}

function SingleProduct({ product }) {
  return (
    <>
      <ListItem sx={{ p: 2 }} alignItems="flex-start">
        <ListItemAvatar>
          <Box
            sx={{
              position: "relative",
              bgcolor: product.color,
              display: "inline-block",
              width: 32,
              height: 32,
              borderRadius: "50%",
            }}
          ></Box>
        </ListItemAvatar>
        <ListItemText
          primary={capitalize(product.name)}
          secondary={
            <>
              <Typography
                component="span"
                variant="body2"
                sx={{
                  display: "inline",
                  color: (theme) =>
                    getContrastedColor(theme.palette.foreground.main),
                }}
              >
                Amount: {product.amount}
              </Typography>
            </>
          }
        />
      </ListItem>

      <Stack sx={{ p: 2 }} spacing={2}>
        <Stack direction={"row"} justifyContent={"space-between"} spacing={1}>
          <Typography>Enter date:</Typography>
          <Typography
            sx={{
              fontFamily: "Carlito",
            }}
          >
            {moment(product.enter_date).format("YYYY/MM/DD HH:mm")}
          </Typography>
        </Stack>
        <Divider
          sx={{
            my: 0,
          }}
        />

        <Stack direction={"row"} justifyContent={"space-between"} spacing={1}>
          <Typography>Manufacture date:</Typography>
          <Typography
            sx={{
              fontFamily: "Carlito",
            }}
          >
            {moment(product.manufacture_date).format("YYYY/MM/DD HH:mm")}
          </Typography>
        </Stack>
        <Divider />

        <Stack direction={"row"} justifyContent={"space-between"} spacing={1}>
          <Typography>Expire date:</Typography>
          <Typography
            sx={{
              fontFamily: "Carlito",
            }}
          >
            {moment(product.expired_date).format("YYYY/MM/DD HH:mm")}
          </Typography>
        </Stack>
      </Stack>
    </>
  );
}

function WarehouseFreshProductForm({ product }) {
  const { data: products, loading: loadingProducts } = useFetch(
    "/api/managers/warehouse/products"
  );

  const { values, setValues, handleInput } = useForm({
    product_id: {
      id: "",
      title: "",
    },
    amount: "0",
    enter_date: "",
    manufacture_date: "",
    expired_date: "",
    unit_buying_price: "",
  });
  const { crud, loading } = useCrud();

  const create = () =>
    crud
      .post("/api/managers/warehouse/products/wh/add", {
        ...values,
        product_id: values.product_id.id,
      })
      .then(() => mutate("/api/managers/warehouse/products/wh"));

  const update = () =>
    crud.post("/api/managers/warehouse/products/wh/add", values);

  useEffect(() => {
    if (product) setValues(product);
  }, [product]);

  return (
    <>
      <Stack sx={{ p: 2, maxWidth: 400 }} spacing={2}>
        {products && (
          <CustomSelect
            loading={loadingProducts}
            label={"Product"}
            value={values.product_id.title}
            options={products.data.map((product) => ({
              id: product.id,
              title: product.name,
            }))}
            onChange={(e, product) =>
              setValues({ ...values, product_id: product })
            }
          />
        )}

        <CustomInput
          type={"number"}
          value={values.amount}
          label={"Amount"}
          onChange={handleInput("amount")}
        />
        <CustomDatePicker
          label={"Enter date"}
          onChange={(newDate) => setValues({ ...values, enter_date: newDate })}
        />
        <CustomDatePicker
          label={"Manufacture date"}
          onChange={(newDate) =>
            setValues({ ...values, manufacture_date: newDate })
          }
        />
        <CustomDatePicker
          label={"Expire date"}
          onChange={(newDate) =>
            setValues({ ...values, expired_date: newDate })
          }
        />

        <CustomInput
          value={values.unit_buying_price}
          label={"Unit buying price"}
          onChange={handleInput("unit_buying_price")}
        />

        <CustomButton
          loading={loading}
          label={product ? "update" : "Create"}
          onClick={product ? update : create}
        />
      </Stack>
    </>
  );
}

function ConfirmDelete({ id, onClose }) {
  const { crud, loading } = useCrud();

  const deleteItem = () =>
    crud
      .delete("/api/managers/warehouse/products/wh/remove?id=" + id)
      .then(() => onClose())
      .then(() => mutate("/api/managers/warehouse/products/wh"));

  return (
    <Stack
      sx={{
        p: 2,
      }}
      spacing={1}
    >
      <Typography>Are you sure?</Typography>
      <Stack direction={"row"} spacing={2} justifyContent={"end"}>
        <CustomButton
          label="cancel"
          variant="text"
          color="inherit"
          onClick={onClose}
          disabled={loading}
        />
        <CustomButton
          loading={loading}
          label="confirm"
          variant="text"
          color="error"
          onClick={deleteItem}
        />
      </Stack>
    </Stack>
  );
}
