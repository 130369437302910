import {
  Autocomplete,
  Box,
  ButtonBase,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { useEffect, useState } from "react";

import {
  Add,
  AddLocationAlt,
  Casino,
  Close,
  Edit,
  PhotoCamera,
  Save,
} from "@mui/icons-material";
import ForegroundPaper from "../../../components/@common/ForegroundPaper";
import { getContrastedColor } from "../../../utils/getContrastedColor";
import CustomInput from "../../../components/@common/CustomInput";
import CustomButton from "../../../components/@common/CustomButton";
import { AppPageHeader } from "../../../components/@app/AppHeader";
import PageContainer from "../../../components/@common/PageContainer";
import useForm from "../../../hooks/useForm";
import CustomSelect from "../../../components/@common/CustomSelect";
import { generatePassword } from "../../../utils/generatePassword";
import { useNotification } from "../../../hooks/useNotification";
import api from "../../../config/api";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { useModal } from "../../../hooks/useModal";
import CustomMap from "../../../components/@common/CustomMap";
import { orange } from "@mui/material/colors";

export default function EditAgent() {
  const { type, id } = useParams();
  const [loading, setLoading] = useState(true);
  const { setNotification } = useNotification();
  const [personImage, setPersonImage] = useState(null);
  const [createImageURL, setCreateImageURL] = useState(null);
  const navigate = useNavigate()

  const { setValues, values, handleInput, handleSelect } = useForm({
    first_name: "",
    last_name: "",
    password: "",
    email: "",
    mobile: "",
    address: "",
    agent: type,
    start_place: "",
    note: "",
  });

  const getAgent = async () => {
    try {
      const response = await api.post(
        "/api/managers/agents/agent",
        {
          agent_type: type,
          agent_id: id,
        },
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("gwmuser"),
          },
        }
      );
      setLoading(false);
      if (response.data.data) {
        return response.data.data;
      }
      return response.data;
    } catch (error) {
      setLoading(false);
    }
  };

  const save = async () => {
    setLoading(true);

    try {
      let formData = new FormData();
      formData.append("profile_picture", personImage);
      console.log({
        profile_picture: personImage,
        ...values,
      });
      const response = await api.post(
        `/api/drivers/admin/edit`,
        {
          ...formData,
          ...values,
          start_place: `${values.latitude},${values.longitude}`,
        },
        {
          headers: {
            "Content-type": "multipart/form-data",
          },
        }
      );

      setNotification({
        message: "Saved",
      });
      setLoading(false);
      return response.data;
    } catch (error) {
      console.log(error);
      if (error.response?.data.message) {
        setNotification({
          variant: "error",
          message: error.response.data.message,
        });
      }
      setLoading(false);
      return;
    }
  };

  const handleImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      setPersonImage(null);
      setCreateImageURL(null);
      const i = event?.target?.files[0];
      setPersonImage(i);
      setCreateImageURL(URL.createObjectURL(i));
    }
  };

  const handleRemoveImage = () => {
    setPersonImage(null);
    setCreateImageURL(null);
  };

  useEffect(() => {
    getAgent().then((agent) => {
      delete agent.profile_picture;
      return setValues({
        ...values,
        ...agent,
        latitude: Number(agent?.start_place?.split(",")[0]),
        longitude: Number(agent?.start_place?.split(",")[1]),
      });
    });
  }, []);

  return (
    <PageContainer
      loading={loading}
      title={"Edit agent"}
      icon={<Edit />}
      color={orange[500]}
      actions={
        <>
          <CustomButton label={"Visit history"} variant={"text"} />
          <CustomButton
            label={"Save"}
            endIcon={<Save />}
            onClick={() => {
              save() 
              navigate(-1)
            }}
          />
        </>
      }
    >
      <EditAgentForm
        values={values}
        setValues={setValues}
        handleInput={handleInput}
        handleSelect={handleSelect}
        handleImage={handleImage}
        handleRemoveImage={handleRemoveImage}
        createImageURL={createImageURL}
      />
    </PageContainer>
  );
}

function EditAgentForm({
  values,
  setValues,
  handleInput,
  createImageURL,
  handleImage,
  handleRemoveImage,
}) {
  const { setModal } = useModal();

  return (
    <ForegroundPaper>
      <UploadImageButton
        // image={values.profile_picture}
        handleImage={handleImage}
        handleRemoveImage={handleRemoveImage}
        createImageURL={createImageURL}
      />
      <Stack
        direction={"row"}
        alignItems={"flex-start"}
        spacing={2}
        sx={{ width: "100%", py: 2 }}
      >
        <Stack
          direction={"column"}
          alignItems={"stretch"}
          justifyContent={"flex-start"}
          spacing={2}
          sx={{
            flex: 1,
          }}
        >
          <Stack
            sx={{ width: "100%" }}
            direction={"row"}
            justifyContent={"space-between"}
            spacing={2}
          >
            <Stack
              sx={{ width: "100%" }}
              direction={"column"}
              justifyContent={"flex-start"}
              spacing={2}
            >
              <Stack sx={{ width: "100%" }} direction={"row"} spacing={2}>
                <CustomInput
                  fullWidth
                  label={"First name"}
                  value={values?.first_name}
                  type="text"
                  onChange={handleInput("first_name")}
                />
                <CustomInput
                  fullWidth
                  value={values?.last_name}
                  label={"Last name"}
                  type="text"
                  onChange={handleInput("last_name")}
                />
              </Stack>
            </Stack>
          </Stack>

          <Stack sx={{ width: "100%" }} direction={"row"} spacing={2}>
            <CustomInput
              fullWidth
              label={"Password"}
              type="text"
              value={values?.password}
              onChange={handleInput("password")}
              endAdornment={
                <IconButton
                  onClick={() =>
                    setValues({ ...values, password: generatePassword() })
                  }
                >
                  <Casino />
                </IconButton>
              }
            />
            <CustomInput
              fullWidth
              value={values?.email}
              label={"Email"}
              type="email"
              onChange={handleInput("email")}
            />
            <CustomInput
              fullWidth
              label={"Mobile"}
              value={values?.mobile}
              type="email"
              onChange={handleInput("mobile")}
            />
          </Stack>

          <Stack sx={{ width: "100%" }} direction={"row"} spacing={2}>
            <CustomInput
              label={"Address"}
              value={values?.address}
              type="text"
              onChange={handleInput("address")}
            />
            <CustomButton
              color="info"
              variant={
                values.latitude && values.longitude ? "contained" : "outlined"
              }
              sx={{
                height: 56,
              }}
              onClick={() =>
                setModal({
                  title: "Pin the address",
                  child: (
                    <Stack
                      sx={{
                        position: "relative",
                        width: "50vw",
                        height: "50vh",
                      }}
                    >
                      <CustomMap
                        lat={values.latitude}
                        lng={values.longitude}
                        onChange={(position) => {
                          setValues({
                            ...values,
                            latitude: position.lat,
                            longitude: position.lng,
                          });
                        }}
                      />
                    </Stack>
                  ),
                })
              }
              label={<AddLocationAlt />}
            />
          </Stack>

          <CustomInput
            value={values?.note}
            label={"Additional note"}
            onChange={handleInput("note")}
            type="text"
            multiline
            rows={2}
          />
        </Stack>
      </Stack>
    </ForegroundPaper>
  );
}

function UploadImageButton({
  image,
  handleImage,
  handleRemoveImage,
  createImageURL,
}) {
  return (
    <Box
      sx={{
        width: 120,
        height: 120,
        position: "relative",
        display: "flex",
        borderRadius: (theme) => theme.settings.button.borderRadius,
        overflow: "hidden",
      }}
    >
      {createImageURL && (
        <Box sx={{ position: "absolute", top: -1, right: -2, zIndex: 10 }}>
          <IconButton size="small" onClick={handleRemoveImage}>
            <Close />
          </IconButton>
        </Box>
      )}
      <ImageButton
        component="label"
        focusRipple
        style={{
          width: 120,
          height: 120,
          borderRadius: 4,
          overflow: "hidden",
        }}
        opacity={createImageURL ? 0 : 0.15}
      >
        <UploadInput
          type="file"
          accept=".png,.jpeg,.jpg"
          onChange={handleImage}
          onClick={(e) => {
            e.target.value = null;
            return;
          }}
          hidden
        />

        <ImageSrc
          style={{
            backgroundImage: `url(${
              image ? image : createImageURL ? createImageURL : ""
            })`,
          }}
        />
        <ImageBackdrop className="MuiImageBackdrop-root" />
        <Image>
          {!createImageURL && (
            <Typography
              component="span"
              variant="subtitle1"
              color="inherit"
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                color: "#999",
                p: 4,
                pt: 2,
                color: (theme) =>
                  getContrastedColor(theme.palette.foreground.main),
                pb: (theme) => `calc(${theme.spacing(1)})`,
              }}
            >
              <PhotoCamera
                fontSize="small"
                sx={{
                  color: (theme) =>
                    getContrastedColor(theme.palette.foreground.main),
                }}
              />
              Photo
            </Typography>
          )}
        </Image>
      </ImageButton>
    </Box>
  );
}

const UploadInput = styled("input")({
  display: "none",
});

const ImageButton = styled(ButtonBase)(({ theme, opacity }) => ({
  position: "relative",
  height: 200,
  [theme.breakpoints.down("sm")]: {
    width: "100% !important", // Overrides inline-style
    height: 100,
  },
  "& .MuiImageBackdrop-root": {
    opacity,
  },
  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    "& .MuiImageBackdrop-root": {
      opacity: 0.15,
    },
    "& .MuiImageMarked-root": {
      opacity: 0,
    },
  },
}));

const ImageSrc = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundPosition: "center 40%",
});

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.1,
  transition: theme.transitions.create("opacity"),
}));
