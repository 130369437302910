import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { getContrastedColor } from "../../utils/getContrastedColor";
import { Divider, Stack } from "@mui/material";

export default function CardItem({ title, actions, children, sx }) {
  return (
    <Box sx={{ ...sx }}>
      <Card
        variant="outlined"
        sx={{
          width: "100%",
          borderRadius: (theme) => theme.settings.foreground.borderRadius + 1,
          bgcolor: (theme) => theme.palette.primary.main,
          boxShadow: (theme) => `0 0 10px ${theme.palette.background.main}`,
          color: (theme) => getContrastedColor(theme.palette.background.main),
        }}
      >
        <CardContent>
          <Stack direction={"row"} justifyContent={"space-between"} pb={1}>
            <Typography variant="h6" fontWeight={700}>
              {title}
            </Typography>
            {actions}
          </Stack>
          <Divider />
          {children}
        </CardContent>
      </Card>
    </Box>
  );
}
