import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Input,
  Stack,
  Typography,
} from "@mui/material";

import { useState } from "react";
import {
  ArrowDownwardOutlined,
  ArrowUpward,
  ArrowUpwardOutlined,
  CalendarMonth,
  Map,
} from "@mui/icons-material";
import CustomButton from "../../../components/@common/CustomButton";
import CustomSelect from "../../../components/@common/CustomSelect";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import PageContainer from "../../../components/@common/PageContainer";
import Label from "../../../components/@common/Label";
import CustomTable from "../../../components/@common/CustomTable";
import { useEffect } from "react";
import useFetch from "../../../hooks/useFetch";
import moment from "moment";
import useForm from "../../../hooks/useForm";
import api from "../../../config/api";
import Cookies from "js-cookie";
import { mutate } from "swr";
import _ from "lodash";
import CustomTableDays from "../../../components/@common/CustomTableDays";
import { useModal } from "../../../hooks/useModal";
import CustomMap from "../../../components/@common/CustomMap";
import LiveMap from "../../../components/@common/Map/liveMap";

const LiveView = () => {
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [agentLocation, setAgentLocation] = useState(null);
  const [loading, setLoading] = useState(false);

  const { data: agents } = useFetch("/api/managers/agents");

  const getDriverLocation = async (id) => {
    setLoading(true);
    try {
      const response = await api.get(
        `/api/managers/livelocation?driver_id=${id}`,
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("gwmuser"),
          },
        }
      );

      setAgentLocation(response.data.data);
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const getDriverLocationBackground = async (id) => {
    try {
      const response = await api.get(
        `/api/managers/livelocation?driver_id=${id}`,
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("gwmuser"),
          },
        }
      );

      setAgentLocation(response.data.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const serializedDrivers = agents?.drivers?.map((item) => {
    return {
      id: item.id,
      title: item.first_name + " " + item.last_name,
    };
  });

  useEffect(() => {
    let interval;
    if (agentLocation) {
      interval = setInterval(() => {
        getDriverLocationBackground(selectedAgent?.id);
      }, 10000);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [agentLocation]);

  return (
    <PageContainer title={"Live view"} icon={<Map />}>
      <Grid container xs={12} sx={{ width: "100%" }}>
        <Grid item mb={4}>
          <Stack direction={"row"} spacing={2} alignItems={"end"}>
            <CustomSelect
              label={"Pick agent"}
              value={selectedAgent?.title}
              options={serializedDrivers}
              onChange={(e, agent) => {
                setSelectedAgent(agent);
                getDriverLocation(agent.id);
              }}
            />
            {loading && (
              <Box>
                <CircularProgress alignSelf="end" color="secondary" />
              </Box>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} mb={4} height={"100%"}>
          {!loading && agentLocation && (
            <LiveMap
              lat={parseFloat(agentLocation?.latitude)}
              lng={parseFloat(agentLocation?.longitude)}
            />
          )}
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default LiveView;
