import PropTypes from "prop-types";
import { useState } from "react";
import { visuallyHidden } from "@mui/utils";
import styled from "@emotion/styled";
import {
  Box,
  Checkbox,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  capitalize,
  darken,
  lighten,
} from "@mui/material";
import { getContrastedColor } from "../../../utils/getContrastedColor";
import ForegroundPaper from "../../../components/@common/ForegroundPaper";
import { FaBoxOpen, FaUsersSlash } from "react-icons/fa6";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "asc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function FinalizeTable({
  outlined,
  title,
  action,
  onClick,
  disablePadding,
  options,
  onSelectAll,
  onSelect,
  hideselect,
  onMouseEnter = null,
  onMouseLeave,
  agentsLength = 5,
}) {
  const { rows = [], loading, showId } = options;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("createdAt");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(agentsLength);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((row) => Object.values(row)[0]);
      if (onSelectAll) {
        onSelectAll(newSelected);
      }
      setSelected(newSelected);
      return;
    }

    if (onSelectAll) {
      onSelectAll(null);
    }
    setSelected([]);
  };

  const handleClick = (event, _id) => {
    const selectedIndex = selected.indexOf(_id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, _id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    if (onSelect) {
      onSelect(newSelected);
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (_id) => selected.indexOf(_id) !== -1;

  let columns =
    rows.length > 0 &&
    Object.keys(rows[0]).map((head) => {
      return {
        id: head,
        numeric: false,
        disablePadding: disablePadding,
        label: head,
      };
    });

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * agentsLength - agentsLength) : 0;

  return (
    <>
      {loading && (
        <ForegroundPaper
          sx={{
            width: "100%",
            border: outlined ? 1 : 0,
            borderColor: (theme) =>
              theme.palette.mode === "light"
                ? darken(theme.palette.foreground.main, 0.25)
                : lighten(theme.palette.foreground.main, 0.25),
          }}
        >
          <Stack
            direction={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            width={"100%"}
            height={120}
          >
            <CircularProgress color="inherit" />
          </Stack>
        </ForegroundPaper>
      )}
      {!loading && rows?.length === 0 && (
        <ForegroundPaper
          sx={{
            width: "100%",
            border: outlined ? 1 : 0,
            borderColor: (theme) =>
              theme.palette.mode === "light"
                ? darken(theme.palette.foreground.main, 0.25)
                : lighten(theme.palette.foreground.main, 0.25),
          }}
        >
          <Stack
            direction={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            width={"100%"}
            height={120}
            sx={{ opacity: 0.5 }}
            spacing={1}
          >
            <FaUsersSlash size={18} />
            <Typography>No customer is selected yet.</Typography>
          </Stack>
        </ForegroundPaper>
      )}
      {rows?.length >= 1 && !loading && (
        <ForegroundPaper
          sx={{
            width: "100%",
            border: outlined ? 1 : 0,
            borderColor: (theme) =>
              theme.palette.mode === "light"
                ? darken(theme.palette.foreground.main, 0.25)
                : lighten(theme.palette.foreground.main, 0.25),
            maxHeight: 650,
            overflowY: "auto",
          }}
        >
          <FinalizeTableToolbar
            title={title}
            numSelected={selected.length}
            action={action}
          />
          <TableContainer>
            <Table aria-labelledby="tableTitle">
              <FinalizeTableHead
                numSelected={selected.length}
                order={order}
                columns={columns}
                showId={showId}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onSelectAll={onSelectAll}
                onRequestSort={handleRequestSort}
                rowCount={agentsLength}
                hideselect={hideselect}
              />
              <TableBody>
                {!loading &&
                  rows
                    ?.slice()
                    .sort(getComparator(order, orderBy))
                    .slice(
                      page * agentsLength,
                      page * agentsLength + agentsLength
                    )
                    .map((row, index) => {
                      const isItemSelected = isSelected(Object.values(row)[0]);

                      return (
                        <TableRow
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={Object.values(row)[0]}
                          hover={onMouseEnter ? true : onClick}
                          onMouseEnter={(e) =>
                            onMouseEnter ? onMouseEnter(e, row) : false
                          }
                          onMouseLeave={onMouseLeave}
                          onClick={(e) => {
                            if (
                              e.target.nodeName !== "BUTTON" &&
                              e.target.nodeName !== "svg" &&
                              e.target.nodeName !== "path"
                            ) {
                              onClick(row);
                            }
                          }}
                          selected={isItemSelected}
                          sx={{
                            cursor: onClick ? "pointer" : "",
                          }}
                        >
                          {!hideselect && (
                            <StyledTableCell
                              role="checkbox"
                              padding={"checkbox"}
                              onClick={(event) =>
                                handleClick(event, Object.values(row)[0])
                              }
                              sx={{
                                cursor: "pointer",
                              }}
                            >
                              <Checkbox checked={isItemSelected} />
                            </StyledTableCell>
                          )}

                          {Object.values(row).map((cell, i) => {
                            if (
                              !showId &&
                              Object.keys(row)[i] === Object.keys(row)[0]
                            ) {
                              return "";
                            } else {
                              return (
                                <StyledTableCell
                                  padding={disablePadding ? "none" : "normal"}
                                >
                                  {cell}
                                </StyledTableCell>
                              );
                            }
                          })}
                        </TableRow>
                      );
                    })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <StyledTableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            sx={{
              position: "sticky",
              bottom: 0,
              display: "none",
              bgcolor: (theme) => theme.palette.foreground.main,
              color: (theme) =>
                getContrastedColor(theme.palette.foreground.main),
              ".MuiButtonBase-root": {
                transform: "scaleX(-1)",
              },
              ".Mui-disabled .MuiSvgIcon-root": {
                color: (theme) =>
                  darken(
                    getContrastedColor(theme.palette.foreground.main),
                    0.2
                  ),
              },

              ".MuiSvgIcon-root": {
                color: (theme) =>
                  getContrastedColor(theme.palette.foreground.main),
              },
              ".MuiMenu-paper": {
                backgroundColor: (theme) =>
                  getContrastedColor(theme.palette.foreground.main),
              },
            }}
            rowsPerPageOptions={[100, agentsLength]}
            component="div"
            count={agentsLength}
            rowsPerPage={agentsLength}
            page={0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </ForegroundPaper>
      )}
    </>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  p: 0,
  zIndex: 0,
  color: getContrastedColor(theme.palette.foreground.main),
  ".MuiSvgIcon-root": {
    color: getContrastedColor(theme.palette.foreground.main),
  },

  ".Mui-active": {
    color: getContrastedColor(theme.palette.foreground.main),
  },
  ".Mui-active .MuiSvgIcon-root": {
    color: getContrastedColor(theme.palette.foreground.main),
  },
}));

function FinalizeTableHead(props) {
  const { onRequestSort, columns, showId } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell) => {
          if (!showId && headCell.id === Object.keys(headCell)[0]) {
            return "";
          }
          return (
            <StyledTableCell
              key={headCell.id}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {capitalize(headCell.id)}
            </StyledTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

FinalizeTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function FinalizeTableToolbar(props) {
  const { numSelected, title, action } = props;

  return (
    <Toolbar
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 1,
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        bgcolor: (theme) => theme.palette.foreground.main,
      }}
    >
      <Stack
        direction={"row"}
        width={"100%"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="subtitle1"
            component="div"
          >
            {numSelected}
            {capitalize(numSelected > 1 ? " items selected" : " item selected")}
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {capitalize(title)}
          </Typography>
        )}
        {action && (
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            {action}
          </Stack>
        )}
      </Stack>
    </Toolbar>
  );
}

FinalizeTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
