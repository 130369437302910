import { CacheProvider, useTheme } from "@emotion/react";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { createContext, useContext, useEffect, useState } from "react";
import { faIR as muiFa } from "@mui/material/locale";
import { faIR as pickerFa } from "@mui/x-data-grid";

import createCache from "@emotion/cache";
import { green } from "@mui/material/colors";

const rtlCache = createCache({
  key: "gwm-theme",
  prepend: true,
});

const AppThemeContext = createContext({});
export const ProvideAppTheme = ({ children }) => {
  const [appTheme, setAppTheme] = useState(theme);
  const [loadingTheme, setLoadingTheme] = useState(true);

  const changePalette = (palette) => {
    setAppTheme((prevState) => ({
      ...prevState,
      palette: {
        ...prevState.palette,
        ...palette,
      },
    }));
  };

  const changeComponent = (newComponent) => {
    setAppTheme((prevState) => ({
      ...prevState,
      components: {
        ...prevState.components,
        ...newComponent,
      },
    }));
  };

  const changeThemeSettings = (newSetting) => {
    setAppTheme((prevState) => ({
      ...prevState,
      settings: {
        ...prevState.settings,
        ...newSetting,
      },
    }));
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const userTheme = localStorage.getItem("gwm-theme");
      if (userTheme) {
        setAppTheme({ ...JSON.parse(userTheme) });
      }
      setLoadingTheme(false);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      localStorage.setItem("gwm-theme", JSON.stringify(appTheme));
    }
  }, [appTheme]);

  return (
    <AppThemeContext.Provider
      value={{
        appTheme,
        setAppTheme,
        changePalette,
        changeThemeSettings,
        changeComponent,
      }}
    >
      <CacheProvider value={rtlCache}>
        {!loadingTheme && (
          <ThemeProvider theme={createTheme(appTheme)}>
            <CssBaseline />
            {children}
          </ThemeProvider>
        )}
      </CacheProvider>
    </AppThemeContext.Provider>
  );
};

export const useAppTheme = () => useContext(AppThemeContext);

let theme = {
  direction: "rtl",
  palette: {
    mode: "dark",
    primary: {
      main: "#212121",
    },
    secondary: {
      main: green.A400,
    },
    tertiary: {
      main: green.A400,
    },
    background: {
      main: "#262626",
    },
    foreground: {
      main: "#212121",
    },
    error: {
      main: "#F44336",
    },
    dark: {
      main: "#212121",
    },
    light: {
      main: "#F9F9F9",
    },
  },
  settings: {
    borderRadius: 4,
    chat: {
      myBackground: "#16A5A5",
      userBackground: "#009CE0",
    },
    drawer: {
      isOpen: true,
    },
    button: {
      borderRadius: 0,
      bgcolor: "",
    },
    background: {
      borderRadius: 0,
    },
    foreground: {
      borderRadius: 4,
    },
  },
  typography: {
    fontFamily: [
      "Roboto",
      "Oxygen",
      "IRANSansWeb",
      "system-ui",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Ubuntu",
      "Cantarell",
      "Open Sans",
      "Helvetica Neue",
      "sans-serif",
    ],
  },

  components: {
    MuiList: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
          "&::-webkit-scrollbar": {
            width: "0.4em",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,.1)",
            outline: "1px solid slategrey",
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderWidth: 2,
          borderRadius: 6,
          padding: "8px 18px",
          whiteSpace: "nowrap",
          boxShadow: "none",
          ":hover": {
            boxShadow: "none",
            borderWidth: 2,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 2,
          border: 0,
        },
      },
    },
  },
};
