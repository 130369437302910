import { Box, Grid, Stack, Typography } from "@mui/material";

import {
  Add,
  CalendarMonth,
  Dashboard as DashboardIcon,
  LiveTv,
  Map,
  People,
} from "@mui/icons-material";

import CustomButton from "../../../components/@common/CustomButton";

import PageContainer from "../../../components/@common/PageContainer";

import { useNavigate } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction";
import { useModal } from "../../../hooks/useModal";
import CustomInput from "../../../components/@common/CustomInput";
import { blue } from "@mui/material/colors";
import moment from "moment";

export default function EventManagement() {
  const { setModal } = useModal();
  return (
    <PageContainer title={"Event management"} icon={<CalendarMonth />}>
      <FullCalendar
        selectable
        events={[
          {
            title: "Call agent 3245",
            start: "2024-03-01",
            end: "2024-03-04",
          },
        ]}
        dateClick={(info) => {
          setModal({
            title:
              "Add an event for " + moment(info.dateStr).format("YYYY/MM/DD"),
            child: (
              <Stack p={2} spacing={2}>
                <CustomInput label={"Title"} />
                <CustomInput label={"Description"} multiline rows={2} />
                <CustomButton label={"Save"} />
              </Stack>
            ),
          });
        }}
        plugins={[dayGridPlugin, interactionPlugin]}
      />
    </PageContainer>
  );
}
