import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import AppLayout from "./components/@app/AppLayout";
import Home from "./pages/Home";
import Settings from "./pages/Settings";
import "./index.css";
import { useEffect, useState } from "react";
import LoginPage from "./pages/Login";
import NotFound from "./pages/NotFound";
import CustomerManagement from "./pages/app/CustomerManagement";
import AddNewCustomer from "./pages/app/CustomerManagement/AddNewCustomer";
import AgentManagement from "./pages/app/AgentManagement";
import AddNewAgent from "./pages/app/AgentManagement/AddNewAgent";
import TripManagement from "./pages/app/TripManagement";
import Dashboard from "./pages/app/Dashboard";
import api from "./config/api";
import Cookies from "js-cookie";
import EditCustomer from "./pages/app/CustomerManagement/EditCustomer";
import EditAgent from "./pages/app/AgentManagement/EditAgent";
import FormManagement from "./pages/app/FormManagement";
import WarehouseManagement from "./pages/app/WarehouseManagement";
import LiveView from "./pages/app/LiveView";
import ReportManagement from "./pages/app/ReportManagement";
import Buckets from "./pages/app/WarehouseManagement/Buckets";
import GeneralProducts from "./pages/app/WarehouseManagement/General";
import ChatView from "./pages/app/Chat";
import Logs from "./pages/app/Logs";
import EventManagement from "./pages/app/EventManagement";
import Vacations from "./pages/app/Vacations";
import CustomerContract from "./pages/app/CustomerContract";
import ContractRelation from "./pages/app/CustomerContract/ContractRelation";
import Map from "./components/test";
import AgentsLiveMap from "./pages/AgentsLiveMap";

export default function App() {
  const [isAuth, setIsAuth] = useState(true);
  const [isAccounting, setIsAccounting] = useState(false);

  const [isLodingUser, setIsLoadingUser] = useState(false);

  function handleLogout() {
    Cookies.remove("gwmuser");
    setIsAuth(false);
  }

  useEffect(() => {
    if (Cookies.get("gwmuser")) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
    setIsLoadingUser(false);
  }, [isAuth]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="livemap" element={<AgentsLiveMap/>}/>
        {!isLodingUser && (
          <Route
            path="/"
            element={
              isAuth ? (
                <AppLayout
                  setIsAccounting={setIsAccounting}
                  isAccounting={isAccounting}
                  handleLogout={handleLogout}
                />
              ) : (
                ""
              )
            }
          >
            <Route
              index
              element={
                isAuth ? <Dashboard /> : <LoginPage setIsAuth={setIsAuth} />
              }
            />
            {isAuth && (
              <>
                <Route path="app/dashboard" element={<Dashboard />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/test" element={<Map />} />
                <Route
                  path="app/customer-management"
                  element={<CustomerManagement />}
                />
                <Route
                  path="app/customer-management/add"
                  element={<AddNewCustomer />}
                />
                <Route
                  path="app/customer-management/edit/:id"
                  element={<EditCustomer />}
                />
                <Route
                  path="app/agent-management"
                  element={<AgentManagement />}
                />
                <Route
                  path="app/agent-management/add"
                  element={<AddNewAgent />}
                />
                <Route
                  path="app/agent-management/edit/:type/:id"
                  element={<EditAgent />}
                />
                <Route
                  path="app/trip-management"
                  element={<TripManagement />}
                />
                <Route
                  path="app/form-management"
                  element={<FormManagement />}
                />
                <Route
                  path="app/warehouse-management"
                  element={<WarehouseManagement />}
                />
                <Route path="app/vacations" element={<Vacations />} />
                <Route
                  path="app/customer_contract"
                  element={<CustomerContract />}
                />
                <Route
                  path="app/contract_relation"
                  element={<ContractRelation />}
                />
                <Route
                  path="app/warehouse-management/general"
                  element={<GeneralProducts />}
                />
                <Route
                  path="app/warehouse-management/buckets"
                  element={<Buckets />}
                />
                <Route
                  path="app/report-management"
                  element={<ReportManagement />}
                />
                <Route
                  path="app/event-management"
                  element={<EventManagement />}
                />
                <Route path="app/chat" element={<ChatView />} />
                <Route path="app/live-view" element={<LiveView />} />
                <Route path="app/logs" element={<Logs />} />
              </>
            )}
            <Route path="*" element={<NotFound />} />
          </Route>
        )}
      </Routes>
    </BrowserRouter>
  );
}
