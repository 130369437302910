import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
// import useFetch from "../../../hooks/useFetch";
import { CircularProgress } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const CollectionStatus = () => {
  // const { data: collection, loading: collectionLoading } = useFetch(
  //   `/api/drivers/admin/reports/collectionAmount`
  // );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Monthly Collection",
      },
    },
  };

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // const labels = collection?.data?.map((item) => monthNames[item?.month - 1]);

  const data = {
    labels: monthNames || [],
    datasets: [
      {
        label: "Collection",
        data: [100,340,590,630,300,400,120,450,580,320,190,560] || [],
        backgroundColor: "rgba(255, 99, 132, 1)",
      },
    ],
  };

  // if (collectionLoading) {
  //   return (
  //     <CircularProgress
  //       sx={{
  //         color: (theme) => theme.palette.secondary.main,
  //       }}
  //     />
  //   );
  // }

  return <Bar options={options} data={data} />;
};

export default CollectionStatus;
