import * as React from "react";

import Box from "@mui/material/Box";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { unstable_useDateField as useDateField } from "@mui/x-date-pickers/DateField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CustomInput from "./CustomInput";

const BrowserField = React.forwardRef((props, inputRef) => {
  const {
    disabled,
    id,
    label,
    InputProps: { ref: containerRef, startAdornment, endAdornment } = {},
    error,
    focused,
    ownerState,
    ...other
  } = props;

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        width: "100%",
        ".MuiFilledInput-root": {
          "::after": { opacity: "0" },
          "::before": { opacity: "0" },
          borderRadius: (theme) => theme.settings.foreground.borderRadius - 2,
        },
        borderRadius: 4,
      }}
      id={id}
      ref={containerRef}
    >
      {startAdornment}

      <CustomInput
        disabled={disabled}
        inputRef={inputRef}
        endAdornment={endAdornment}
        {...props}
        control={props.ownerState.control}
        onControlChange={props.ownerState.onControlChange}
      />
    </Box>
  );
});

function BrowserDateField(props) {
  const {
    inputRef: externalInputRef,
    slots,
    slotProps,
    ...textFieldProps
  } = props;

  const response = useDateField({
    props: textFieldProps,
    inputRef: externalInputRef,
  });

  return <BrowserField {...textFieldProps.value} {...response} />;
}

function BrowserDatePicker(props) {
  return (
    <DatePicker closeOnSelect={true}
      slots={{ field: BrowserDateField, ...props.slots }}
      {...props}
    />
  );
}

export default function CustomDatePicker(props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <BrowserDatePicker {...props}/>
    </LocalizationProvider>
  );
}
