import Axios from "axios";
import Cookies from "js-cookie";

const api = Axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

api.defaults.headers.Authorization = `Bearer ${Cookies.get("gwmuser")}`;

export default api;
