import { Box, ButtonBase, Grid, Stack, Typography } from "@mui/material";

import {
  Assessment,
  CalendarMonth,
  Chat,
  Dashboard as DashboardIcon,
  DateRange,
  DynamicForm,
  Map,
  OilBarrel,
  People,
  PersonPin,
  ReceiptLong,
  Warehouse,
} from "@mui/icons-material";
import Masonry from "@mui/lab/Masonry";
import PageContainer from "../../../components/@common/PageContainer";
import CardItem from "../../../components/@dashboard/CardItem";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import Oil from "../Oil";
import { useModal } from "../../../hooks/useModal";
import { blue, green, orange } from "@mui/material/colors";
import { getContrastedColor } from "../../../utils/getContrastedColor";
import CollectionStatus from "./CollectionStatus";

ChartJS.register(ArcElement, Tooltip, Legend);

const items = [
  {
    label: "Collection status",
    media: (
      <Box width={"100%"}>
        <CollectionStatus />
      </Box>
    ),
    list: [
      {
        label: "Type",
        value: "Euros",
      },
      {
        label: "UCO",
        value: "10.00",
      },
      {
        label: "Wastes",
        value: "4.22",
      },
      {
        label: "Chicken",
        value: "2.64",
      },
    ],
  },
  {
    label: "Warehouse",
    list: [
      {
        label: "Type",
        value: "In/Out",
      },
      {
        label: "UCO",
        value: "13/16",
      },
      {
        label: "Goods",
        value: "13/16",
      },
      {
        label: "Empty buckets",
        value: "13/16",
      },
    ],
  },

  {
    label: "Active drivers",
    list: [
      {
        label: "Ahmadi",
        value: "40/10",
      },
      {
        label: "Robin",
        value: "5/8",
      },
      {
        label: "John",
        value: "10/12",
      },
      {
        label: "Kavous",
        value: "16/12",
      },
    ],
  },
  // {
  //   label: "Purchase",

  //   list: [
  //     {
  //       label: "UCO",
  //       value: "10.00",
  //     },
  //     {
  //       label: "Wastes",
  //       value: "4.22",
  //     },
  //     {
  //       label: "Chicken",
  //       value: "2.64",
  //     },
  //   ],
  // },
];

const Dashboard = () => {
  const navigate = useNavigate();
  const { setModal } = useModal();

  const dashboardItems = [
    {
      id: "live-view",
      label: "Live view",
      description: "Track agents live location",
      onClick: () => navigate("/app/live-view"),
      icon: <Map fontSize="large" color="inherit" />,
      color: blue[500],
    },
    {
      id: "event-management",
      label: "Event management",
      description: "Manage event on calendar",

      onClick: () => navigate("/app/event-management"),
      icon: <CalendarMonth fontSize="large" color="inherit" />,
      color: green[500],
    },
    {
      id: "customer-management",
      label: "Customer management",
      description: "Add or edit customers",

      onClick: () => navigate("/app/customer-management"),
      icon: <People fontSize="large" color="inherit" />,
      color: blue[500],
    },
    {
      id: "agent-management",
      label: "Agent management",
      description: "Add or edit agents",

      onClick: () => navigate("/app/agent-management"),
      icon: <PersonPin fontSize="large" color="inherit" />,
      color: orange[500],
    },

    {
      id: "trip-management",
      label: "Trip management",
      description: "Manage trips and plans",
      onClick: () => navigate("/app/trip-management"),
      icon: <DateRange fontSize="large" color="inherit" />,
      color: green[500],
    },
    {
      id: "form-management",
      label: "Form management",
      description: "Add or edit forms",
      onClick: () => navigate("/app/form-management"),
      icon: <DynamicForm fontSize="large" color="inherit" />,
      color: blue[500],
    },
    {
      id: "warehouse-management",
      label: "Warehouse management",
      description: "Manage warehouse products",
      onClick: () => navigate("/app/warehouse-management"),
      icon: <Warehouse fontSize="large" color="inherit" />,
      color: blue[500],
    },
    {
      id: "report-management",
      label: "Report management",
      description: "Get report about what is done",
      onClick: () => navigate("/app/report-management"),
      icon: <Assessment fontSize="large" color="inherit" />,
      color: orange[500],
    },
    {
      id: "logs",
      label: "Logs",
      onClick: () => navigate("/app/logs"),
      description: "Watch recent logs and activities",
      icon: <ReceiptLong fontSize="large" color="inherit" />,
      color: blue[500],
    },
    {
      id: "chat",
      label: "Chat",
      onClick: () => navigate("/app/chat"),
      description: "Chat real-time with teams and co-workers",
      icon: <Chat fontSize="large" color="inherit" />,
      color: blue[500],
    },
    {
      id: "oil",
      label: "Oil",
      description: "Manage oil quantity",
      onClick: () =>
        setModal({
          title: "Oil",
          child: <Oil />,
        }),
      icon: <OilBarrel fontSize="large" color="inherit" />,
      color: blue[500],
    },
  ];
  return (
    <PageContainer title={"Dashboard"} icon={<DashboardIcon />}>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
        <Grid container>
          {dashboardItems.map((item) => {
            return (
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <ButtonBase
                  sx={{
                    p: 4,
                    borderRadius: 4,
                    width: "100%",
                    height: "100%",
                    ":hover": {
                      boxShadow: (theme) =>
                        `0 4px 4px 2px ${theme.palette.background.main}`,
                      backgroundColor: (theme) => theme.palette.background.main,
                    },
                  }}
                  onClick={item.onClick}
                >
                  <Stack alignItems={"center"} spacing={1} sx={{}}>
                    <Box
                      sx={{
                        svg: {
                          bgcolor: item.color,
                          width: 62,
                          height: 62,
                          borderRadius: 4,
                          p: 1,
                          color: (theme) =>
                            getContrastedColor(item.color) + " !important",
                        },
                      }}
                    >
                      {item.icon}
                    </Box>
                    <Typography fontWeight={700} lineHeight={1.2}>
                      {item.label}
                    </Typography>
                    <Typography variant="body2" sx={{ opacity: 0.8 }}>
                      {item.description}
                    </Typography>
                  </Stack>
                </ButtonBase>
              </Grid>
            );
          })}
        </Grid>
        <Stack sx={{ width: "100%" }}>
          <CardItem
            title={items[0].label}
            actions={items[0].actions}
            sx={{ width: "100%", mb: 2 }}
          >
            {items[0].media ? items[0].media : ""}
          </CardItem>
          <Masonry columns={{ xs: 1, sm: 1, md: 1, lg: 2 }} spacing={2}>
            {items.map((cardItem, i) => {
              if (i > 0)
                return (
                  <CardItem title={cardItem.label} actions={cardItem.actions}>
                    <Stack direction={"row"} spacing={2} my={2}>
                      <Stack spacing={2} flex={1}>
                        {cardItem.label !== "Collection status" &&
                          cardItem.list.map(({ label, value }) => {
                            return (
                              <Stack
                                direction={"row"}
                                justifyContent={"space-between"}
                              >
                                <Typography>{label}</Typography>
                                <Typography px={2}>{value}</Typography>
                              </Stack>
                            );
                          })}
                      </Stack>
                    </Stack>
                    {cardItem.media ? cardItem.media : ""}
                  </CardItem>
                );
            })}
          </Masonry>
        </Stack>
      </Stack>
    </PageContainer>
  );
};

const data = {
  labels: ["UCO", "Wastes", "Chicken"],

  datasets: [
    {
      label: "#",
      data: items[1].list
        .slice(1, items[1].list.length)
        .map((item) => item.value),
      backgroundColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 0,
    },
  ],
};

function CollectionStatus1() {
  return (
    <Pie
      data={data}
      options={{
        plugins: {
          legend: {
            align: "start",
            position: "left",
          },
        },
      }}
    />
  );
}

export default Dashboard;
