import {Add, Check, Delete, Warehouse} from "@mui/icons-material";
import {blue, green} from "@mui/material/colors";
import CustomButton from "../../../components/@common/CustomButton";
import CustomTable from "../../../components/@common/CustomTable";
import useFetch from "../../../hooks/useFetch";
import PageContainer from "../../../components/@common/PageContainer";
import {FaDoorOpen, FaTimes} from "react-icons/fa";
import {IconButton} from "@mui/material";
import ConfirmDelete from "../../../components/@common/ConfirmDelete";
import {LiaTimesSolid} from "react-icons/lia";


function WarehouseFreshProductForm() {
    return null;
}

export default function Vacations() {
    const {data, loading} = useFetch("/api/managers/drivers/vacations");
    const serializedData = data?.data?.map((item) => {
        return {
            id: item.id,
            name: item.driver_info.first_name + ' ' + item.driver_info.last_name,
            type: item.type,
            from: item.from,
            to: item.to,
            date: item.request_date,
            status: item.status,
            "Accept | Reject": (

                item.status === 'requested' && (
                    <>
                        <IconButton
                            onClick={() =>
                                setModal({
                                    title: "confirm delete",
                                    child: (
                                        <ConfirmDelete
                                            id={item.id}
                                            onClose={() => setModal(null)}
                                        />
                                    ),
                                })
                            }
                        >
                            <Check/>
                        </IconButton>
                        <IconButton
                            onClick={() =>
                                setModal({
                                    title: "confirm delete",
                                    child: (
                                        <ConfirmDelete
                                            id={item.id}
                                            onClose={() => setModal(null)}
                                        />
                                    ),
                                })
                            }
                        >
                            <LiaTimesSolid/>
                        </IconButton>
                    </>
                )


            ),
        };
    });
    return (
        <PageContainer
            title={"Drivers vacations"}
            icon={<FaDoorOpen color={`#FFFFFF`}/>}
            color={green[500]}
        >
            <CustomTable
                options={{
                    rows: serializedData || [],
                    loading,
                }}
            />
        </PageContainer>
    )
}