import { Add, Group, Palette as PaletteIcon } from "@mui/icons-material";

import CustomButton from "../../../components/@common/CustomButton";
import PageContainer from "../../../components/@common/PageContainer";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import CustomTable from "../../../components/@common/CustomTable";
import api from "../../../config/api";
import Cookies from "js-cookie";
import { mutate } from "swr";
import { useState } from "react";
import { useEffect } from "react";
import {
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useModal } from "../../../hooks/useModal";
import CustomInput from "../../../components/@common/CustomInput";
import useFetch from "../../../hooks/useFetch";
import { useRef } from "react";
import { blue } from "@mui/material/colors";
import { useSnackbar } from "notistack";

export default function FormManagement() {
  const entities = [
    {
      title: "cities",
      type: "city",
    },
    {
      title: "zip codes",
      type: "zipcode",
    },
    {
      title: "establishments",
      type: "establishment",
    },
    {
      title: "languages",
      type: "language",
    },
  ];

  return (
    <PageContainer
      title={"Form Management"}
      icon={<Group />}
      color={blue[500]}
      actions={<></>}
    >
      <Grid container gap={2}>
        {entities.map(({ title, type }) => (
          <Grid item xs={3.5} key={title}>
            <SingleEntity title={title} entity_type={type} />
          </Grid>
        ))}
      </Grid>
    </PageContainer>
  );
}

function SingleEntity({ title, entity_type }) {
  const { data, loading } = useFetch(
    `/api/managers/form_fields/entity_type?entity_type=${entity_type}`
  );

  const serializedData = data?.data?.map((item) => {
    return {
      id: item.id,
      title: item.title,
    };
  });

  const { setModal } = useModal();
  return (
    <CustomTable
      outlined
      disablePadding
      title={title}
      action={
        <CustomButton
          label={"add"}
          variant={"text"}
          size={"small"}
          onClick={() =>
            setModal({
              title: `Add ${entity_type}`,
              child: (
                <AddEntity
                  entity_type={entity_type}
                  onClose={() => setModal(null)}
                />
              ),
            })
          }
        />
      }
      onDelete={(id) =>
        setModal({
          title: "confirm delete",
          child: (
            <ConfirmDelete
              id={id}
              entity_type={entity_type}
              onClose={() => setModal(null)}
            />
          ),
        })
      }
      options={{
        rows: serializedData ?? [],
        loading,
        showId: false,
      }}
    />
  );
}

function AddEntity({ entity_type, onClose }) {
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const add = async () => {
    setLoading(true);
    try {
      const response = await api.post(
        "/api/managers/form_fields/add",
        { title, entity_type },
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("gwmuser"),
          },
        }
      );
      setLoading(false);
      mutate(
        `/api/managers/form_fields/entity_type?entity_type=${entity_type}`
      );
      onClose();
      enqueueSnackbar({
        message: "Added",
        variant: "success",
      });
      return response.data;
    } catch (error) {
      console.log(error);
      setLoading(false);
      enqueueSnackbar({
        variant: "error",
        message: "Error",
      });
      return;
    }
  };

  return (
    <Stack sx={{ p: 2 }} spacing={2}>
      <CustomInput
        autoFocus
        label="title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <CustomButton label={"add"} onClick={() => add()} loading={loading} />
    </Stack>
  );
}

function ConfirmDelete({ id, entity_type, onClose }) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const deleteItem = async () => {
    setLoading(true);

    try {
      const response = await api.post(
        "/api/managers/form_fields/remove",
        { id },
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("gwmuser"),
          },
        }
      );

      mutate(
        `/api/managers/form_fields/entity_type?entity_type=${entity_type}`,
        () => onClose()
      );
      enqueueSnackbar({
        message: "Deleted",
        variant: "success",
      });
      setLoading(false);

      return response.data;
    } catch (error) {
      if (error.response.data.message) {
        enqueueSnackbar({
          variant: "error",
          message: "error",
        });
      }
      setLoading(false);

      return;
    }
  };

  return (
    <Stack
      sx={{
        p: 2,
      }}
      spacing={1}
    >
      <Typography>Are you sure?</Typography>
      <Stack direction={"row"} spacing={2} justifyContent={"end"}>
        {!loading && (
          <>
            <CustomButton
              label="cancel"
              variant="text"
              color="inherit"
              onClick={onClose}
            />
            <CustomButton
              label="confirm"
              variant="text"
              color="error"
              onClick={async () => await deleteItem()}
            />
          </>
        )}
        {loading && <CircularProgress size={16} color="secondary" />}
      </Stack>
    </Stack>
  );
}
