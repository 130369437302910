import { Paper } from "@mui/material";
import { getContrastedColor } from "../../utils/getContrastedColor";

export default function BackgroundPaper({ children, sx }) {
  return (
    <Paper
      sx={{
        borderRadius: (theme) => theme.settings.foreground.borderRadius + 1,
        bgcolor: (theme) => `${theme.palette.background.main} !important`,
        color: (theme) => getContrastedColor(theme.palette.background.main),
        ...sx,
      }}
      variant={"outlined"}
    >
      {children}
    </Paper>
  );
}
