import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Stack,
  Switch,
  Tab,
  Typography,
  alpha,
  capitalize,
} from "@mui/material";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import {
  Add,
  AddLocationAlt,
  Delete,
  Edit,
  PictureAsPdf,
  Save,
} from "@mui/icons-material";
// import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ForegroundPaper from "../../../components/@common/ForegroundPaper";
import { getContrastedColor } from "../../../utils/getContrastedColor";
import CustomInput from "../../../components/@common/CustomInput";
import CustomButton from "../../../components/@common/CustomButton";
import { useModal } from "../../../hooks/useModal";
import PageContainer from "../../../components/@common/PageContainer";
import CustomSelect from "../../../components/@common/CustomSelect";
import useForm from "../../../hooks/useForm";
import { useNotification } from "../../../hooks/useNotification";
import api from "../../../config/api";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import CustomTable from "../../../components/@common/CustomTable";
import CustomDatePicker from "../../../components/@common/CustomDatePicker";
import moment from "moment";
import useFetch from "../../../hooks/useFetch";
import { mutate } from "swr";
import { blue } from "@mui/material/colors";
import useCrud from "../../../hooks/useCrud";
import DraggableMap from "../../../components/@common/Map/DraggableMap";
import { TabContext, TabList, TabPanel } from "@mui/lab";

export default function EditCustomer() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const { setNotification } = useNotification();
  const { crud, loading: loadingCrud } = useCrud();
  const { setModal } = useModal();
  const [customer, setCustomer] = useState(null);
  const { setValues, values, handleInput, handleSelect } = useForm({
    customer_number: "",
    first_name: "",
    last_name: "",
    company_name: "",
    email: "",
    phone: "",
    mobile: "",
    address: "",
    city: "",
    zipcode: "",
    latitude: "",
    longitude: "",
    establishment: "",
    contract_status: "",
    contracted_by: "",
    time_note: "",
    collection_preference: "",
    working_time: "",
    preferred_language: "",
    note: "",
  });

  const getCustomer = async () => {
    try {
      const response = await api.post(
        "/api/managers/customers/customer",
        {
          id,
        },
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("gwmuser"),
          },
        }
      );
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCustomer().then((customer) => {
      setCustomer(customer?.data);
      setValues(customer?.data);
      return;
    });
  }, []);

  return (
    <PageContainer
      loading={loading || loadingCrud}
      title={"Edit customer"}
      icon={<Edit />}
      color={blue[500]}
      actions={
        <>
          <CustomButton
            label={"Contract history"}
            variant={"text"}
            onClick={() =>
              setModal({
                title: "Contract history",
                child: <ContractHistory customer={customer} />,
              })
            }
          />
          <CustomButton
            label={"Visit history"}
            variant={"outlined"}
            onClick={() =>
              setModal({
                title: "visits",
                child: <VisitHistory customer={customer} />,
              })
            }
          />
          <CustomButton
            label={"Save"}
            endIcon={<Save />}
            onClick={() =>
              crud.post("/api/managers/customers/edit", values, "Saved")
            }
          />
        </>
      }
    >
      <EditCustomerForm
        values={values}
        setValues={setValues}
        handleInput={handleInput}
        handleSelect={handleSelect}
      />
    </PageContainer>
  );
}

function EditCustomerForm({ values, setValues, handleInput, handleSelect }) {
  const { setModal } = useModal();
  const [markerPos, setMarkerPos] = useState([
    values?.latitude,
    values?.longitude,
  ]);

  useEffect(() => {
    setValues({
      ...values,
      latitude: markerPos[0],
      longitude: markerPos[1],
    });
  }, [markerPos]);

  const { data: agents } = useFetch("/api/managers/agents");

  const serializedDrivers = agents?.drivers?.map((item) => {
    return {
      id: item.id,
      title: item.first_name + " " + item.last_name,
    };
  });

  return (
    <ForegroundPaper>
      <Stack
        direction={"row"}
        alignItems={"flex-start"}
        spacing={2}
        sx={{ width: "100%", p: 2 }}
      >
        <Stack
          direction={"column"}
          alignItems={"stretch"}
          justifyContent={"flex-start"}
          spacing={2}
          sx={{
            flex: 1,
          }}
        >
          <Stack
            sx={{ width: "100%" }}
            direction={"row"}
            justifyContent={"space-between"}
            spacing={2}
          >
            <Stack
              sx={{ width: "100%" }}
              direction={"column"}
              justifyContent={"flex-start"}
              spacing={2}
            >
              <Stack sx={{ width: "100%" }} direction={"row"} spacing={2}>
                <CustomInput
                  fullWidth
                  label={"Customer number"}
                  value={values.customer_number}
                  type="text"
                  onChange={handleInput("customer_number")}
                />
                <CustomInput
                  fullWidth
                  label={"First name"}
                  value={values.first_name}
                  type="text"
                  onChange={handleInput("first_name")}
                />
                <CustomInput
                  fullWidth
                  value={values.last_name}
                  label={"Last name"}
                  type="text"
                  onChange={handleInput("last_name")}
                />
                <CustomInput
                  value={values.company_name}
                  fullWidth
                  label={"Company name"}
                  type="text"
                  onChange={handleInput("company_name")}
                />
                <CustomInput
                  value={values.email}
                  fullWidth
                  label={"Email"}
                  type="email"
                  onChange={handleInput("email")}
                />
              </Stack>
            </Stack>
          </Stack>

          <Stack sx={{ width: "100%" }} direction={"row"} spacing={2}>
            <CustomInput
              value={values.phone}
              fullWidth
              label={"Phone"}
              type="tel"
              onChange={handleInput("phone")}
            />
            <CustomInput
              value={values.mobile}
              fullWidth
              label={"Mobile"}
              type="tel"
              onChange={handleInput("mobile")}
            />
            <SingleEntity
              label={"City"}
              value={values.city}
              onChange={handleSelect("city")}
              entityType={"city"}
            />
            <SingleEntity
              label={"Zip code"}
              value={values.zipcode}
              onChange={handleSelect("zipcode")}
              entityType={"zipcode"}
            />
            <CustomInput
              value={values.address}
              label={"Address"}
              type="text"
              onChange={handleInput("address")}
            />
            <Box
              sx={{
                position: "relative",
                alignSelf: "end",
              }}
            >
              <CustomButton
                color="info"
                variant={
                  values.latitude && values.longitude ? "contained" : "outlined"
                }
                sx={{
                  height: 48,
                }}
                onClick={() =>
                  setModal({
                    title: "Pin the address",
                    child: (
                      <Stack
                        sx={{
                          position: "relative",
                          width: "50vw",
                          height: "50vh",
                        }}
                      >
                        <DraggableMap
                          setModal={setModal}
                          values={values}
                          setValues={setValues}
                        />
                      </Stack>
                    ),
                  })
                }
                label={<AddLocationAlt />}
              />
            </Box>
          </Stack>
          <Stack sx={{ width: "100%" }} direction={"row"} spacing={2}>
            <SingleEntity
              value={values.preferred_language}
              label={"Preferred language"}
              entityType={"language"}
              onChange={handleSelect("preferred_language")}
            />
            <SingleEntity
              value={values.working_time}
              label={"Working time"}
              entityType={"working_time"}
              onChange={handleSelect("working_time")}
            />
            <CustomInput
              value={values.time_note}
              label={"Time note"}
              type="text"
              onChange={handleInput("time_note")}
            />
          </Stack>
          <Stack sx={{ width: "100%" }} direction={"row"} spacing={2}>
            <SingleEntity
              value={values.establishment}
              label={"Type of establishment"}
              entityType={"establishment"}
              onChange={handleSelect("establishment")}
            />
            <CustomSelect
              value={values.contract_status}
              label={"Contract status"}
              options={[
                { id: 1, title: "available" },
                { id: 2, title: "in progress" },
              ]}
              onChange={handleSelect("contract_status")}
            />

            <CustomSelect
              label={"Contracted by"}
              options={serializedDrivers}
              value={
                serializedDrivers?.sort()[values?.contracted_by - 1]?.title
              }
              onChange={(e, opt) =>
                setValues({
                  ...values,
                  contracted_by: opt.id,
                })
              }
            />
            <CustomSelect
              value={values.collection_preference}
              label={"Collection preference"}
              options={[
                { id: 1, title: "every 20 days" },
                { id: 2, title: "every 30 days" },
                { id: 3, title: "every 40 days" },
              ]}
              onChange={handleSelect("collection_preference")}
            />
          </Stack>

          <CustomInput
            value={values.note}
            label={"Additional note"}
            type="text"
            multiline
            rows={2}
            onChange={handleInput("note")}
          />
        </Stack>
      </Stack>
    </ForegroundPaper>
  );
}

function SingleEntity({ value, label, entityType, onChange }) {
  const { data, loading } = useFetch(
    `/api/managers/form_fields/entity_type?entity_type=${entityType}`
  );

  return (
    <CustomSelect
      loading={loading}
      value={value}
      label={label}
      onChange={onChange}
      options={data?.data}
    />
  );
}

function VisitHistory({ customer }) {
  const [fields, setFields] = useState([]);
  const { setNotification } = useNotification();
  const { crud, loading } = useCrud();

  const [exportPdf, setExportPdf] = useState(false);
  const {
    data,
    loading: loadingPassedVisits,
    mutateFetch,
  } = useFetch(
    `/api/managers/customers/passed_visits?customer_id=${customer.id}`
  );
  const {
    data: failData,
    loading: loadingFailVisits,
    mutateFetch: mutateFail,
  } = useFetch(
    `api/managers/customers/passed_visits/failedVisits?customer_id=${customer.id}`
  );

  const handlePdfDownload = async (visit_id) => {
    setExportPdf(true);
    try {
      const response = await api
        .get(
          `/api/drivers/admin/customer_passed_visits/makePDF?visit_id=${visit_id}`,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          setExportPdf(false);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${customer.last_name}.pdf`);
          document.body.appendChild(link);
          link.click();
          setNotification({
            message: "Pdf Downloaded",
            variant: "success",
          });
        });
    } catch (error) {
      console.error("Error downloading PDF:", error);
      setNotification({
        message: "PDF is not available",
        variant: "error",
      });
      setExportPdf(false);
    }
  };

  const onDelete = async (visit_id) => {
    await crud.post("/api/managers/customers/passed_visits/remove", {
      visit_id,
    });
    mutateFetch(
      `/api/managers/customers/passed_visits?customer_id=${customer.id}`
    );
  };

  let serializedData = data?.data?.map((item) => {
    return {
      id: item.id,
      driver: item.driver.first_name + " " + item.driver.last_name,
      "visit date": moment(item.visit_date).format("YYYY/MM/DD"),
      status: item.status && (
        <Chip color="warning" size="small" label={capitalize(item.status)} />
      ),
      barters: item?.barters.length === 0 ? "No" : "Yes",
      amount: item?.visit_info?.amount + " Kg",
      "contract price": item?.visit_info?.contract_price + " EURO",
      "payment type": item?.visit_info?.payment_type,
      note: item?.note,
      "export pdf": (
        <IconButton
          onClick={() => {
            handlePdfDownload(item?.visit_info?.visit_id);
          }}
        >
          {exportPdf ? (
            <CircularProgress
              sx={{
                color: (theme) => theme.palette.secondary.main,
              }}
            />
          ) : (
            <PictureAsPdf />
          )}
        </IconButton>
      ),
      "": (
        <IconButton
          disabled={loading || loadingPassedVisits}
          onClick={() => onDelete(item?.visit_info?.visit_id)}
        >
          {loading || loadingPassedVisits ? <CircularProgress /> : <Delete />}
        </IconButton>
      ),
    };
  });

  let serializedFailedData = failData?.data?.map((item) => {
    return {
      id: item?.id,
      driver: item?.driver?.first_name + " " + item?.driver?.last_name,
      "visit date": moment(item?.visit_date).format("YYYY/MM/DD"),
      status: item?.status && (
        <Chip color="warning" size="small" label={capitalize(item?.status)} />
      ),
      barters: item?.barters?.length === 0 ? "No" : "Yes",
      amount: item?.visit_info?.amount + " Kg",
      "contract price": item?.visit_info?.contract_price + " EURO",
      "payment type": item?.visit_info?.payment_type,
      note: item?.note,
      "export pdf": (
        <IconButton
          onClick={() => {
            handlePdfDownload(item?.visit_info?.visit_id);
          }}
        >
          {exportPdf ? (
            <CircularProgress
              sx={{
                color: (theme) => theme.palette.secondary.main,
              }}
            />
          ) : (
            <PictureAsPdf />
          )}
        </IconButton>
      ),
      // "": (
      //   <IconButton
      //     disabled={loading || loadingFailVisits}
      //     onClick={() => onDelete(item?.visit_info?.visit_id)}
      //   >
      //     {loading || loadingPassedVisits ? <CircularProgress /> : <Delete />}
      //   </IconButton>
      // ),
    };
  });
  // const [value, setValue] = useState("1");

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const TripStatus = [
    { id: 1, title: "Success Visit" },
    { id: 2, title: "Failed Visit" },
    { id: 3, title: "Marketing Visit" },
  ];

  const [tripStatus, setTripStatus] = useState("Success Visit");

  return (
    <Box sx={{ width: "100%" }}>
      {/* <TabContext value={value}> */}
      {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            TabIndicatorProps={{
              style: {
                backgroundColor: "white",
              },
            }}
          >
            <Tab
              label="Successed Trip"
              value="1"
              sx={{
                "&.Mui-selected": {
                  color: "white",
                },
              }}
            />
            <Tab
              label="Failed Trip"
              value="2"
              sx={{
                "&.Mui-selected": {
                  color: "white",
                },
              }}
            />
          </TabList>
        </Box> */}
      {/* <TabPanel value="1"> */}
      <Stack p={1} width={"90vw"} spacing={3}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={2}
        >
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {capitalize(
              `${customer.first_name} ${customer.last_name}'s visit history`
            )}
          </Typography>
          <CustomSelect
            value={tripStatus.title}
            label={"history status"}
            options={TripStatus}
            onChange={(e, status) => setTripStatus(status)}
          />
        </Box>
        {tripStatus.title === "Failed Visit" ? (
          <CustomTable
            options={{
              rows: serializedFailedData,
              loading,
            }}
            title={`visit history`}
            action={
              <CustomButton
                disabled={fields?.length >= 1}
                label={"Add"}
                variant={"text"}
                endIcon={<Add />}
                onClick={() =>
                  setFields([
                    ...fields,
                    {
                      id: uuidv4(),
                    },
                  ])
                }
                sx={{
                  mt: 2,
                  alignSelf: "end",
                }}
              />
            }
          />
        ) : tripStatus.title === "Marketing Visit" ? (
          <CustomTable
            options={{
              rows: "",
              loading,
            }}
            title={`visit history`}
            action={
              <CustomButton
                disabled={fields?.length >= 1}
                label={"Add"}
                variant={"text"}
                endIcon={<Add />}
                onClick={() =>
                  setFields([
                    ...fields,
                    {
                      id: uuidv4(),
                    },
                  ])
                }
                sx={{
                  mt: 2,
                  alignSelf: "end",
                }}
              />
            }
          />
        ) : (
          <CustomTable
            options={{
              rows: serializedData,
              loading,
            }}
            title={`visit history`}
            action={
              <CustomButton
                disabled={fields?.length >= 1}
                label={"Add"}
                variant={"text"}
                endIcon={<Add />}
                onClick={() =>
                  setFields([
                    ...fields,
                    {
                      id: uuidv4(),
                    },
                  ])
                }
                sx={{
                  mt: 2,
                  alignSelf: "end",
                }}
              />
            }
          />
        )}

        {fields?.map((item) => {
          return (
            <Field
              key={item.id}
              customer_id={customer.id}
              driver_id={customer.contracted_by}
              id={item.id}
              onRemove={() =>
                setFields(fields.filter((field) => field.id !== item.id))
              }
            />
          );
        })}
      </Stack>
      {/* </TabPanel> */}
      {/* <TabPanel value="2">
        <Stack p={1} width={"90vw"} spacing={3}>
            <CustomTable
              options={{
                rows: serializedFailedData,
                loadingFailVisits,
              }}
              title={`${customer.first_name} ${customer.last_name}'s visit history`}
              action={
                <CustomButton
                  disabled={fields?.length >= 1}
                  label={"Add"}
                  variant={"text"}
                  endIcon={<Add />}
                  onClick={() =>
                    setFields([
                      ...fields,
                      {
                        id: uuidv4(),
                      },
                    ])
                  }
                  sx={{
                    mt: 2,
                    alignSelf: "end",
                  }}
                />
              }
            />
            {/* {fields?.map((item) => {
              return (
                <Field
                  key={item.id}
                  customer_id={customer.id}
                  driver_id={customer.contracted_by}
                  id={item.id}
                  onRemove={() =>
                    setFields(fields.filter((field) => field.id !== item.id))
                  }
                />
              );
            })} */}
      {/* </Stack>
        </TabPanel> */}
      {/* </TabContext> */}
    </Box>
  );
}
const ContractHistory = ({ customer }) => {
  const { data: contractRelations, loading: contractRelationLoading } =
    useFetch(`/api/managers/contracts/relations/customer?id=${customer?.id}`);
  const contractRelationObj = contractRelations?.data?.map((item) => {
    return {
      id: item?.id,
      "Contract price": item?.contract_price,
      "Contract currency": item?.contract_currency,
      "Updated at": moment(item?.updated_at).format("YYYY/MM/DD"),
    };
  });

  return (
    <Box p={2}>
      {contractRelationLoading && (
        <Stack
          direction={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          width={"100%"}
          height={120}
        >
          <CircularProgress color="inherit" />
        </Stack>
      )}

      {!contractRelationLoading && (
        <CustomTable
          hideselect={true}
          options={{
            rows: contractRelationObj,
          }}
        />
      )}
    </Box>
  );
};

function Field({ customer_id, driver_id, onRemove }) {
  const [barterCargo, setBarterCargo] = useState(false);
  const [paymentStatusActive, setPaymentStatusActive] = useState(true);
  const [loading, setLoading] = useState(false);
  const { setNotification } = useNotification();
  const { crud } = useCrud();
  const { data: agents } = useFetch("/api/managers/agents");
  const [paymentType, setPaymentType] = useState(false);
  const { values, setValues, handleSelect } = useForm({
    visit_date: new Date(),
    driver: { id: "", title: "" },
    total_collected: 0,
    contract_price: 0,
    payable_cost: 0,
    payment_type: "",
    payment_status: null,
    visit_barter: barterCargo ? 1 : 0,
    barters: null,
    pdf_doc: "",
    receipt: "",
  });
  const [serializedDrivers, setSerializedDrivers] = useState();

  useEffect(() => {
    if (values.payment_type === "transaction") {
      setPaymentType(true);
    } else {
      setPaymentType(false);
    }
  }, [paymentType]);

  useEffect(() => {
    async function getDrivers() {
      const response = await api.get("/api/managers/agents");
      let d = [];
      response?.data?.drivers.map((item) => {
        d.push({
          id: item.id,
          value: item.id,
          title: `${item.first_name} ${item.last_name}`,
        });
      });
      setSerializedDrivers(d);
    }
    getDrivers();
  }, []);
  // const serializedDriver = agents?.drivers?.map((item) => {
  //   return {
  //     id: item.id,
  //     title: item.first_name + " " + item.last_name,
  //   };
  // });
  const [receiptFile, setReceiptFile] = useState(null);
  const handleReceiptFileChange = (file) => {
    setReceiptFile(file);
  };
  const save = async () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("customer_id", customer_id);
    formData.append("driver_id", values?.driver?.id);
    formData.append("amount", values.total_collected);
    formData.append("pdf_doc", values?.pdf_doc);
    formData.append("receipt", values?.receipt);
    formData.append("contract_price", values.contract_price);
    formData.append("payment_type", values.payment_type);
    formData.append("visit_barter", values.visit_barter);
    formData.append("barters", values.barters);
    formData.append(
      "visit_date",
      moment(values.visit_date).format("YYYY/MM/DD")
    );
    formData.append(
      "payment_status",
      values.payment_status
        ? moment(values.payment_status).format("YYYY/MM/DD")
        : null
    );

    const response = await api.post(
      "/api/managers/customers/passed_visits/add",

      formData,

      {
        headers: {
          "Content-type": "multipart/form-data",
        },
      }
    );
    mutate(`/api/managers/customers/passed_visits?customer_id=${customer_id}`);
    if (response?.data?.result === "false") {
      setNotification({
        variant: "error",
        message: response.data.messages,
      });
      setLoading(false);
      return;
    }

    onRemove();
    setLoading(false);
  };

  return (
    <Stack
      spacing={2}
      p={2}
      sx={{
        borderRadius: (theme) => theme.settings.foreground.borderRadius,
        bgcolor: (theme) =>
          alpha(getContrastedColor(theme.palette.foreground.main), 0.05),
      }}
    >
      <Stack direction={"row"} spacing={2}>
        <CustomSelect
          label={"Driver"}
          options={serializedDrivers}
          value={values?.driver?.title}
          onChange={(e, driver) => {
            setValues({ ...values, driver: driver });
          }}
        />
        <CustomDatePicker
          label="visit"
          defaultValue={values.visit}
          onChange={(date) =>
            setValues({ ...values, visit_date: new Date(date) })
          }
        />
        <CustomInput
          label="total collected"
          type={"number"}
          onChange={(e) =>
            setValues({ ...values, total_collected: parseInt(e.target.value) })
          }
        />
        <CustomInput
          label="contract price"
          type={"number"}
          onChange={(e) =>
            setValues({ ...values, contract_price: parseInt(e.target.value) })
          }
        />
        <CustomInput
          label="payable cost"
          description="read only"
          type={"number"}
          value={values.total_collected * (values.contract_price / 100)}
        />
        <CustomSelect
          label="payment type"
          options={[
            {
              id: 1,
              title: "cash",
            },
            {
              id: 2,
              title: "transaction",
            },
          ]}
          value={values.payment_type}
          onChange={handleSelect("payment_type")}
        />
        {/* {paymentType && ( */}
        <CustomInput
          label="PDF"
          description="read only"
          type={"file"}
          onChange={(e) => setValues({ ...values, pdf_doc: e.target.files[0] })}
        />
        <CustomInput
          label="Receipt"
          description="read only"
          type={"file"}
          onChange={(e) => setValues({ ...values, receipt: e.target.files[0] })}
        />
        {/* )} */}
        <CustomDatePicker
          label="Payment status"
          value={values.payment_status}
          control={
            <Switch
              checked={!paymentStatusActive ? true : false}
              defaultChecked={!paymentStatusActive ? true : false}
              size="small"
              color="secondary"
              onChange={() => {
                setPaymentStatusActive(!paymentStatusActive);
                if (!paymentStatusActive) {
                  setValues({
                    ...values,
                    payment_status: null,
                  });
                } else {
                  setValues({
                    ...values,
                    payment_status: new Date(),
                  });
                }
              }}
            />
          }
          disabled={paymentStatusActive}
          onChange={(date) =>
            setValues({
              ...values,
              payment_status: new Date(date),
            })
          }
        />
      </Stack>

      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>{"Barter cargo"}</Typography>
          <Switch
            checked={barterCargo ? true : false}
            defaultChecked={barterCargo ? true : false}
            color="secondary"
            onChange={() => {
              setBarterCargo(!barterCargo);
              if (!values.visit_barter) {
                setValues({
                  ...values,
                  barters: null,
                  visit_barter: !barterCargo ? 1 : 0,
                });
              }
            }}
            inputProps={{ "aria-label": "Barter cargo" }}
          />
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <CustomButton
            label={"remove"}
            variant="text"
            disabled={loading}
            onClick={onRemove}
          />
          <CustomButton label={"save"} onClick={save} loading={loading} />
        </Stack>
      </Stack>
      {barterCargo && (
        <WareHouseProducts values={values} setValues={setValues} />
      )}
    </Stack>
  );
}

function WareHouseProducts({ values, setValues }) {
  const { data, loading } = useFetch("/api/managers/warehouse/products/wh");

  const serializedData = data?.data?.map((item) => {
    return {
      id: item.id,
      name: item.product.name,
      added: moment(item.enter_date).format("YYYY/MM/DD"),
      expire: moment(item.expired_date).format("YYYY/MM/DD"),
      "sell status": item.sell_status,
    };
  });

  return (
    <CustomTable
      options={{
        rows: serializedData || [],
        loading,
      }}
      onSelect={(id) => {
        setValues({ ...values, barters: id });
      }}
      onSelectAll={(ids) => {
        setValues({ ...values, barters: ids });
      }}
    />
  );
}
