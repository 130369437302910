import * as React from "react";
import { alpha, darken, lighten, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  capitalize,
} from "@mui/material";
import { ArrowDownward, ExpandMore } from "@mui/icons-material";
import { getContrastedColor } from "../../utils/getContrastedColor";

const CustomTextField = styled((props) => (
  <TextField
    fullWidth
    InputProps={{ disableUnderline: true, ...props }}
    {...props}
    label={props.label ? capitalize(String(props.label)) : ""}
  />
))(({ theme }) => ({
  borderRadius: theme.settings.foreground.borderRadius + 4,

  "& .MuiFilledInput-input:-webkit-autofill": {
    "&:-webkit-autofill": {
      borderRadius: 0,
      "-webkit-box-shadow": `0 0 0 100px ${theme.palette.foreground.main} inset`,
    },
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "transparent",
    ":hover": {
      backgroundColor: "transparent",
    },
  },
  "& .MuiInputBase-root": {
    "&:-webkit-autofill": {
      "-webkit-box-shadow": `none`,
    },

    overflow: "hidden",
    backgroundColor: theme.palette.foreground.main,
    border: "1px solid",
    borderColor: alpha(getContrastedColor(theme.palette.foreground.main), 0.5),
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),

    "&.Mui-focused": {
      borderColor: theme.palette.secondary.main,
      backgroundColor: theme.palette.foreground.main,
    },
  },
}));

export default function CustomInput(props) {
  return (
    <>
      {props.control && (
        <Box
          component={"span"}
          sx={{
            position: "relative",
            px: 1,
            pr: 1.2,
            border: 1,
            borderRight: 0,
            borderColor: (theme) =>
              alpha(getContrastedColor(theme.palette.foreground.main), 0.2),
            height: "100%",
            display: "flex",
            alignItems: "center",
            right: -5,
            borderTopLeftRadius: (theme) => theme.settings.borderRadius + 4,
            borderBottomLeftRadius: (theme) => theme.settings.borderRadius + 4,
          }}
        >
          {props.control}
        </Box>
      )}
      <CustomTextField
        id={props.label + "_input"}
        dropdown={props.dropdown?.toString()}
        inputProps={props.inputProps}
        value={props.value}
        onChange={props.onChange}
        variant="filled"
        {...props}
      />
    </>
  );
}
