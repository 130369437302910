import { CircularProgress, Grid, Stack } from "@mui/material";
import PageContainer from "../../../components/@common/PageContainer";
import { FaFileContract } from "react-icons/fa6";
import { blue } from "@mui/material/colors";
import useFetch from "../../../hooks/useFetch";
import MultipleSelect from "../../../components/@common/CustomMultiSelect";
import CustomInput from "../../../components/@common/CustomInput";
import CustomButton from "../../../components/@common/CustomButton";
import { useState } from "react";
import useCrud from "../../../hooks/useCrud";
import CustomSelect from "../../../components/@common/CustomSelect";
import ForegroundPaper from "../../../components/@common/ForegroundPaper";

const ContractRelation = () => {
  const { data: customerData, loading: customerLoading } = useFetch(
    "/api/managers/customers"
  );
  const { data: contract_data } = useFetch("/api/managers/contracts");
  const { crud,loading } = useCrud();
  const [priceValue, setPriceValue] = useState([]);
  const [customerContractList, setCustomerContractList] = useState(null);
  const [selectedId, setSelectedId] = useState([]);
  const customers = customerData?.data?.map((item) => {
    return {
      id:item?.id,
      customer_number: item?.customer_number,
      title: item?.last_name,
    };
  });

  const confirmRelation = () => {
    crud.post(
      "/api/managers/contracts/relations/makeRelation",
      {
        entity_id: selectedId,
        entity_type: "customer",
        contract_id: customerContractList?.id,
        contract_price: priceValue,
      },
      "Relation Created"
    );
    setPriceValue([]);
    setSelectedId([]);
  };

  const customerContract = contract_data?.data.map((item) => {
    return {
      id: item?.id,
      title: item?.title,
    };
  });

  return (
    <>
      {customerLoading && (
        <ForegroundPaper
          sx={{
            width: "100%",
          }}
        >
          <Stack
            direction={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            width={"100%"}
            height={120}
          >
            <CircularProgress color="inherit" />
          </Stack>
        </ForegroundPaper>
      )}
      {!customerLoading && (
        <PageContainer
          title={"Contract Relation"}
          icon={<FaFileContract color={`#FFFFFF`} />}
          color={blue[500]}
        >
          <Grid
            p={2}
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <MultipleSelect
                label={"Customer list"}
                AllCustomersList={customers}
                setSelectedId={setSelectedId}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomSelect
                value={customerContractList?.title}
                label={"Contract title"}
                options={customerContract}
                onChange={(e, contract) => setCustomerContractList(contract)}
              />
            </Grid>

            <Grid item xs={6}>
              <CustomInput
                value={priceValue}
                type="text"
                label="Contract price"
                onChange={(e) => setPriceValue(() => e?.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomButton sx={{
                width:"100%",
                height:"100%"
              }} loading={loading} label="create relation" onClick={confirmRelation} />
            </Grid>
          </Grid>
        </PageContainer>
      )}
    </>
  );
};

export default ContractRelation;
