import { Paper, lighten } from "@mui/material";
import React from "react";
import { getContrastedColor } from "../../utils/getContrastedColor";

export default function ForegroundPaper(props) {
  return (
    <Paper
      {...props}
      sx={{
        transformOrigin: "top",
        overflow: "hidden",
        borderRadius: (theme) => theme.settings.foreground.borderRadius - 1,
        bgcolor: (theme) => theme.palette.foreground.main,
        color: (theme) => getContrastedColor(theme.palette.foreground.main),
        ".Mui-disabled": {
          color: (theme) =>
            getContrastedColor(theme.palette.foreground.main) + "! important",
          opacity: 0.5,
        },
        ".Mui-disabled fieldset": {
          opacity: 0.5,
        },

        ".MuiOutlinedInput-notchedOutline": {
          borderColor: (theme) =>
            getContrastedColor(theme.palette.foreground.main) + " !important",
        },
        ".Mui-focused": {
          color: (theme) => theme.palette.secondary.main + " !important",

          fieldset: {
            borderColor: (theme) =>
              theme.palette.secondary.main + " !important",
          },
        },
        ...props.sx,
      }}
      variant={"outlined"}
    >
      {props.children}
    </Paper>
  );
}
