import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import PageContainer from "../../../components/@common/PageContainer";
import useFetch from "../../../hooks/useFetch";
import { Chip, Stack, capitalize } from "@mui/material";
import moment from "moment";
import { Subject } from "@mui/icons-material";
import CustomTable from "../../../components/@common/CustomTable";
import { FiActivity } from "react-icons/fi";

function createData(id, name, calories, fat, carbs, protein) {
  return {
    id,
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const rows = [
  createData(1, "Cupcake", 305, 3.7, 67, 4.3),
  createData(2, "Donut", 452, 25.0, 51, 4.9),
  createData(3, "Eclair", 262, 16.0, 24, 6.0),
  createData(4, "Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData(5, "Gingerbread", 356, 16.0, 49, 3.9),
  createData(6, "Honeycomb", 408, 3.2, 87, 6.5),
  createData(7, "Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData(8, "Jelly Bean", 375, 0.0, 94, 0.0),
  createData(9, "KitKat", 518, 26.0, 65, 7.0),
  createData(10, "Lollipop", 392, 0.2, 98, 0.0),
  createData(11, "Marshmallow", 318, 0, 81, 2.0),
  createData(12, "Nougat", 360, 19.0, 9, 37.0),
  createData(13, "Oreo", 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const headCells = [
  {
    id: "id",
    label: "Id",
  },
  {
    id: "service_name",
    label: "Service",
  },
  {
    id: "operator",
    label: "By",
  },
  {
    id: "action_type",
    label: "Action type",
  },
  {
    id: "action",
    label: "Action",
  },
  {
    id: "date",
    label: "Action time",
  },
];

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,

    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function Logs() {
  const { data: logsView, loading } = useFetch(
    `/api/managers/logs/view?service_name=warehouse`
  );

  const serializedLogs = logsView?.data
    ?.sort((a, b) => new Date(b.date) - new Date(a.date))
    .map((log) => {
      const action_switch = (action, action_type) => {
        switch (action) {
          case "change":
            return (
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <Box
                  sx={{
                    position: "relative",
                    width: 10,
                    height: 10,
                    borderRadius: "50%",
                    bgcolor: (theme) => theme.palette.info.main,
                  }}
                ></Box>
                <Typography variant="button" noWrap>
                  {capitalize("update")}
                </Typography>
              </Stack>
            );

          case "add":
            return (
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <Box
                  sx={{
                    position: "relative",
                    width: 10,
                    height: 10,
                    borderRadius: "50%",
                    bgcolor: (theme) => theme.palette.success.main,
                  }}
                ></Box>
                <Typography variant="button" noWrap>
                  {capitalize(String(action))}
                </Typography>
              </Stack>
            );

          case "remove":
            return (
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <Box
                  sx={{
                    position: "relative",
                    width: 10,
                    height: 10,
                    borderRadius: "50%",
                    bgcolor: (theme) => theme.palette.error.main,
                  }}
                ></Box>
                <Typography variant="button" noWrap>
                  {capitalize(String(action))}
                </Typography>
              </Stack>
            );

          default:
            return;
        }
      };
      return {
        id: log.id,
        "service name": log.service_name,
        operator: log.operator,
        "action type": (
          <Chip
            label={capitalize(String(log.action_type))}
            sx={{
              background: "transparent",
            }}
          />
        ),
        action: action_switch(log.action),
        "action time": moment(log.date).calendar(),
      };
    });

  return (
    <PageContainer title={"Logs"} icon={<FiActivity size={22} />}>
      <Box
        sx={{
          width: "100%",
          // height: "content-fit",
        }}
      >
        <CustomTable
          hideselect
          maxHeight={"70vh"}
          options={{ rows: serializedLogs, loading }}
          defaultRowsPerPage={50}
          defaultRowsPerPageOptions={[50, 100, 200]}
        />
      </Box>
    </PageContainer>
  );
}
