import {
  Badge,
  Box,
  CircularProgress,
  Grid,
  Stack,
  Typography,
  darken,
  lighten,
} from "@mui/material";

import { useState } from "react";
import { CalendarMonth } from "@mui/icons-material";
import CustomButton from "../../../components/@common/CustomButton";
import CustomSelect from "../../../components/@common/CustomSelect";
import { PickersDay } from "@mui/x-date-pickers";
import PageContainer from "../../../components/@common/PageContainer";
import Label from "../../../components/@common/Label";
import CustomTable from "../../../components/@common/CustomTable";
import useFetch from "../../../hooks/useFetch";
import moment from "moment";
import useForm from "../../../hooks/useForm";
import _ from "lodash";
import CustomTableDays from "../../../components/@common/CustomTableDays";
import { useModal } from "../../../hooks/useModal";
import CustomDatePicker from "../../../components/@common/CustomDatePicker";
import useCrud from "../../../hooks/useCrud";
import CustomInput from "../../../components/@common/CustomInput";
import ConfirmModal from "../../../components/@common/ConfirmModal";
import { useNotification } from "../../../hooks/useNotification";
import { green } from "@mui/material/colors";
import { getRandomNumber } from "../../../utils/getRandomNumber";
import SingleCustomerModal from "./SingleCustomerModal";
import FinalVisitsTable from "./FinalVisitsTable";
import FinalizeTable from "./FinalizeTable";
import CustomerList from "./customer_list_table/CustomerList";
import ForegroundPaper from "../../../components/@common/ForegroundPaper";

const TripManagement = () => {
  const { setModal } = useModal();
  const { setNotification } = useNotification();
  const { crud, loading: loadingCrud } = useCrud();
  const { setValues, values, handleInput } = useForm({
    driver: { id: "", title: "" },
    visit_date: new Date(),
  });

  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDays, setLoadingDays] = useState(false);
  const [fromDays, setFromDays] = useState(0);
  const [toDays, setToDays] = useState(0);
  const [daysBetween, setDaysBetween] = useState([]);
  const [groupedByZipcode, setGroupedByZipcode] = useState([]);
  const [finalAllCustomers, setFinalAllCustomers] = useState([]);
  const [finalProposedCustomers, setFinalProposedCustomers] = useState([]);
  const [finalCustomers, setFinalCustomers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [sortedCustomers, setSortedCustomers] = useState([]);
  const [allCustomers, setAllCustomers] = useState([]);
  const [seed, setSeed] = useState(Math.random());
  const [selectedCustomersTableSeed, setSelectedCustomersTableSeed] = useState(
    Math.random()
  );
  const [filterdCustomers, setFilteredCustomers] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [AllCustomers, setAllCustomer] = useState([]);
  const [sortedList, setSortedList] = useState([]);

  const [customersTableSeed, setCustomersTableSeed] = useState(Math.random());
  const [finalTableSeed, setFinalTableSeed] = useState(Math.random());
  const clearForm = () => {
    setSeed(Math.random());
    setFromDays(0);
    setToDays(0);
    setDaysBetween([]);
    setGroupedByZipcode([]);
    setFinalAllCustomers([]);
    setFinalProposedCustomers([]);
    setFinalCustomers([]);
    setCustomers([]);
    setCustomer([]);
    setSortedCustomers([]);
    setAllCustomers([]);
    setFilteredCustomer([]);
    setSortedList([]);
    setSelectedItem([]);
    setValues({
      driver: { id: "", title: "" },
      visit_date: new Date(),
    });
  };

  const { data: agents } = useFetch("/api/managers/agents");
  const { data: savedTrips } = useFetch("/api/managers/tripplanner");

  const [filterdCustomer, setFilteredCustomer] = useState([]);

  const handleCheckboxChange = (id) => {
    if (selectedItem.includes(id.id)) {
      setSelectedItem(selectedItem.filter((itemId) => itemId !== id.id));
      setSortedList(selectedItem?.filter((item) => item?.id !== id.id));
    } else {
      setSelectedItem([...selectedItem, id.id]);
      setSortedList([...sortedList, id]);
    }
  };

  const getSavedData = async (date) => {
    setLoading(true);
    try {
      const response = await crud.get(
        `/api/managers/tripplanner/saved?date=${date}&driver_id=${values?.driver?.id}`
      );

      const sortedResponse = response.data.map((d) => d.customer_info);

      setLoading(false);
      setSelectedItem(sortedResponse?.map((i) => i.id));
      setSortedList(_.uniqBy(sortedResponse, "id"));

      return response.data;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getDays = async () => {
    const visit_date = values?.visit_date;
    setLoadingDays(true);
    const response = await crud.get(
      `/api/managers/tripplanner/between?from=${fromDays}&to=${toDays}&date=${visit_date}`
    );

    if (response.result === "false") {
      setLoadingDays(false);
      return;
    }

    const customersDataBetweenDays = response?.data?.map((item) => {
      return item.customer
        ? {
            id: item.id,
            CN: item.customer ? item.customer?.customer_number : null,
            status: item.status,
            visit_date: moment(item.visit_date).format("YYYY/MM/DD"),
            city: item.customer?.city ? item.customer?.city : null,
            zipcode: item.customer?.zipcode ? item.customer?.zipcode : null,
            customer: item.customer ? item.customer : null,
          }
        : null;
    });

    setGroupedByZipcode(
      groupByZipCode(
        customersDataBetweenDays?.filter((item) => item !== null)
      ).map((item) => {
        if (item) {
          return {
            id: item[0],
            CN: item[1][0].CN,
            zipcode: item[0],
            city: item[1][0]?.city,
            "last visit": item[1][0]?.visit_date,
            cutomers: item[1]?.length,
          };
        }
      })
    );

    setDaysBetween(customersDataBetweenDays);

    setLoadingDays(false);
  };

  const serializedDrivers = agents?.drivers?.map((item) => {
    return {
      id: item.id,
      title: item.first_name + " " + item.last_name,
    };
  });
  const serializedCustomer = sortedList
    ?.map((customer, i) => {
      return {
        id: customer?.id,
        customer_number: customer?.customer_number,
        "": sortedList?.length[0],
        name: customer?.last_name,
        CoName: customer?.company_name,
        WT: customer?.working_time,
        city: customer?.city,
        ZCode: customer?.zipcode,
        "c-rank": getRandomNumber(1, 10, 2),
        "last visit":
          customer?.customer_visits_history &&
          moment(customer?.customer_visits_history[0]?.visit_date).format(
            "YYYY/MM/DD"
          ),
        " ": (
          <CustomInput
            fullWidth
            value={values.note}
            label={"Additionale note"}
            type="text-a"
            onChange={(e) => {
              sortedList[i].note = e.target.value;
            }}
          />
        ),

        isSelected: true,
      };
    })
    .map((customer) => {
      return { ...customer, isSelected: true };
    });

  const Save = async (data) => {
    setSaving(true);
    try {
      const response = await crud.post("/api/managers/tripplanner/save", data);
      setSaving(false);
      setNotification({
        message: "Saved Successfully",
      });
      return response;
    } catch (error) {
      console.log(error);
      setSaving(false);
    }
  };

  const SavingCallBack = () => {
    let newEntry = {
      driver_id: values.driver.id,
      visit_date: moment(values.visit_date).format("YYYY/MM/DD"),
      customers: sortedList.map((customer, index) => {
        return {
          id: customer?.id,
          sort: index,
          note: customer?.note,
        };
      }),
    };
    Save(newEntry);
    setModal(null);
  };

  const saveModal = () => {
    setModal({
      title: "Final Visit list for save",
      child: (
        <Box p={1}>
          <FinalizeTable
            title={`Finalize (${sortedList?.length})`}
            agentsLength={sortedList?.length}
            outlined
            hideselect
            options={{
              rows: serializedCustomer,
            }}
            onClick={() => {}}
          />
          <Box mt={2} display={"flex"} gap={2} justifyContent={"right"}>
            <CustomButton
              label={"Cancel"}
              onClick={() => setModal(null)}
              variant={"outlined"}
            />
            <CustomButton
              label={"Save"}
              loading={saving}
              onClick={SavingCallBack}
            />
          </Box>
        </Box>
      ),
    });
  };

  return (
    <PageContainer
      title={"Trip Management"}
      color={green[500]}
      icon={<CalendarMonth />}
      actions={
        <>
          <CustomButton
            disabled={
              [
                ...daysBetween,
                ...groupedByZipcode,
                ...finalAllCustomers,
                ...finalProposedCustomers,
                ...finalCustomers,
                ...customers,
                ...customer,
                ...sortedCustomers,
                ...sortedList,
                ...selectedItem,
              ].length <= 1
            }
            label={"Clear"}
            variant={"text"}
            color={"inherit"}
            onClick={() =>
              setModal({
                title: "Clear trip management form",
                child: (
                  <ConfirmModal
                    onClose={() => setModal(null)}
                    onConfirm={() => {
                      clearForm();
                      setModal(null);
                      return;
                    }}
                  />
                ),
              })
            }
          />
          <CustomButton
            disabled={sortedList.length < 1}
            label={"Save"}
            variant={"outlined"}
            onClick={() => saveModal()}
          />
        </>
      }
    >
      <Grid container spacing={2} key={seed}>
        <Grid item xs={12} lg={4} xl={4}>
          <Box
            height={"100vh"}
            sx={{
              p: 1,
              borderRadius: (theme) => theme.settings.borderRadius,
              bgcolor: (theme) =>
                theme.palette.mode === "light"
                  ? darken(theme.palette.foreground.main, 0.05)
                  : darken(theme.palette.foreground.main, 0.25),
            }}
          >
            <Stack spacing={2} alignItems={"stretch"}>
              <Label sx={{ pt: 1 }}>1. Pick agent</Label>
              <CustomSelect
                label={"List of agents"}
                options={serializedDrivers}
                value={values.driver?.title}
                onChange={(e, driver) =>
                  setValues({ ...values, driver: driver })
                }
              />
              <Stack spacing={2} alignItems={"stretch"}>
                <Label>2. Pick a date</Label>
                <CustomDatePicker
                  defaultValue={values.visit_date}
                  value={new Date(values.visit_date)}
                  sx={{ input: { p: 2 } }}
                  slots={{
                    day: (props) => {
                      return (
                        <Badge
                          key={props?.day?.toString()}
                          overlap="circular"
                          variant={"dot"}
                          color="secondary"
                          invisible={
                            !savedTrips?.data?.find((date) => {
                              return moment(date)?.isSame(props?.day);
                            })
                          }
                        >
                          <PickersDay
                            {...props}
                            onDaySelect={(d) => {
                              const isReserved = savedTrips.data.find(
                                (date) => {
                                  return moment(date).isSame(d);
                                }
                              );
                              if (isReserved) {
                                getSavedData(moment(d).format("YYYY/MM/DD"));
                              } else {
                                setSortedList([]);
                                setSelectedItem([]);
                              }
                              setValues((prevValues) => ({
                                ...prevValues,
                                visit_date: moment(d).format("YYYY/MM/DD"),
                              }));
                            }}
                          />
                        </Badge>
                      );
                    },
                  }}
                />

                <Label>3. Choose intervals</Label>
                <Stack
                  direction={"row"}
                  spacing={1}
                  mb={4}
                  alignItems={"stretch"}
                >
                  <CustomInput
                    label={"From"}
                    value={fromDays}
                    onChange={(e) => setFromDays(e.target.value)}
                    type={"number"}
                  />
                  <CustomInput
                    label={"To"}
                    value={toDays}
                    onChange={(e) => setToDays(e.target.value)}
                    type={"number"}
                  />
                  <CustomButton
                    disabled={Number(fromDays) < 1 || Number(toDays) < 2}
                    fullWidth
                    sx={{
                      height: 56,
                    }}
                    variant={"text"}
                    loading={loadingDays}
                    label={"Get list"}
                    onClick={() => getDays()}
                  />
                </Stack>
              </Stack>
              <Stack
                alignItems={"center"}
                direction={"row"}
                justifyContent={"space-between"}
                sx={{ pt: 1, px: 1 }}
              >
                <Typography variant="subtitle2" fontWeight={700}>
                  {`4. Choose between the list (${groupedByZipcode.length}) `}
                </Typography>
              </Stack>
              <DaysTable
                days={groupedByZipcode}
                onSelect={(id) => {
                  if (id?.length >= 1) {
                    let customer = _.uniqBy(
                      _.flatten(
                        id.map((item) => {
                          let arr = daysBetween
                            ?.filter((day) => day !== null)
                            .map((d) => {
                              return {
                                ...d.customer,
                                last_visit: d.visit_date,
                              };
                            })
                            .filter((v) => v.zipcode === item.zipcode);
                          return arr;
                        })
                      ),
                      "id"
                    );
                    setCustomer(customer);
                    setFinalCustomers([]);
                    setFinalProposedCustomers([]);
                    setFinalAllCustomers([]);
                    setSelectedCustomersTableSeed(Math.random());
                    setCustomersTableSeed(Math.random());
                    setFinalTableSeed(Math.random());
                  } else {
                    setCustomer([]);
                    setFinalCustomers([]);
                    setFinalAllCustomers([]);
                    setFinalProposedCustomers([]);
                    setSelectedCustomersTableSeed(Math.random());
                    setCustomersTableSeed(Math.random());
                    setFinalTableSeed(Math.random());
                  }
                }}
              />
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={12} lg={4} xl={4}>
          <Box
            key={selectedCustomersTableSeed}
            sx={{
              p: 1,
              borderRadius: (theme) => theme.settings.borderRadius,
              bgcolor: (theme) =>
                theme.palette.mode === "light"
                  ? darken(theme.palette.foreground.main, 0.05)
                  : darken(theme.palette.foreground.main, 0.25),
            }}
          >
            <Stack spacing={2} alignItems={"stretch"}>
              <Stack
                alignItems={"center"}
                direction={"row"}
                justifyContent={"space-between"}
                sx={{ pt: 1, px: 1 }}
              >
                <Typography variant="subtitle2" fontWeight={700}>
                  {`5. Choose proposed visits (${customers.length}) `}
                </Typography>
                <Typography component={"span"} variant="subtitle2">
                  {finalProposedCustomers.length >= 1
                    ? " " + finalProposedCustomers.length + " selected"
                    : ""}
                </Typography>
              </Stack>
              <SelectedCustomersTable
                selectedCustomers={customer}
                onSelect={(id) => {
                  if (id?.length > 0) {
                    setFinalProposedCustomers((prev) => {
                      return [...id];
                    });
                    setFinalCustomers([...finalAllCustomers, ...[...id]]);
                    setCustomersTableSeed(Math.random());
                  } else {
                    setFinalProposedCustomers([]);
                    setFinalCustomers([...finalAllCustomers, ...[...id]]);
                    setCustomersTableSeed(Math.random());
                  }
                }}
                onSelectAll={(id) => {
                  if (id?.length > 0) {
                    setFinalProposedCustomers(id);
                    setFinalCustomers([...finalAllCustomers, ...id]);
                    setCustomersTableSeed(Math.random());
                    setFinalTableSeed(Math.random());
                  } else {
                    setFinalProposedCustomers([]);
                    setFinalCustomers([...finalAllCustomers]);
                    setCustomersTableSeed(Math.random());
                    setFinalTableSeed(Math.random());
                  }
                }}
              />

              <Stack
                alignItems={"center"}
                direction={"row"}
                justifyContent={"space-between"}
                sx={{ pt: 1, px: 1 }}
              >
                <Typography variant="subtitle2" fontWeight={700}>
                  {`6. Choose from all customers (${
                    AllCustomers?.length - customers.length
                  }) `}
                </Typography>
                <Typography component={"span"} variant="subtitle2">
                  {selectedItem.length >= 1
                    ? " " + selectedItem.length + " selected"
                    : ""}
                </Typography>
              </Stack>

              <CustomerList
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                AllCustomers={AllCustomers}
                setAllCustomers={setAllCustomer}
                sortedList={sortedList}
                setSortedList={setSortedList}
                filterdCustomers={filterdCustomer}
                setFilteredCustomers={setFilteredCustomer}
                handleCheckboxChange={handleCheckboxChange}
                onClick={(v) => {
                  setModal({
                    title: `${v.name}`,
                    child: (
                      <SingleCustomerModal
                        customer={AllCustomers.find((c) => c.id === v.id)}
                      />
                    ),
                  });
                }}
              />
              {/* <CustomersTable
                setAllCustomers={setAllCustomers}
                // selectedCustomers={customers}
                _setSelected={setSelected}
                _selected={selected}
                onSelect={(selected_customers) => {
                  if (selected_customers?.length > 0) {
                    setFinalAllCustomers(selected_customers);
                    setFinalCustomers([
                      ...selected_customers,
                      ...finalProposedCustomers,
                    ]);
                    setFinalTableSeed(Math.random());
                  } else {
                    setFinalAllCustomers([]);
                    setFinalCustomers([...finalProposedCustomers]);
                    setFinalTableSeed(Math.random());
                  }
                }}
              /> */}
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={12} lg={8} xl={4}>
          <Box
            sx={{
              p: 1,
              borderRadius: (theme) => theme.settings.borderRadius,
              bgcolor: (theme) =>
                theme.palette.mode === "light"
                  ? darken(theme.palette.foreground.main, 0.05)
                  : darken(theme.palette.foreground.main, 0.25),
            }}
          >
            <Stack spacing={2} alignItems={"stretch"}>
              <Stack
                alignItems={"center"}
                direction={"row"}
                justifyContent={"space-between"}
                sx={{ pt: 1, px: 1 }}
              >
                <Typography variant="subtitle2" fontWeight={700}>
                  {`7. Choose the final visits (${sortedCustomers.length}) `}
                </Typography>
              </Stack>

              <FinalVisitsTable
                filterdCustomers={filterdCustomers}
                setFilteredCustomers={setFilteredCustomers}
                sortedCustomers={sortedList}
                setSortedCustomers={setSortedList}
                onRemove={(id) => {
                  setSortedList(
                    sortedList?.filter((item) => item?.id !== id?.id)
                  );
                  setSelectedItem(
                    selectedItem?.filter((item) => item !== id?.id)
                  );
                  setModal(null);
                }}
                loading={loading}
                setLoading={setLoading}
              />
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

const DaysTable = ({ days, onSelect }) => {
  const { setModal } = useModal();
  return (
    <CustomTableDays
      outlined
      options={{
        rows: days,
      }}
      onSelectAll={onSelect}
      onSelect={onSelect}
      onClick={(v) => {
        setModal({
          title: `${v.name}`,
          child: (
            <SingleCustomerModal customer={days.find((c) => c.id === v.id)} />
          ),
        });
      }}
    />
  );
};

const SelectedCustomersTable = ({
  selectedCustomers,
  onSelectAll,
  onSelect,
}) => {
  const { setModal } = useModal();

  const serializedCustomer = selectedCustomers?.map((customer) => {
    return {
      id: customer.id,
      name: customer.first_name + " " + customer.last_name,
      WT: customer.working_time,
      ZCode: customer.zipcode,
      "c-rank": getRandomNumber(1, 10, 2),
      city: customer.city,
      "last visit": customer?.last_visit,
    };
  });

  return (
    <CustomTable
      outlined
      options={{
        rows: serializedCustomer,
      }}
      onSelect={onSelect}
      onSelectAll={onSelectAll}
      onClick={(v) => {
        setModal({
          title: `${v.name}`,
          child: (
            <SingleCustomerModal
              customer={selectedCustomers.find((c) => c.id === v.id)}
            />
          ),
        });
      }}
    />
  );
};

const groupByZipCode = (data) => {
  const reducedData = data?.reduce((prevValue, currentValue) => {
    (prevValue[currentValue["zipcode"]] =
      prevValue[currentValue["zipcode"]] || []).push(currentValue);
    return prevValue;
  }, {});

  return Object.entries(reducedData);
};

export default TripManagement;
