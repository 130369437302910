import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

export default function SingleCustomerModal({ customer }) {
  return (
    <TableContainer sx={{ p: 2 }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Customer No.
            </TableCell>
            <TableCell align="right">{customer?.customer_number}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Fullname
            </TableCell>
            <TableCell align="right">
              {customer?.first_name} {customer?.last_name}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Company
            </TableCell>
            <TableCell align="right">{customer?.company_name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Mobile
            </TableCell>
            <TableCell align="right">{customer?.mobile}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Phone
            </TableCell>
            <TableCell align="right">{customer?.phone}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Email
            </TableCell>
            <TableCell align="right">{customer?.email}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
