import {
    Box,
    IconButton,
    Paper,
    Stack,
    Typography,
    capitalize,
} from "@mui/material";
import React, { useState } from "react";
import { config } from "../config";
import { styled } from "@mui/material/styles";

import { motion } from "framer-motion";

import CustomInput from "../components/@common/CustomInput";
import CustomButton from "../components/@common/CustomButton";
import { getContrastedColor } from "../utils/getContrastedColor";
import ForegroundPaper from "../components/@common/ForegroundPaper";
import api from "../config/api";
import Cookies from "js-cookie";
import { useNotification } from "../hooks/useNotification";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";

export default function LoginPage({ setIsAuth }) {
    const { setNotification, enqueueSnackbar } = useNotification();
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [credential, setCredential] = useState({
        username: "",
        password: "",
    });

    const login = async () => {
        const { username, password } = credential;
        try {
            setLoading(true);
            const response = await api.post('/api/managers/auth/login', {
                username,
                password
            })
            const { token } = response.data.data;

            localStorage.setItem("userInfo", JSON.stringify(response.data.data));
            Cookies.set("gwmuser", token, { expires: 7 });
            api.defaults.headers.Authorization = `Bearer ${token}`;
            setIsAuth(true);
            enqueueSnackbar("Logged in successfully.", {
                variant: "success",
            });
            setLoading(false);

            return response.data;
        } catch (error) {
            setLoading(false);
            if (error?.response?.data?.message) {
                enqueueSnackbar(capitalize(error.response.data.message), {
                    variant: "error",
                });
            }
            console.log(error);
        }
    };

    const handleCredential = (prop) => (event) => {
        setCredential({ ...credential, [prop]: event.target.value });
    };

    return (
        <Box
            sx={{
                position: "relative",
                minHeight: "100vh",
                width: "100%",
                fontFamily: "IRANSansWeb",
                backgroundColor: (theme) => theme.palette.background.main,
            }}
        >
            <div class="animated-bg"></div>

            <Box
                sx={{
                    position: "relative",
                    zIndex: 1,
                    width: "100%",
                    minHeight: "100vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <LoginContainer
                    initial={{
                        scale: 0.8,
                    }}
                    animate={{
                        scale: 1,
                    }}
                >
                    <ForegroundPaper sx={{ padding: 2, width: "100%" }}>
                        <Stack
                            alignItems={"center"}
                            justifyContent={"center"}
                            spacing={2}
                            my={2}
                        >
                            <img src="logo.png" width={100} />
                            <Typography
                                variant="h5"
                                sx={{
                                    mb: 2,
                                    fontWeight: 700,
                                }}
                            >
                                {config.appName}
                            </Typography>
                        </Stack>

                        <Stack spacing={2}>
                            <CustomInput
                                fullWidth
                                label={"Username"}
                                onChange={handleCredential("username")}
                            />
                            <CustomInput
                                fullWidth
                                label={"Password"}
                                type={showPassword ? "text" : "password"}
                                onChange={handleCredential("password")}
                                endAdornment={
                                    <IconButton
                                        onClick={() => setShowPassword(!showPassword)}
                                        variant="text"
                                        sx={{
                                            minWidth: 0,

                                            ml: 1.5,
                                        }}
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                }
                            />

                            <CustomButton
                                loading={loading}
                                variant="contained"
                                fullWidth
                                label={"Login"}
                                onClick={() => login()}
                            />
                        </Stack>
                    </ForegroundPaper>
                </LoginContainer>
            </Box>
        </Box>
    );
}

const LoginContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.main,
    color: getContrastedColor(theme.palette.background.main),
    borderRadius: theme.settings.foreground.borderRadius,
    transition: "all 0.5s",
    width: "95%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "stretch",
    [theme.breakpoints.up("sm")]: {
        width: 500,
        height: "auto",
    },
}));

const WithPasswordFields = styled(motion.div)(({ theme }) => ({
    margin: theme.spacing(2, 0),
}));