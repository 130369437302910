import {
    Add,
    Assessment,
    Dashboard,
    DateRange,
    DynamicForm,
    ExpandLess,
    ExpandMore,
    Group,
    OilBarrel,
    PersonPin,
    SortByAlphaRounded,
    Subject,
    Warehouse,
    WaterDrop,
} from "@mui/icons-material";

import {
    Avatar,
    Box,
    Collapse,
    Icon,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
    capitalize,
    darken,
    lighten,
} from "@mui/material";

import {motion} from "framer-motion";

import {useState} from "react";

import {Link, useLocation, useNavigate} from "react-router-dom";
import CustomButton from "../@common/CustomButton";
import {useAppTheme} from "../../hooks/useAppTheme";
import {blue, green, orange} from "@mui/material/colors";
import {FiActivity} from "react-icons/fi";
import {useModal} from "../../hooks/useModal";
import Oil from "../../pages/app/Oil";
import {getRandomNumber} from "../../utils/getRandomNumber";
import {FaDoorOpen} from "react-icons/fa";
import { FaFileContract } from "react-icons/fa6";

function createMenuItem(
    id,
    icon,
    label,
    location,
    childs = [],
    color = "transparent",
    onClick = null
) {
    return {
        id,
        icon,
        label,
        location,
        childs,
        color,
        onClick,
    };
}

export default function AppDrawerItems({open}) {
    const {setModal} = useModal();
    const [listItemkey] = useState(getRandomNumber());

    const MENU_ITEMS = [
        createMenuItem(
            "dashboard",
            <Dashboard/>,
            "Dashboard",
            "/app/dashboard",
            []
        ),
        createMenuItem(
            "customer-management",
            <Group/>,
            "Customer Management",
            "/app/customer-management",
            [
                createMenuItem(
                    "customer-management/add",
                    null,
                    "Add or edit cutomers",
                    null,
                    null
                ),
            ],
            blue[500]
        ),
        createMenuItem(
            "agent-management",
            <PersonPin/>,
            "Agent Management",
            "/app/agent-management",
            [
                createMenuItem(
                    "agent-management/add",
                    null,
                    "Add or edit agents",
                    null,
                    null
                ),
            ],
            orange[500]
        ),
        createMenuItem(
            "trip-management",
            <DateRange/>,
            "Trip Management",
            "/app/trip-management",
            [
                createMenuItem(
                    "/app/trip-management",
                    null,
                    "Manage visits",
                    null,
                    null
                ),
            ],
            green[500]
        ),
        createMenuItem(
            "form-management",
            <DynamicForm/>,
            "Form Management",
            "/app/form-management",
            [
                createMenuItem(
                    "/app/form-management",
                    null,
                    "Manage from fields",
                    null,
                    null
                ),
            ],
            blue[500]
        ),
        createMenuItem(
            "warehouse-management",
            <Warehouse/>,
            "Warehouse Management",
            "/app/warehouse-management",
            [
                createMenuItem(
                    "warehouse-management/general",
                    null,
                    "General",
                    "/app/warehouse-management/general",
                    null
                ),
                createMenuItem(
                    "warehouse-management/buckets",
                    null,
                    "Buckets",
                    "/app/warehouse-management/buckets",
                    null
                ),
                createMenuItem(
                    "warehouse-management/oil",
                    <WaterDrop/>,
                    "Oil",
                    "/app/warehouse-management/oil",
                    [],
                    "",
                    () =>
                        setModal({
                            title: "Oil",
                            child: <Oil/>,
                        })
                ),
            ],
            blue[500]
        ),
        createMenuItem(
            "vacations",
            <FaDoorOpen size={22}/>,
            "Vacations",
            "/app/vacations",
            [
                createMenuItem(
                    "/app/vacations",
                    null,
                    "Drivers vacations",
                    null,
                    null
                ),
            ],
            green[500]
        ),
        createMenuItem(
            "Customer Contract",
            <FaFileContract size={22} />,
            "Customer Contract",
            "/app/customer_contract",
            [
                createMenuItem(
                    "/app/customer_contract",
                    null,
                    "Customer Contract",
                    null,
                    null
                ),
            ],
            blue[500]
        ),
        createMenuItem(
            "report-management",
            <Assessment/>,
            "Report Management",
            "/app/report-management",
            [
                createMenuItem(
                    "app/report-management",
                    null,
                    "Get report",
                    null,
                    null
                ),
            ],
            orange[500]
        ),
        createMenuItem(
            "logs",
            <FiActivity size={22}/>,
            "Logs",
            "/app/logs",
            [
                createMenuItem(
                    "app/logs",
                    null,
                    "System logs",
                    null,
                    null
                ),
            ]
        ),
    ];

    const location = useLocation();

    const [isSelectedParent, setIsSelectedParent] = useState(location.toString());
    const [isSelectedChild, setIsSelectedChild] = useState(location.toString());

    return (
        <List sx={{py: 2}}>
            {MENU_ITEMS &&
                MENU_ITEMS.map((item, i) => (
                    <MainListItem
                        key={listItemkey + i}
                        setIsSelectedMenuItem={setIsSelectedParent}
                        isDrawerOpen={open}
                        isSelected={isSelectedParent}
                        listItem={item}
                        sx={{
                            ...(open ? {pl: 3, my: 0.5, mx: 1} : {mx: 0}),
                            borderRadius: (theme) => theme.settings.foreground.borderRadius,
                        }}
                    />
                ))}
        </List>
    );
}

function MainListItem({listItem, isDrawerOpen}) {
    const location = useLocation();
    const navigate = useNavigate();

    const handleListButton = (item, e) => {
        if (e.target.href === undefined) {
            return navigate(item.location);
        } else {
            return navigate(e.target.href.replace(/^.*\/\/[^\/]+/, ""));
        }
    };

    return (
        <ListItem
            key={listItem.id}
            onClick={(e) => handleListButton(listItem, e)}
            sx={{
                cursor: "pointer",
                px: isDrawerOpen ? 2.5 : 1,
                py: 1,
                mx: 1,
                borderRadius: 2,
                ":hover": {
                    bgcolor: (theme) => lighten(theme.palette.primary.main, 0.2),
                },
            }}
        >
            <ListItemIcon
                sx={{
                    mx: 0,
                    color: (theme) =>
                        listItem.color !== "transparent"
                            ? theme.palette.primary.main
                            : theme.palette.primary.contrastText,
                }}
            >
                <motion.div
                    style={{
                        backgroundColor: listItem.color,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "50%",
                        padding: 4,
                    }}
                    component={motion.div}
                    initial={{
                        opacity: 0,
                    }}
                    animate={{
                        opacity: 1,
                        transition: {
                            delay: 0.5,
                        },
                    }}
                    exit={{
                        scale: 0,
                        opacity: 0,
                    }}
                >
                    {listItem.icon}
                </motion.div>
            </ListItemIcon>
            {isDrawerOpen && (
                <ListItemText
                    sx={{
                        color: (theme) => {
                            if (location === listItem.location) {
                                return theme.palette.tertiary.main + " !important";
                            } else {
                                return theme.palette.primary.contrastText + " !important";
                            }
                        },
                    }}
                    primary={
                        <motion.div
                            component={motion.div}
                            initial={{
                                opacity: 0,
                            }}
                            animate={{
                                opacity: 1,
                                transition: {
                                    delay: 0.5,
                                },
                            }}
                            exit={{
                                opacity: 0,
                            }}
                        >
                            <Typography
                                sx={{
                                    a: {
                                        textDecoration: "none",

                                        color: (theme) =>
                                            location.pathname === listItem.location
                                                ? theme.palette.secondary.main
                                                : theme.palette.primary.contrastText + " !important",
                                    },
                                }}
                            >
                                <Link to={listItem.location}>{listItem.label}</Link>
                            </Typography>
                        </motion.div>
                    }
                    secondary={
                        <motion.div
                            component={motion.div}
                            initial={{
                                opacity: 0,
                            }}
                            animate={{
                                opacity: 1,
                                transition: {
                                    delay: 0.5,
                                },
                            }}
                            exit={{
                                opacity: 0,
                            }}
                        >
                            <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                {listItem.childs.map((child, i) => {
                                    return (
                                        <>
                                            <Typography
                                                key={getRandomNumber()}
                                                sx={{
                                                    fontSize: 13,
                                                    opacity:
                                                        location.pathname === child.location ? 0.8 : 0.7,
                                                    a: {
                                                        textDecoration: "none",
                                                        ":hover": {
                                                            textDecoration: "underline",
                                                        },
                                                        color: (theme) =>
                                                            location.pathname === child.location
                                                                ? theme.palette.secondary.main
                                                                : theme.palette.primary.contrastText +
                                                                " !important",
                                                    },
                                                }}
                                            >
                                                {child.onClick ? (
                                                    <Link onClick={child.onClick}>{child.label}</Link>
                                                ) : child.location ? (
                                                    <Link to={child.location}>{child.label}</Link>
                                                ) : (
                                                    <Typography
                                                        sx={{
                                                            fontSize: 13,
                                                        }}
                                                    >
                                                        {capitalize(child.label)}
                                                    </Typography>
                                                )}
                                            </Typography>
                                            {listItem.childs.length > 1 &&
                                                i !== listItem.childs.length - 1 && (
                                                    <Typography
                                                        key={getRandomNumber()}
                                                        sx={{
                                                            opacity: 0.3,
                                                            color: (theme) =>
                                                                theme.palette.primary.contrastText,
                                                        }}
                                                    >
                                                        |
                                                    </Typography>
                                                )}
                                        </>
                                    );
                                })}
                            </Stack>
                        </motion.div>
                    }
                />
            )}
        </ListItem>
    );
}
