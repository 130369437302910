import { Stack, Toolbar, Typography } from "@mui/material";
import { getContrastedColor } from "../../../../utils/getContrastedColor";

const CustomTableToolbar = (props) => {
    const { numSelected, title, action } = props;
  
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          bgcolor: (theme) => theme.palette.foreground.main,
          color: (theme) => getContrastedColor(theme.palette.foreground.main),
          ...(numSelected > 0 && {}),
        }}
      >
        <Stack
          direction={"row"}
          width={"100%"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          {numSelected > 0 ? (
            <Typography
              sx={{ flex: "1 1 100%" }}
              variant="subtitle1"
              component="div"
            >
              {capitalize(title ? title : "")} - {numSelected}
              {capitalize(numSelected > 1 ? " items selected" : " item selected")}
            </Typography>
          ) : (
            <Typography
              sx={{ flex: "1 1 100%" }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              {capitalize(title ? title : "")}
            </Typography>
          )}
          {action && (
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              {action}
            </Stack>
          )}
        </Stack>
      </Toolbar>
    );
  };

  export default CustomTableToolbar