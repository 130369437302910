import { createContext, useContext } from "react";
import { GlobalStyles } from "@mui/material";

import { useAppTheme } from "./useAppTheme";
import {
  getContrastedColor,
  getContrastedColorOriginal,
} from "../utils/getContrastedColor";

const GlobalStyleContext = createContext({});

export const GlobalStyleProvider = ({ children }) => {
  const { appTheme } = useAppTheme();

  const style = (
    <GlobalStyles
      styles={{
        ".Mui-checked": {
          ".MuiSvgIcon-root": {
            color: `${getContrastedColorOriginal(
              appTheme.palette.secondary.main
            )} !important`,
          },
        },
        ".MuiPickersPopper-root,.MuiSnackbar-root": {
          ".MuiPaper-root": {
            backgroundColor: `${appTheme.palette.foreground.main} `,
            color: `${getContrastedColor(appTheme.palette.foreground.main)}`,
          },
        },
        ".MuiAutocomplete-popper,.MuiPopover-root": {
          ".MuiPaper-root": {
            backgroundColor: `${appTheme.palette.foreground.main} `,
            color: `${getContrastedColor(appTheme.palette.foreground.main)}`,
          },
          ".MuiAutocomplete-noOptions": {
            color: `${getContrastedColor(appTheme.palette.foreground.main)}`,
          },
        },

        ".MuiPaper-root": {
          label: {
            color: getContrastedColor(appTheme.palette.foreground.main),
          },
          input: {
            color: getContrastedColor(appTheme.palette.foreground.main),
          },
          fieldset: {
            color: getContrastedColor(appTheme.palette.foreground.main),
            borderRadius: appTheme.settings.foreground.borderRadius + 6,
          },
          textarea: {
            color: getContrastedColor(appTheme.palette.foreground.main),
            borderRadius: appTheme.settings.foreground.borderRadius + 6,
          },
          ".MuiDayCalendar-weekDayLabel": {
            color: `${getContrastedColor(appTheme.palette.foreground.main)} `,
          },
          ">.MuiSvgIcon-root": {
            color: `${getContrastedColor(appTheme.palette.foreground.main)} `,
          },
          ".MuiPickersDay-root": {
            color: `${getContrastedColor(appTheme.palette.foreground.main)} `,
          },
          ".MuiTablePagination-root": {
            color: `${getContrastedColor(appTheme.palette.foreground.main)} `,
          },
          ".MuiTableSortLabel-root": {
            color: `${getContrastedColor(appTheme.palette.foreground.main)} `,
            ":hover": {
              color: `${getContrastedColor(appTheme.palette.foreground.main)} `,
            },
          },

          ".MuiCheckbox-root": {
            color: `${getContrastedColor(appTheme.palette.foreground.main)} `,
            ":hover": {
              color: `${getContrastedColor(appTheme.palette.foreground.main)} `,
            },
          },
          ".MuiPickersDay-root.Mui-selected": {
            color: `${getContrastedColor(appTheme.palette.primary.main)} `,
          },
          ".Mui-active": {
            color: getContrastedColor(appTheme.palette.foreground.main),
          },
          ".MuiButtonBase-root.Mui-active .MuiSvgIcon-root": {
            color: getContrastedColor(appTheme.palette.foreground.main),
          },
          ".MuiSvgIcon-root": {
            color: getContrastedColor(appTheme.palette.foreground.main),

            ".Mui-active.MuiSvgIcon-root": {
              color: getContrastedColor(appTheme.palette.foreground.main),
            },
          },
          ".MuiButton-root .MuiSvgIcon-root": {
            color: "inherit",
          },
        },
      }}
    />
  );

  return (
    <GlobalStyleContext.Provider value={{ style }}>
      {style}
      {children}
    </GlobalStyleContext.Provider>
  );
};

export const useGlobalStyle = () => useContext(GlobalStyleContext);
