import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  OverlayView,
  OVERLAY_MOUSE_TARGET,
  OverlayViewF,
} from "@react-google-maps/api";
import { useState } from "react";
import { useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import ForegroundPaper from "./ForegroundPaper";
import { Box } from "@mui/material";

export default function CustomMap({ onChange, lat, lng, customMarkers }) {
  const [showDetail, setShowDetail] = useState(null);
  const [defaultProps, setDefaultProps] = useState({
    center: {
      lat: 38.075488,
      lng: 46.288423,
    },
    zoom: 11,
  });
  const [markers, setMarkers] = useState(
    customMarkers
      ? customMarkers
      : [
          {
            lat: lat ? lat : 38.075488,
            lng: lng ? lng : 46.288423,
          },
        ]
  );

  function addMarker(e) {
    const newPlace = {
      lat: e.latLng?.lat(),
      lng: e.latLng?.lng(),
      zoom: 10,
    };

    setDefaultProps({
      ...defaultProps,
      center: { lat: e.latLng?.lat(), lng: e.latLng?.lng() },
    });

    setMarkers([newPlace]);

    onChange({ lat: e.latLng?.lat(), lng: e.latLng?.lng() });
  }

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDmCG19kIRxejJ1dvHoKyUrBwZ_zZRFH00",
  });

  const [map, setMap] = useState(null);

  const onLoad = useCallback(function callback(map) {
    if (customMarkers?.length > 0) {
      let bounds = new window.google.maps.LatLngBounds();
      customMarkers.forEach((item) => {
        bounds.extend({ lat: item.lat, lng: item.lng });
      });
      bounds.getCenter();
      map.fitBounds(bounds);
      setMap(map);
    } else if (lat && lng) {
    } else {
      let bounds = new window.google.maps.LatLngBounds(defaultProps.center);
    }
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        width: "100%",
        height: "50vh",
      }}
      center={!customMarkers && lat ? { lat, lng } : defaultProps.center}
      zoom={defaultProps.zoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onClick={!customMarkers && addMarker}
    >
      {customMarkers?.length > 0
        ? customMarkers?.map((place) => {
            return (
              <>
                <OverlayView
                  position={{
                    lat: place.lat,
                    lng: place.lng,
                  }}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  getPixelPositionOffset={getPixelPositionOffset}
                >
                  <div>
                    <Marker
                      icon={
                        place.isSelected &&
                        "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
                      }
                      key={place.lat}
                      onClick={(e) => {
                        if (place.lat === showDetail) {
                          setShowDetail(null);
                          return;
                        }
                        setShowDetail(place.lat);
                      }}
                      position={{
                        lat: place.lat,
                        lng: place.lng,
                      }}
                    />
                    {showDetail === place.lat && (
                      <Box
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.foreground.main,
                          borderRadius: (theme) =>
                            theme.settings.foreground.borderRadius,
                          width: 120,
                          height: 60,
                          padding: 1,
                          fontSize: 12,
                        }}
                      >
                        {place.name}
                      </Box>
                    )}
                  </div>
                </OverlayView>
              </>
            );
          })
        : markers?.map((place) => {
            return (
              <Marker
                key={place.lat}
                onClick={(e) => {
                  setMarkers([]);
                }}
                position={{
                  lat: place.lat,
                  lng: place.lng,
                }}
              />
            );
          })}
    </GoogleMap>
  ) : (
    <></>
  );
}

const getPixelPositionOffset = (width, height) => ({
  x: -(width / 2),
  y: -(height / 2),
});
