import React, { useEffect, useRef, useState } from "react";
import CustomInput from "../../components/@common/CustomInput";
import CustomSelect from "../../components/@common/CustomSelect";
import CustomButton from "../../components/@common/CustomButton";
import { Stack, Typography } from "@mui/material";
import useFetch from "../../hooks/useFetch";
import { useAppTheme } from "../../hooks/useAppTheme";
import { color } from "d3-color";
import { getContrastedColor } from "../../utils/getContrastedColor";
import { motion, animate, AnimatePresence } from "framer-motion";
import useForm from "../../hooks/useForm";
import { ChevronLeft, Close } from "@mui/icons-material";
import api from "../../config/api";
import { useNotification } from "../../hooks/useNotification";
import Cookies from "js-cookie";
import useCrud from "../../hooks/useCrud";
import { mutate } from "swr";
import commafy from "../../utils/commafy";

const initialValues = {
  amount: 0,
  note: "",
};

function Oil() {
  const { crud, loading: updatingData } = useCrud();
  const { values, setValues } = useForm(initialValues);
  const [editMode, setEditMode] = useState(false);
  const { data: totalOil, loading: loadingTotalOil } = useFetch(
    "/api/managers/warehouse/total_oil"
  );

  const update = () =>
    crud
      .post("/api/managers/warehouse/total_oil/add", values, "Updated")
      .then(() => mutate("/api/managers/warehouse/total_oil"));

  useEffect(() => {
    if (totalOil && !loadingTotalOil)
      setValues({
        amount: totalOil?.data?.amount,
        note: "",
      });

    return () => {
      setValues(initialValues);
    };
  }, []);

  return (
    <>
      <Stack
        sx={{ p: 2, transformOrigin: "top" }}
        spacing={2}
        direction={"row"}
      >
        {!loadingTotalOil && totalOil && (
          <>
            <Stack
              sx={{
                width: "100%",
              }}
            >
              <OilCounter from={0} to={parseInt(totalOil?.data?.amount)} />

              <Typography variant="subtitle">
                <Stack component={"span"}>
                  <span style={{ opacity: 0.7 }}>Last update</span>{" "}
                  <span
                    style={{
                      fontFamily: "Unbounded",
                    }}
                  >
                    {totalOil?.data?.update}
                  </span>
                </Stack>
              </Typography>
            </Stack>

            <CustomButton
              variant={"text"}
              color={editMode ? "inherit" : "secondary"}
              label={
                editMode ? (
                  <ChevronLeft
                    sx={{
                      rotate: "90deg",
                    }}
                  />
                ) : (
                  "Edit"
                )
              }
              onClick={() => setEditMode(!editMode)}
              sx={{
                alignSelf: "end",
              }}
            />
          </>
        )}
      </Stack>
      <AnimatePresence>
        {editMode && (
          <motion.div layout>
            <Stack sx={{ p: 2, maxWidth: 400 }} spacing={2}>
              <CustomInput
                autoFocus
                value={values?.amount}
                type={"number"}
                label={"Amount"}
                onChange={(e) =>
                  setValues((prevValues) => ({
                    ...prevValues,
                    amount: e.target.value,
                  }))
                }
              />

              <CustomInput
                value={values.note}
                multiline
                rows={2}
                label={"Description"}
                onChange={(e) =>
                  setValues((prevValues) => ({
                    ...prevValues,
                    note: e.target.value,
                  }))
                }
              />
              <CustomButton
                disabled={updatingData}
                loading={updatingData}
                label={"Update"}
                onClick={update}
              />
            </Stack>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

export default Oil;

function OilCounter({ from, to }) {
  const nodeRef = useRef();

  useEffect(() => {
    const node = nodeRef.current;

    const controls = animate(from, to, {
      duration: 1,
      onUpdate(value) {
        node.textContent = commafy(value.toFixed());
      },
    });

    return () => controls.stop();
  }, [from, to]);

  return (
    <Stack flex={1}>
      <Typography fontFamily={"Unbounded"} variant="h6">
        Total oil
      </Typography>
      <Typography fontSize={32} fontFamily={"Unbounded"} ref={nodeRef} />
    </Stack>
  );
}
