import {
  Avatar,
  Box,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Input,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
  darken,
  lighten,
} from "@mui/material";

import { useState } from "react";
import {
  ArrowDownwardOutlined,
  ArrowUpward,
  ArrowUpwardOutlined,
  AttachFile,
  CalendarMonth,
  Chat,
  Map,
  Search,
  Send,
} from "@mui/icons-material";
import CustomButton from "../../../components/@common/CustomButton";
import CustomSelect from "../../../components/@common/CustomSelect";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import PageContainer from "../../../components/@common/PageContainer";
import Label from "../../../components/@common/Label";
import CustomTable from "../../../components/@common/CustomTable";
import { useEffect } from "react";
import useFetch from "../../../hooks/useFetch";
import moment from "moment";
import useForm from "../../../hooks/useForm";
import api from "../../../config/api";
import Cookies from "js-cookie";
import { mutate } from "swr";
import _ from "lodash";
import CustomTableDays from "../../../components/@common/CustomTableDays";
import { useModal } from "../../../hooks/useModal";
import CustomMap from "../../../components/@common/CustomMap";
import CustomInput from "../../../components/@common/CustomInput";
import { getContrastedColor } from "../../../utils/getContrastedColor";

export default function ChatView() {
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [agentLocation, setAgentLocation] = useState(null);
  const [loading, setLoading] = useState(false);

  const { data: agents } = useFetch("/api/managers/agents");

  const getDriverLocation = async (id) => {
    setLoading(true);
    try {
      const response = await api.get(
        `/api/managers/livelocation?driver_id=${id}`,
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("gwmuser"),
          },
        }
      );

      setAgentLocation(response.data.data);
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const getDriverLocationBackground = async (id) => {
    try {
      const response = await api.get(
        `/api/managers/livelocation?driver_id=${id}`,
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("gwmuser"),
          },
        }
      );

      setAgentLocation(response.data.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const serializedDrivers = agents?.drivers?.map((item) => {
    return {
      id: item.id,
      title: item.first_name + " " + item.last_name,
    };
  });

  useEffect(() => {
    let interval;
    if (agentLocation) {
      interval = setInterval(() => {
        getDriverLocationBackground(selectedAgent?.id);
      }, 10000);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [agentLocation]);

  return (
    <PageContainer title={"Chat"} icon={<Chat />}>
      <Grid container xs={12} spacing={2} sx={{ width: "100%" }}>
        <Grid item xs={3}>
          <People />
        </Grid>
        <Grid item xs={9}>
          <Box
            sx={{ maxHeight: "70vh", overflow: "hidden", position: "relative" }}
          >
            <ConversationUser />
            <Box sx={{ overflowY: "auto", maxHeight: "70vh" }}>
              <Conversations />
              <Conversations />
              <Conversations />
            </Box>
          </Box>
          <NewMessage />
        </Grid>
      </Grid>
    </PageContainer>
  );
}

function People() {
  return (
    <Box>
      <CustomInput label={"Search"} />
      <Divider sx={{ mt: 2 }} />
      <List
        sx={{
          width: "100%",
        }}
      >
        <ListItemButton alignItems="flex-start">
          <ListItemAvatar>
            <Avatar alt="Ahmad" src="/static/images/avatar/1.jpg" />
          </ListItemAvatar>
          <ListItemText
            primary="Ahmad"
            secondary={"I'll be in your neighborhood doing errands this…"}
          />
        </ListItemButton>
        <Divider component="li" />
        <ListItemButton alignItems="flex-start">
          <ListItemAvatar>
            <Avatar alt="Reza" src="/static/images/avatar/2.jpg" />
          </ListItemAvatar>
          <ListItemText
            primary="Reza"
            secondary={"Wish I could come, but I'm out of town this…"}
          />
        </ListItemButton>
        <Divider component="li" />
        <ListItemButton alignItems="flex-start">
          <ListItemAvatar>
            <Avatar alt="Mohammad" src="/static/images/avatar/1.jpg" />
          </ListItemAvatar>
          <ListItemText
            primary="Mohammad"
            secondary={"Do you have Paris recommendations? Have you ever…"}
          />
        </ListItemButton>
      </List>
    </Box>
  );
}

function Conversations() {
  return (
    <Stack>
      <Stack direction={"row"} spacing={1} my={2}>
        <Avatar alt="Ahmad" src="/static/images/avatar/1.jpg" />
        <Stack
          sx={{
            p: 1,
            borderRadius: 4,
            bgcolor: (theme) =>
              theme.palette.mode === "light"
                ? lighten(theme.palette.foreground.main, 1)
                : darken(theme.palette.foreground.main, 0.25),
          }}
        >
          <Typography variant="caption" fontWeight={700} gutterBottom>
            Ahmad
          </Typography>
          <Typography variant="body2">
            {"I'll be in your neighborhood doing errands this…"}
          </Typography>
          <Typography variant="body2" sx={{ mt: 1, opacity: 0.5 }}>
            {moment().calendar()}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction={"row-reverse"} spacing={1} my={2}>
        <Avatar alt="Reza" src="/static/images/avatar/1.jpg" />
        <Stack
          sx={{
            p: 1,
            borderRadius: 4,
            bgcolor: (theme) =>
              theme.palette.mode === "light"
                ? lighten(theme.palette.secondary.main, 0.8)
                : lighten(theme.palette.secondary.main, 0.25),
            textAlign: "right",
            color: (theme) => getContrastedColor(theme.palette.secondary.main),
          }}
        >
          <Typography variant="caption" fontWeight={700} gutterBottom>
            Reza
          </Typography>
          <Typography variant="body2">
            {"Wish I could come, but I'm out of town this…"}
          </Typography>
          <Typography variant="body2" sx={{ mt: 1, opacity: 0.5 }}>
            {moment().calendar()}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

function NewMessage() {
  return (
    <Box>
      <Divider />
      <Stack direction={"row"} alignItems={"center"} spacing={1}>
        <IconButton>
          <AttachFile />
        </IconButton>
        <InputBase
          multiline
          placeholder="Type here..."
          sx={{
            minHeight: 80,
            width: "100%",
          }}
        />
        <IconButton>
          <Send />
        </IconButton>
      </Stack>
    </Box>
  );
}

function ConversationUser() {
  return (
    <Box>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Ahmad" src="/static/images/avatar/3.jpg" />
        </ListItemAvatar>
        <ListItemText
          primary="Ahmad"
          secondary={"Last online 42 seconds ago"}
        />
      </ListItem>
      <Divider
        sx={{
          mt: 1,
        }}
      />
    </Box>
  );
}
