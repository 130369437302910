import {
  FormControl,
  InputLabel,
  TextField,
  capitalize,
  darken,
  lighten,
} from "@mui/material";
import { Compact } from "@uiw/react-color";
import { useAppTheme } from "../../hooks/useAppTheme";
import { useEffect } from "react";
let COLORS = [
  "#4D4D4D",
  "#999999",
  "#FFFFFF",
  "#F44E3B",
  "#FE9200",
  "#FCDC00",
  "#DBDF00",
  "#A4DD00",
  "#68CCCA",
  "#73D8FF",
  "#AEA1FF",
  "#FDA1FF",
  "#333333",
  "#808080",
  "#cccccc",
  "#D33115",
  "#E27300",
  "#FCC400",
  "#B0BC00",
  "#68BC00",
  "#16A5A5",
  "#009CE0",
  "#7B64FF",
  "#FA28FF",
  "#000000",
  "#666666",
  "#B3B3B3",
  "#9F0500",
  "#C45100",
  "#FB9E00",
  "#808900",
  "#194D33",
  "#0C797D",
  "#0062B1",
  "#653294",
  "#AB149E",
];
export default function CustomColor(props) {
  const { appTheme } = useAppTheme();

  return (
    <FormControl
      variant="standard"
      fullWidth={props.fullWidth === false ? false : true}
      sx={{
        position: "relative",
        ".MuiInputLabel-root.Mui-focused": {
          color: (theme) => theme.palette.secondary.main,
        },
        ".w-color-editable-input input": {
          fontSize: "14px !important",
          color:
            appTheme.palette.mode === "dark"
              ? "#fff !important"
              : "#111 !important",
        },
        ".w-color-editable-input span": {
          fontSize: "14px !important",
        },
        ".w-color-editable-input span div": {
          width: "13px !important",
          height: "13px !important",
        },
      }}
    >
      {props.label && (
        <InputLabel
          shrink
          htmlFor={props.label + "_input"}
          sx={{
            fontSize: 18,
            px: 0.5,
          }}
        >
          {props.label && capitalize(props.label.toString())}
        </InputLabel>
      )}
      <Compact
        onChange={(color) => props.onChange(color.hex)}
        color={props.color}
        colors={[...COLORS]}
        style={{
          background:
            appTheme.palette.mode === "dark"
              ? darken(appTheme.palette.foreground.main, 0.25)
              : lighten(appTheme.palette.foreground.main, 0.25),
          width: "100%",
          marginTop: 22,
          borderRadius: 6,
        }}
      />
    </FormControl>
  );
}
