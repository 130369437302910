import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";

import isEmptyObject from "../../utils/isEmptyObject";
import { getContrastedColor } from "../../utils/getContrastedColor";

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }) => ({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.palette.secondary.main,
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 60,
    width: "100%",
  },
}));

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: getContrastedColor(theme.palette.foreground.main),
    "&.Mui-selected": {
      color: theme.palette.secondary.main,
    },

    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`setting-tabpanel-${index}`}
      aria-labelledby={`setting-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function CustomTabs({ tabs }) {
  const [value, setValue] = useState(0);

  const tabProps = (tabId) => {
    return {
      id: `tab-${tabId}`,
    };
  };

  const handleChange = (event, newValue) => {
    setValue(parseInt(newValue));
  };

  return (
    <Box sx={{ width: "100%", position: "relative" }}>
      <Box>
        <StyledTabs
          variant="scrollable"
          selectionFollowsFocus
          value={value}
          onChange={handleChange}
          sx={{
            my: 2,
          }}
        >
          {tabs.map((tab, index) => {
            return (
              <StyledTab
                key={tab.tabId}
                label={
                  <Typography sx={{ fontSize: { md: 18, xs: 14 } }}>
                    {tab.label}
                  </Typography>
                }
                {...tabProps(tab.tabId)}
              />
            );
          })}
        </StyledTabs>
      </Box>

      {tabs.map((tab, index) => {
        return (
          <TabPanel key={tab.tabId + "_"} value={value} index={index}>
            {tab.content}
          </TabPanel>
        );
      })}
    </Box>
  );
}

export default CustomTabs;
