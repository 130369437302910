import * as React from "react";
import * as ReactDOM from "react-dom/client";
import App from "./App";
import { ProvideAppTheme } from "./hooks/useAppTheme";
import { GlobalStyleProvider } from "./hooks/useGlobalStyle";
import { ProvideModal } from "./hooks/useModal";
import { NotificationProvider } from "./hooks/useNotification";
import "@fontsource/carlito";
import "@fontsource/unbounded";
import { SnackbarProvider } from "notistack";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

// document.documentElement.dir = "rtl";
// document.documentElement.lang = "fa";

root.render(
  <ProvideAppTheme>
    <GlobalStyleProvider>
      <SnackbarProvider
        style={{
          borderRadius: 15,
        }}
        anchorOrigin={{ horizontal: "top", vertical: "right" }}
      >
        <NotificationProvider>
          <ProvideModal>
            <App />
          </ProvideModal>
        </NotificationProvider>
      </SnackbarProvider>
    </GlobalStyleProvider>
  </ProvideAppTheme>
);
