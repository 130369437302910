import { Add, Category, Delete, Edit, Group } from "@mui/icons-material";
import PageContainer from "../../../components/@common/PageContainer";
import {
  Avatar,
  Box,
  Card,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Stack,
  Typography,
  capitalize,
} from "@mui/material";
import CustomTable from "../../../components/@common/CustomTable";
import useFetch from "../../../hooks/useFetch";
import moment from "moment";
import { useModal } from "../../../hooks/useModal";
import CustomButton from "../../../components/@common/CustomButton";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../../components/@common/CustomInput";
import CustomColor from "../../../components/@common/CustomColor";
import { useEffect, useState } from "react";
import { getContrastedColor } from "../../../utils/getContrastedColor";
import useForm from "../../../hooks/useForm";
import useCrud from "../../../hooks/useCrud";
import { mutate } from "swr";
import { blue } from "@mui/material/colors";

export default function GeneralProducts() {
  const { setModal } = useModal();
  const [hoveredItem, setHoveredItem] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null > null);

  const handlePopoverOpen = (event, item) => {
    setHoveredItem(item);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setHoveredItem(null);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const { data, loading } = useFetch("/api/managers/warehouse/products");

  const serializedData = data?.data?.map((product) => {
    return {
      id: product.id,
      name: product.name,
      factory: product.factory,
      color: product.color,
      weight: product.weight,
      added: moment(product.enter_date).format("YYYY/MM/DD"),
      "manufacture date": moment(product.manufacture_date).format("YYYY/MM/DD"),
      "expire date": moment(product.expired_date).format("YYYY/MM/DD"),
      "": (
        <>
          <IconButton
            onClick={() =>
              setModal({
                title: "confirm delete",
                child: (
                  <ConfirmDelete
                    id={product.id}
                    onClose={() => setModal(null)}
                  />
                ),
              })
            }
          >
            <Delete />
          </IconButton>
          {/* <IconButton
            onClick={() =>
              setModal({
                title: `Edit ${product.name}`,
                child: <FreshProductForm product={product} />,
              })
            }
          >
            <Edit />
          </IconButton> */}
        </>
      ),
    };
  });

  return (
    <PageContainer
      title={"General products"}
      icon={<Category />}
      color={blue[500]}
      actions={
        <>
          <CustomButton
            label={"Add new"}
            endIcon={<Add color="inherit" />}
            onClick={() =>
              setModal({
                title: "Add new product",
                child: <FreshProductForm />,
              })
            }
          />
        </>
      }
    >
      <CustomTable
        options={{
          rows: serializedData || [],
          loading,
        }}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      {hoveredItem && (
        <Popover
          sx={{
            pointerEvents: "none",
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <List sx={{ minWidth: 400, maxWidth: 560 }}>
            <SingleProduct product={hoveredItem} />
          </List>
        </Popover>
      )}
    </PageContainer>
  );
}

function SingleProduct({ product }) {
  return (
    <>
      <ListItem sx={{ p: 2 }} alignItems="flex-start">
        <ListItemAvatar>
          <Box
            sx={{
              position: "relative",
              bgcolor: product.color,
              display: "inline-block",
              width: 32,
              height: 32,
              borderRadius: "50%",
            }}
          ></Box>
        </ListItemAvatar>
        <ListItemText
          primary={capitalize(product.name)}
          secondary={
            <>
              <Typography
                component="span"
                variant="body2"
                sx={{
                  display: "inline",
                  color: (theme) =>
                    getContrastedColor(theme.palette.foreground.main),
                }}
              >
                {product.factory}
              </Typography>
            </>
          }
        />
      </ListItem>

      <Stack sx={{ p: 2 }} spacing={2}>
        <Stack direction={"row"} justifyContent={"space-between"} spacing={1}>
          <Typography>Weight:</Typography>
          <Typography
            sx={{
              fontFamily: "Carlito",
            }}
          >
            {String(product.weight).toUpperCase()}
          </Typography>
        </Stack>
        <Divider
          sx={{
            my: 0,
          }}
        />

        <Stack direction={"row"} justifyContent={"space-between"} spacing={1}>
          <Typography>Enter date:</Typography>
          <Typography
            sx={{
              fontFamily: "Carlito",
            }}
          >
            {moment(product.enter_date).format("YYYY/MM/DD HH:mm")}
          </Typography>
        </Stack>
        <Divider
          sx={{
            my: 0,
          }}
        />

        <Stack direction={"row"} justifyContent={"space-between"} spacing={1}>
          <Typography>Manufacture date:</Typography>
          <Typography
            sx={{
              fontFamily: "Carlito",
            }}
          >
            {moment(product.manufacture_date).format("YYYY/MM/DD HH:mm")}
          </Typography>
        </Stack>
        <Divider />

        <Stack direction={"row"} justifyContent={"space-between"} spacing={1}>
          <Typography>Expire date:</Typography>
          <Typography
            sx={{
              fontFamily: "Carlito",
            }}
          >
            {moment(product.expired_date).format("YYYY/MM/DD HH:mm")}
          </Typography>
        </Stack>
      </Stack>
    </>
  );
}

function FreshProductForm({ product }) {
  const { values, setValues, handleInput } = useForm({
    name: "",
    factory: "",
    color: "#d68fb7",
    weight: "",
    note: "",
  });
  const { crud, loading } = useCrud();

  const create = () =>
    crud
      .post("/api/managers/warehouse/products/add", values)
      .then(() => mutate("/api/managers/warehouse/products"));

  function standardize_color(str) {
    var ctx = document.createElement("canvas").getContext("2d");
    ctx.fillStyle = str;
    return ctx.fillStyle;
  }

  useEffect(() => {
    if (product) setValues(product);
  }, [product]);

  return (
    <>
      <Stack sx={{ p: 2, maxWidth: 400 }} spacing={2}>
        <CustomInput
          value={values.name}
          label={"Name"}
          onChange={handleInput("name")}
        />
        <CustomInput
          value={values.factory}
          label={"Factory"}
          onChange={handleInput("factory")}
        />
        <CustomColor
          label={"Color"}
          newColor={standardize_color(values.color)}
          color={standardize_color(values.color)}
          onChange={(color) => {
            setValues((prev) => ({ ...prev, color: color }));
          }}
        />
        <CustomInput
          type={"number"}
          label={"Weight"}
          value={values.weight}
          onChange={handleInput("weight")}
        />
        <CustomInput
          label={"Note"}
          onChange={handleInput("note")}
          multiline
          value={values.note}
          rows={2}
        />
        <CustomButton
          loading={loading}
          label={product ? "update" : "Create"}
          onClick={product ? update : create}
        />
      </Stack>
    </>
  );
}

function ConfirmDelete({ id, onClose }) {
  const { crud, loading } = useCrud();
  const deleteItem = () =>
    crud
      .delete("/api/managers/warehouse/products/remove?id=" + id)
      .then(() => onClose())
      .then(() => mutate("/api/managers/warehouse/products"));

  return (
    <Stack
      sx={{
        p: 2,
      }}
      spacing={1}
    >
      <Typography>Are you sure?</Typography>
      <Stack direction={"row"} spacing={2} justifyContent={"end"}>
        <CustomButton
          label="cancel"
          variant="text"
          color="inherit"
          onClick={onClose}
          disabled={loading}
        />
        <CustomButton
          loading={loading}
          label="confirm"
          variant="text"
          color="error"
          onClick={deleteItem}
        />
      </Stack>
    </Stack>
  );
}
