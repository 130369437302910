import React, { useState } from "react";
import { useNotification } from "../../hooks/useNotification";
import api from "../../config/api";
import { CircularProgress, Stack, Typography } from "@mui/material";
import CustomButton from "./CustomButton";

export default function ConfirmModal({
  text = "Are you sure?",
  onConfirm,
  onClose,
  loading,
}) {
  return (
    <Stack
      sx={{
        p: 2,
      }}
      spacing={1}
    >
      <Typography>{text}</Typography>
      <Stack direction={"row"} spacing={2} justifyContent={"end"}>
        {!loading && (
          <>
            <CustomButton
              label="cancel"
              variant="text"
              color="inherit"
              onClick={onClose}
            />
            <CustomButton
              label="confirm"
              variant="text"
              color="error"
              onClick={onConfirm}
            />
          </>
        )}
        {loading && <CircularProgress color="secondary" />}
      </Stack>
    </Stack>
  );
}
