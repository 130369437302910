import { darken, getContrastRatio, lighten } from "@mui/material";

export function getContrastedColor(color) {
  if (color) {
    return getContrastRatio(color, "#fff") > 3.5
      ? lighten(color, 0.7)
      : darken(color, 0.5);
  }
  return getContrastRatio("#000", "#fff") > 3.5
    ? lighten("#000", 0.7)
    : darken("#000", 0.5);
}

export function getContrastedColorOriginal(color) {
  if (color) {
    return getContrastRatio(color, "#fff") > 3.5 ? color : color;
  }
  return getContrastRatio("#000", "#fff") > 3.5 ? "#000" : "#000";
}
