import { ExpandMore } from "@mui/icons-material";
import Swatch from "@uiw/react-color-swatch";
import { hsvaToHex } from "@uiw/react-color";
import { getContrastedColor } from "../../utils/getContrastedColor";
import { colors } from "../../utils/colors";
import ForegroundPaper from "../@common/ForegroundPaper";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  InputBase,
  Slider,
  Stack,
  Typography,
} from "@mui/material";

export default function ColorPalette({
  appTheme,
  changeThemeSettings,
  changePalette,
  palette,
  setPallete,
}) {
  const tools = [
    {
      id: "primary",
      label: "رنگ اول",
      currentColor: appTheme.palette.primary.main,
      content: (
        <ForegroundPaper>
          <Box
            sx={{
              flex: 1,
              width: 300,
              my: 2,
            }}
          >
            <ColorInputField
              value={palette.primary.main}
              onChange={(e) => {
                let reg = /^#([0-9a-f]{3}){1,2}$/i;
                let val = e.target.value;
                setPallete({ ...palette, primary: { main: val } });

                if (reg.test(val)) {
                  return changePalette({
                    primary: {
                      main: val,
                    },
                  });
                }
              }}
            />
          </Box>
          <Stack direction={"row"}>
            <Swatch
              color={appTheme.palette.primary.main}
              colors={colors}
              onChange={(color) => {
                setPallete({ ...palette, primary: { main: hsvaToHex(color) } });
                return changePalette({
                  primary: {
                    main: hsvaToHex(color),
                  },
                });
              }}
            />
          </Stack>
        </ForegroundPaper>
      ),
    },
    {
      id: "secondary",
      label: "رنگ دوم",
      currentColor: appTheme.palette.secondary.main,
      content: (
        <ForegroundPaper>
          <Box
            sx={{
              flex: 1,
              width: 300,
              my: 2,
            }}
          >
            <ColorInputField
              value={palette.secondary.main}
              onChange={(e) => {
                let reg = /^#([0-9a-f]{3}){1,2}$/i;
                let val = e.target.value;
                setPallete({ ...palette, secondary: { main: val } });
                if (reg.test(val)) {
                  return changePalette({
                    secondary: {
                      main: val,
                    },
                  });
                }
              }}
            />
          </Box>
          <Swatch
            color={appTheme.palette.secondary.main}
            colors={colors}
            onChange={(color) => {
              setPallete({ ...palette, secondary: { main: hsvaToHex(color) } });

              return changePalette({
                secondary: {
                  main: hsvaToHex(color),
                },
              });
            }}
          />
        </ForegroundPaper>
      ),
    },
    {
      id: "tertiary",
      label: "رنگ سوم",
      currentColor: appTheme.palette.tertiary.main,
      content: (
        <ForegroundPaper>
          <Box
            sx={{
              flex: 1,
              width: 300,
              my: 2,
            }}
          >
            <ColorInputField
              value={palette.tertiary.main}
              onChange={(e) => {
                let reg = /^#([0-9a-f]{3}){1,2}$/i;
                let val = e.target.value;
                setPallete({ ...palette, tertiary: { main: val } });
                if (reg.test(val)) {
                  return changePalette({
                    tertiary: {
                      main: val,
                    },
                  });
                }
              }}
            />
          </Box>
          <Swatch
            color={appTheme.palette.tertiary.main}
            colors={colors}
            onChange={(color) => {
              setPallete({ ...palette, tertiary: { main: hsvaToHex(color) } });

              return changePalette({
                tertiary: {
                  main: hsvaToHex(color),
                },
              });
            }}
          />
        </ForegroundPaper>
      ),
    },

    {
      id: "background",
      label: "رنگ پیش زمینه",
      currentColor: appTheme.palette.background.main,
      content: (
        <ForegroundPaper>
          <Box
            sx={{
              flex: 1,
              width: 300,
              my: 2,
            }}
          >
            <ColorInputField
              value={palette.background.main}
              onChange={(e) => {
                let reg = /^#([0-9a-f]{3}){1,2}$/i;
                let val = e.target.value;
                setPallete({ ...palette, background: { main: val } });

                if (reg.test(val)) {
                  return changePalette({
                    background: {
                      main: val,
                    },
                  });
                }
              }}
            />
          </Box>
          <Swatch
            color={appTheme.palette.background.main}
            colors={colors}
            onChange={(color) => {
              setPallete({
                ...palette,
                background: { main: hsvaToHex(color) },
              });

              setPallete({
                ...palette,
                background: { main: hsvaToHex(color) },
              });

              return changePalette({
                background: {
                  main: hsvaToHex(color),
                },
              });
            }}
          />
        </ForegroundPaper>
      ),
    },
    {
      id: "foregroundColor",
      label: "رنگ پس زمینه",
      currentColor: appTheme.palette.foreground.main,
      content: (
        <ForegroundPaper>
          <Box
            sx={{
              flex: 1,
              width: 300,
              my: 2,
            }}
          >
            <ColorInputField
              value={palette.foreground.main}
              onChange={(e) => {
                let reg = /^#([0-9a-f]{3}){1,2}$/i;
                let val = e.target.value;
                setPallete({
                  ...palette,
                  foreground: { main: val },
                });

                if (reg.test(val)) {
                  return changePalette({
                    foreground: {
                      main: val,
                    },
                  });
                }
              }}
            />
          </Box>
          <Swatch
            color={appTheme.palette.foreground.main}
            colors={colors}
            onChange={(color) => {
              setPallete({
                ...palette,
                foreground: { main: hsvaToHex(color) },
              });

              setPallete({
                ...palette,
                foreground: { main: hsvaToHex(color) },
              });

              return changePalette({
                foreground: {
                  main: hsvaToHex(color),
                },
              });
            }}
          />
        </ForegroundPaper>
      ),
    },

    {
      id: "foregroundRoundness",
      label: "حاشیه",

      content: (
        <ForegroundPaper>
          <Stack
            sx={{
              p: 2,
            }}
            direction={"column"}
          >
            <Box
              sx={{
                width: "50%",
                px: 2,
                alignSelf: "flex-end",
              }}
            >
              <Slider
                color="secondary"
                defaultValue={appTheme.settings.foreground.borderRadius}
                step={1}
                marks
                min={0}
                max={5}
                onChange={(e, val) => {
                  changeThemeSettings({
                    foreground: {
                      borderRadius: val,
                    },
                  });
                }}
              />
            </Box>
          </Stack>
        </ForegroundPaper>
      ),
    },
  ];

  return (
    <Stack direction={"column"}>
      <ForegroundPaper>
        {tools.map((tool) => (
          <Accordion
            key={tool.id}
            sx={{
              bgcolor: "transparent",
              boxShadow: 0,
              color: (theme) =>
                getContrastedColor(theme.palette.foreground.main),
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMore
                  sx={{
                    color: (theme) =>
                      getContrastedColor(theme.palette.foreground.main),
                  }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Stack direction={"row"} alignItems={"center"} spacing={2}>
                {tool.currentColor ? (
                  <Box
                    sx={{
                      width: 10,
                      height: 10,
                      border: 1,
                      bgcolor: tool.currentColor,
                      borderRadius: 100,
                    }}
                  ></Box>
                ) : (
                  <Box
                    sx={{
                      width: 10,
                      height: 10,
                    }}
                  ></Box>
                )}
                <Typography>{tool.label}</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>{tool.content}</AccordionDetails>
          </Accordion>
        ))}
      </ForegroundPaper>
    </Stack>
  );
}

function ColorInputField({ value, onChange }) {
  return (
    <Box
      sx={{
        flex: 1,
        width: 300,
        my: 2,
      }}
    >
      <InputBase
        sx={{
          border: 1,
          px: 2,
          py: 1.5,
          borderRadius: 3,
          fontFamily: "monospace",
          direction: "ltr",
          textAlign: "left",
          color: (theme) => getContrastedColor(theme.palette.foreground.main),
          borderColor: (theme) =>
            getContrastedColor(theme.palette.foreground.main),
        }}
        fullWidth
        value={value}
        onChange={onChange}
      />
    </Box>
  );
}
