import { Alert, Box, Snackbar, capitalize } from "@mui/material";
import { green, red } from "@mui/material/colors";
import { AnimatePresence, motion } from "framer-motion";
import { useSnackbar } from "notistack";
import { createContext, useState, useContext, useEffect } from "react";

const NotificationContext = createContext({});

export const NotificationProvider = ({ children }) => {
  const { enqueueSnackbar: setNotification } = useSnackbar();

  return (
    <NotificationContext.Provider
      value={{
        setNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);
