export const openedMixin = (drawerwidth, theme) => ({
  borderRadius: 0,
  border: 0,
  width: drawerwidth,
  backgroundColor: theme.palette.primary.main,

  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

export const closedMixin = (theme) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: 0,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
});
