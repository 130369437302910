import useSWR from "swr";
import api from "../config/api";
import Cookies from "js-cookie";

const useFetch = (url, options) => {
  async function fetcher(url) {
    const response = await api.get(url);
    return response?.data;
  }
  let {
    data,
    error,
    isValidating,
    mutate: mutateFetch,
  } = useSWR(url, fetcher, options);

  let loading = !data && !error;

  return {
    loading,
    data,
    error,
    isValidating,
    mutateFetch,
  };
};

export default useFetch;
