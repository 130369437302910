import { NotInterested } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import CustomButton from "../components/@common/CustomButton";

export default function NotFound() {
  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ p: 2, width: "100%", height: "70vh" }}
    >
      <NotInterested />
      <Typography sx={{ my: 2 }} variant="h6">
        Page not found
      </Typography>
      <Link to={"/"}>
        <CustomButton variant="text" label="Go back" />
      </Link>
    </Stack>
  );
}
