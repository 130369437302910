import React, { useState } from "react";
import { useNotification } from "../../hooks/useNotification";
import api from "../../config/api";
import { CircularProgress, Stack, Typography } from "@mui/material";
import CustomButton from "./CustomButton";

export default function ConfirmDelete({ url, id, onClose, afterDelete }) {
  const { setNotification } = useNotification();
  const [loading, setLoading] = useState(false);
  const deleteItem = async () => {
    setLoading(true);
    try {
      const response = await api.post(
        url,
        { id },
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("gwmuser"),
          },
        }
      );

      if (afterDelete) {
        afterDelete();
      }
      setNotification({
        message: "removed",
        variant: "success",
      });
      setLoading(false);
      return response.data;
    } catch (error) {
      if (error.response.data.message) {
        setNotification({
          variant: "error",
          message: "error",
        });
      }
      setLoading(false);
      return;
    }
  };

  return (
    <Stack
      sx={{
        p: 2,
      }}
      spacing={1}
    >
      <Typography>آیا مطمئن هستید؟</Typography>
      <Stack direction={"row"} spacing={2} justifyContent={"end"}>
        {!loading && (
          <>
            <CustomButton
              label="cancel"
              variant="text"
              color="inherit"
              onClick={onClose}
            />
            <CustomButton
              label="confirm"
              variant="text"
              color="error"
              onClick={async () => await deleteItem()}
            />
          </>
        )}
        {loading && <CircularProgress color="secondary" />}
      </Stack>
    </Stack>
  );
}
