import {
  Add,
  Assessment,
  Dashboard,
  DateRange,
  DynamicForm,
  ExpandLess,
  ExpandMore,
  Group,
  OilBarrel,
  PersonPin,
  SortByAlphaRounded,
  Warehouse,
  WaterDrop,
} from "@mui/icons-material";

import {
  Avatar,
  Box,
  Collapse,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  darken,
  lighten,
} from "@mui/material";

import { motion } from "framer-motion";

import { useState } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../@common/CustomButton";
import { useAppTheme } from "../../hooks/useAppTheme";
import { blue, green, orange } from "@mui/material/colors";
import { getContrastedColor } from "../../utils/getContrastedColor";
import { useModal } from "../../hooks/useModal";
import Oil from "../../pages/app/Oil";

function createMenuItem(
  id,
  icon,
  label,
  location,
  childs = [],
  color = "transparent",
  onClick = null
) {
  return {
    id,
    icon,
    label,
    location,
    childs,
    color,
    onClick,
  };
}

export default function AccountingDrawerItems({ open }) {
  const { setModal } = useModal();
  const MENU_ITEMS = [
    createMenuItem(
      "customer-management",
      <Group />,
      "Customer Management",
      "/app/customer-management",
      [
        createMenuItem(
          "customer-management/add",
          null,
          "Add new",
          "/app/customer-management/add",
          null
        ),
      ],
      blue[500]
    ),

    createMenuItem(
      "warehouse-management",
      <Warehouse />,
      "Warehouse Management",
      "/app/warehouse-management",
      [
        createMenuItem(
          "warehouse-management/general",
          null,
          "General",
          "/app/warehouse-management/general",
          null
        ),
        createMenuItem(
          "warehouse-management/buckets",
          null,
          "Buckets",
          "/app/warehouse-management/buckets",
          null
        ),
        createMenuItem(
          "warehouse-management/oil",
          <WaterDrop />,
          "Oil",
          "/app/warehouse-management/oil",
          [],
          "",
          () =>
            setModal({
              title: "Oil",
              child: <Oil />,
            })
        ),
      ],
      blue[500]
    ),
  ];

  const location = useLocation();

  const [isSelectedParent, setIsSelectedParent] = useState(location.toString());

  return (
    <List sx={{ py: 2 }}>
      {MENU_ITEMS &&
        MENU_ITEMS.map((item) => (
          <MainListItem
            key={item.label}
            setIsSelectedMenuItem={setIsSelectedParent}
            isDrawerOpen={open}
            isSelected={isSelectedParent}
            listItem={item}
            sx={{
              ...(open ? { pl: 3, my: 0.5, mx: 1 } : { mx: 0 }),
              borderRadius: (theme) => theme.settings.foreground.borderRadius,
            }}
          />
        ))}
    </List>
  );
}

function MainListItem({ listItem, isDrawerOpen }) {
  const location = useLocation();
  const navigate = useNavigate();

  const handleListButton = (item, e) => {
    if (e.target.href === undefined) {
      return navigate(item.location);
    } else {
      return navigate(e.target.href.replace(/^.*\/\/[^\/]+/, ""));
    }
  };

  return (
    <ListItem
      onClick={(e) => handleListButton(listItem, e)}
      sx={{
        cursor: "pointer",
        px: isDrawerOpen ? 2.5 : 1,
        py: 1,
        mx: 1,
        borderRadius: 2,
        ":hover": {
          bgcolor: (theme) => lighten(theme.palette.primary.main, 0.2),
        },
      }}
    >
      <ListItemIcon
        sx={{
          color: (theme) =>
            listItem.color !== "transparent"
              ? theme.palette.primary.main
              : theme.palette.primary.contrastText,
        }}
      >
        <motion.div
          style={{
            backgroundColor: listItem.color,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            padding: 4,
          }}
          component={motion.div}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              delay: 0.5,
            },
          }}
          exit={{
            scale: 0,
            opacity: 0,
          }}
        >
          {listItem.icon}
        </motion.div>
      </ListItemIcon>
      {isDrawerOpen && (
        <ListItemText
          sx={{
            color: (theme) => {
              if (location === listItem.location) {
                return theme.palette.tertiary.main + " !important";
              } else {
                return theme.palette.primary.contrastText + " !important";
              }
            },
          }}
          primary={
            <motion.div
              component={motion.div}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  delay: 0.5,
                },
              }}
              exit={{
                opacity: 0,
              }}
            >
              <Typography
                sx={{
                  a: {
                    textDecoration: "none",

                    color: (theme) =>
                      location.pathname === listItem.location
                        ? theme.palette.secondary.main
                        : theme.palette.primary.contrastText + " !important",
                  },
                }}
              >
                <Link to={listItem.location}>{listItem.label}</Link>
              </Typography>
            </motion.div>
          }
          secondary={
            <motion.div
              component={motion.div}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  delay: 0.5,
                },
              }}
              exit={{
                opacity: 0,
              }}
            >
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                {listItem.childs.map((child, i) => {
                  return (
                    <>
                      <Typography
                        key={child.label}
                        variant="button"
                        sx={{
                          fontSize: 13,
                          opacity:
                            location.pathname === child.location ? 0.8 : 0.7,
                          a: {
                            textDecoration: "none",
                            ":hover": {
                              textDecoration: "underline",
                            },
                            color: (theme) =>
                              location.pathname === child.location
                                ? theme.palette.secondary.main
                                : theme.palette.primary.contrastText +
                                  " !important",
                          },
                        }}
                      >
                        {child.onClick ? (
                          <Link onClick={child.onClick}>{child.label}</Link>
                        ) : (
                          <Link to={child.location}>{child.label}</Link>
                        )}
                      </Typography>
                      {listItem.childs.length > 1 &&
                        i !== listItem.childs.length - 1 && (
                          <Typography
                            sx={{
                              opacity: 0.3,
                              color: (theme) =>
                                theme.palette.primary.contrastText,
                            }}
                          >
                            |
                          </Typography>
                        )}
                    </>
                  );
                })}
              </Stack>
            </motion.div>
          }
        />
      )}
    </ListItem>
  );
}
