import { useState } from "react";

const useForm = (initial = null) => {
  const [values, setValues] = useState(initial);

  const handleInput = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleSelect = (prop) => (event, option) => {
    setValues({ ...values, [prop]: option?.title });
  };

  return {
    handleSelect,
    handleInput,
    setValues,
    values,
  };
};

export default useForm;
