import { Assessment } from "@mui/icons-material";
import PageContainer from "../../../components/@common/PageContainer";
import { orange } from "@mui/material/colors";
import useFetch from "../../../hooks/useFetch";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import CustomSelect from "../../../components/@common/CustomSelect";
import { useState } from "react";
import moment from "moment";
import CustomTable from "../../../components/@common/CustomTable";
import Label from "../../../components/@common/Label";
import CustomDatePicker from "../../../components/@common/CustomDatePicker";
import { PickersDay } from "@mui/x-date-pickers";
import useForm from "../../../hooks/useForm";

const ReportManagement = () => {
  const [selectedAgent, setSelectedAgent] = useState({
    id: null,
    title: "",
  });

  const [selectedCustomer, setSelectedCustomer] = useState({
    id: null,
    title: "",
  });

  const { setValues, values } = useForm({
    from_date: new Date(),
    to_date: new Date(),
  });

  const TripStatus = [
    { id: 0, title: "All" }, // all
    { id: 1, title: "Completed" }, // completed
    { id: 2, title: "Not Started" }, // not_started
    { id: 3, title: "Completed Inprogress" }, // completed_inprogress
    { id: 4, title: "End Trip" }, // end_trip
  ];

  const [tripStatus, setTripStatus] = useState("All");

  const { data: reportData, loading: reportLoading } = useFetch(
    `/api/drivers/admin/reports?driver_id=${selectedAgent?.id}&from=${values?.from_date}&to=${values?.to_date}&customer_id=${selectedCustomer?.customer_id}`
  );

  const { data: agentReport, loading: agentReportLoading } = useFetch(
    `/api/drivers/admin/reports?driver_id=${selectedAgent?.id}`
  );

  const { data: customerReport, loading: customerReportLoding } = useFetch(
    `/api/drivers/admin/reports?driver_id=${selectedAgent?.id}&customer_id=${selectedCustomer?.customer_id}`
  );

  const { data: agentsData, loading: agentLoading } = useFetch(
    "/api/managers/agents"
  );

  const { data: customerData, loading: customerLoading } = useFetch(
    "/api/managers/customers"
  );

  const wholeStatus = agentReport?.data;

  const reportsList = Object.keys(reportData?.data || "")
    .map((key) => reportData?.data[key] || "")
    .filter((entry) => entry && entry?.id !== undefined)
    .map((entry) => entry);

  const reportsAgentList = Object.keys(agentReport?.data || "")
    .map((key) => agentReport?.data[key] || "")
    .filter((entry) => entry && entry?.id !== undefined)
    .map((entry) => entry);

  const reportsCustomerList = Object.keys(customerReport?.data || "")
    .map((key) => customerReport?.data[key] || "")
    .filter((entry) => entry && entry?.id !== undefined)
    .map((entry) => entry);

  const customers = customerData?.data?.map((item) => {
    return {
      customer_id: item?.id,
      customer_number: item?.customer_number,
      title: item?.last_name,
    };
  });

  const agents = agentsData?.drivers?.map((agent) => {
    return {
      id: agent.id,
      title: `${agent?.first_name} ${agent?.last_name}`,
    };
  });

  let reportDataList = (
    !selectedCustomer?.customer_id || !values
      ? reportsAgentList
      : selectedCustomer?.customer_id || !values
      ? reportsCustomerList
      : reportsList
  )
    ?.map((item) => {
      return {
        id: item.id,
        "Customer Number": item?.customer_info?.customer_number || "",
        "Company Name": item?.customer_info?.company_name || "",
        "Contract Price": item?.visit_info?.contract_price || "-" + " " + "€",
        Amount: (item?.visit_info?.amount || "-") + " " + "Kg",
        "Payment Type": item?.visit_info?.payment_type || "-",
        "Visit Time": item?.visit_info?.visit_time || "-",
        "Start Time": item?.trips?.start_time || "-",
        "End Time": item?.trips?.end_time || "-",
        Status: item?.status,
      };
    })
    .filter((i) => {
      if (tripStatus.id === 1) {
        return i.label?.Status === "completed";
      } else if (tripStatus.id === 2) {
        return i.Status === "not_started";
      } else if (tripStatus.id === 3) {
        return i.Status === "completed_inprogress";
      } else if (tripStatus.id === 4) {
        return i.Status === "end_trip";
      } else {
        return i;
      }
    });

  return (
    <PageContainer
      title={"Report Management"}
      icon={<Assessment />}
      color={orange[500]}
    >
      {agentLoading &&
        customerLoading &&
        reportLoading &&
        agentReportLoading &&
        customerReportLoding && (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            m={"auto"}
          >
            <CircularProgress
              sx={{
                color: (theme) => theme.palette.secondary.main,
              }}
            />
          </Box>
        )}
      {!agentLoading && (
        <Grid container spacing={3}>
          <Grid item xs={4}>
            {!agentLoading && agents && (
              <>
                <Label>Choose Agent</Label>

                <CustomSelect
                  label={"Agents"}
                  options={agents}
                  onChange={(e, agent) => setSelectedAgent(agent)}
                  value={selectedAgent.title}
                />
              </>
            )}
          </Grid>
          <Grid item xs={4}>
            <>
              <Label>Choose Customer</Label>

              <CustomSelect
                label={"Customers"}
                options={customers}
                onChange={(e, customer) => setSelectedCustomer(customer)}
                value={selectedCustomer.title}
              />
            </>
          </Grid>
          <Grid item xs={4}>
            {!agentLoading && agents && (
              <>
                <Label>Status</Label>
                <CustomSelect
                  value={tripStatus.title}
                  label={"history status"}
                  options={TripStatus}
                  onChange={(e, status) => setTripStatus(status)}
                />
              </>
            )}
          </Grid>
          <Grid item xs={4}>
            <Label>From</Label>
            <CustomDatePicker
              defaultValue={values.from_date}
              value={new Date(values.from_date)}
              sx={{ input: { p: 2 } }}
              slots={{
                day: (props) => {
                  return (
                    <PickersDay
                      {...props}
                      onDaySelect={(d) => {
                        setValues((prevValues) => ({
                          ...prevValues,
                          from_date: moment(d).format("YYYY/MM/DD"),
                        }));
                      }}
                    />
                  );
                },
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Label>To</Label>
            <CustomDatePicker
              defaultValue={values.to_date}
              value={new Date(values.to_date)}
              sx={{ input: { p: 2 } }}
              slots={{
                day: (props) => {
                  return (
                    <PickersDay
                      {...props}
                      onDaySelect={(d) => {
                        setValues((prevValues) => ({
                          ...prevValues,
                          to_date: moment(d).format("YYYY/MM/DD"),
                        }));
                      }}
                    />
                  );
                },
              }}
            />
          </Grid>
        </Grid>
      )}

      <Box
        mt={6}
        display={"flex"}
        justifyContent={"space-between"}
        border={"1px solid"}
        p={3}
        borderRadius={4}
      >
        <Typography>
          Total Collected: {wholeStatus?.totalCollected || "-"}
        </Typography>
        <Typography>Completed: {wholeStatus?.completed || "-"}</Typography>
        <Typography>In Progress : {wholeStatus?.in_progress || "-"}</Typography>
        <Typography>Failed : {wholeStatus?.failed || "-"}</Typography>
      </Box>
      <Box mt={6}>
        <CustomTable
          hideselect={true}
          options={{
            rows: reportDataList,
            loading: reportLoading,
          }}
          title={`report`}
        />
      </Box>
    </PageContainer>
  );
};

export default ReportManagement;
