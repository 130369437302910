import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useState } from "react";
import { FullscreenControl } from "react-leaflet-fullscreen";

const OpenMap = ({ customMarkers, sortedMarker, lat, lng, isSelected }) => {
  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  });

  const redIcon = L.icon({
    iconUrl: require("../../../../src/img/red_02.png"),
    iconSize: [50, 50], 

  });

  const [markers, setMarkers] = useState(
    customMarkers
      ? customMarkers
      : [
          {
            lat: lat ? lat : 38.075488,
            lng: lng ? lng : 46.288423,
          },
        ]
  );

  return (
    <>
      <MapContainer
        center={[49.79391 ,9.95121]}
        zoom={6}
        scrollWheelZoom={true}
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <FullscreenControl
          position="topright"
          title="Full Screen"
          titleCancel="Standard Size"
        />
        {customMarkers?.length > 0 &&
          customMarkers.map((place) => (
            <Marker position={[place?.lat, place?.lng]}>
              <Popup>{place?.name}</Popup>
            </Marker>
          ))}
        {sortedMarker?.length > 0 &&
          sortedMarker?.map((place) => (
            <Marker position={[place?.lat, place?.lng]} icon={redIcon}>
              <Popup>{place?.name}</Popup>
            </Marker>
          ))}
      </MapContainer>
    </>
  );
};

export default OpenMap;
